import React from "react";
import styled, { css } from "styled-components";
import { color, font } from "src/styles/variables";

const breakPoint = "@media (min-width: 600px)";

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${({ align }) => align && "center"};
  & > button,
  & > a {
    margin: 10px;
    @media (max-width: 499px) {
      width: 100%;
    }
  }
`;

const buttonCssStyles = (styles) => css`
  ${styles};
`;
const variantLinkStyles = css`
  ${({ inline }) => (inline ? "display: inline; width: auto;" : "")};
  border-color: transparent;
  color: ${color.midBlue};
  background: none;
  padding: 0;
  text-align: left;
  font-size: 16px;
  text-transform: none;
  text-decoration: none;
  :hover {
    background: none;
    color: ${color.blue};
  }
`;
const variantPrimaryStyles = css`
  background-color: ${color.green};
  :hover {
    background-color: ${color.funGreen};
  }
`;
const variantSecondaryStyles = css`
  background-color: ${color.darkBlue};
  :hover {
    background-color: ${color.blue};
  }
`;
const variantOutlineStyles = css`
  outline: none;
  border-color: ${color.mineShaft};
  ${({ isSelected }) =>
    isSelected
      ? `
    background-color: ${color.mineShaft};
    color: ${color.white};
    :focus, :hover {
      background-color: ${color.midGrey};
      border-color: ${color.midGrey};
    }
  `
      : `
  background-color: transparent;
  color: ${color.mineShaft};
  :hover, :focus {
    background-color: ${color.mineShaft};
    color: ${color.white};
  }
`};
`;
const variantOutlinePrimaryStyles = css`
  border-color: ${color.green};
  background-color: transparent;
  color: ${color.green};
  :hover {
    border-color: ${color.funGreen};
    background-color: transparent;
    color: ${color.funGreen};
  }
`;
const variantOutlineSecondaryStyles = css`
  border-color: ${color.white};
  background-color: transparent;
  color: ${color.white};
  :hover {
    background-color: ${color.white};
    color: ${color.mineShaft};
  }
`;
const variantDangerStyles = css`
  background-color: ${color.torchRed};
  :hover {
    background-color: ${color.red};
  }
`;
const buttonInProgressStyles = css`
  cursor: not-allowed;
  opacity: 0.6;
`;
const buttonSmallStyles = css`
  font-size: 12px;
  padding: 5px 10px;
`;
const buttonDisabledStyles = css`
  cursor: not-allowed;
  border-color: ${color.shipGrey};
  background-color: ${color.shipGrey};
  color: ${color.silverChalice};
  :hover {
    background-color: ${color.shipGrey};
    border-color: ${color.shipGrey};
    color: ${color.silverChalice};
  }
`;
const buttonCenterStyles = css`
  margin-left: auto;
  margin-right: auto;
`;

export const defaultStyles = css`
  display: block;
  appearance: none;
  border: 2px solid transparent;
  background-color: ${color.mineShaft};
  border-radius: 5px;
  color: ${color.white};
  cursor: pointer;
  font-family: ${font.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  transition-property: background-color, color, border;
  transition-duration: 0.3s;
  padding: 10px;
  :hover {
    color: ${color.white};
    background-color: ${color.midGrey};
  }
  ${({ fullWidth }) =>
    fullWidth
      ? ""
      : `
    ${breakPoint} {
     max-width: 300px;
    }
  `};
  ${({ variant }) => variant === "link" && variantLinkStyles};
  ${({ variant }) => variant === "primary" && variantPrimaryStyles};
  ${({ variant }) => variant === "secondary" && variantSecondaryStyles};
  ${({ variant }) => variant === "outline" && variantOutlineStyles};
  ${({ variant }) => variant === "danger" && variantDangerStyles};
  ${({ variant }) =>
    variant === "outlinePrimary" && variantOutlinePrimaryStyles};
  ${({ variant }) =>
    variant === "outlineSecondary" && variantOutlineSecondaryStyles};
  ${({ size }) => size === "small" && buttonSmallStyles};
  ${({ inProgress }) => inProgress && buttonInProgressStyles};
  ${({ align }) => align === "center" && buttonCenterStyles};
  ${({ align }) => align === "right" && "margin-left: auto"};
  ${({ disabled, inProgress }) =>
    disabled && !inProgress && buttonDisabledStyles};
  ${({ css }) => css};
`;

function ButtonAdapter({
  fullWidth,
  inProgress,
  isSelected,
  variant,
  align,
  size,
  inline,
  css,
  tracking,
  trackingOptions,
  ...rest
}) {
  return <button {...rest} />;
}

export const ButtonBase = styled(ButtonAdapter)`
  ${defaultStyles}
`;

export const ButtonLink = styled.button`
  ${variantLinkStyles};
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: ${font.primary};
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  transition-property: color;
  transition-duration: 0.3s;
  ${({ align }) => align === "right" && "margin: auto; text-align: right;"};
  ${({ align }) => align === "center" && "margin: auto; text-align: center;"};
  ${({ size }) => size === "small" && buttonSmallStyles};
  ${({ css }) => css & buttonCssStyles(css)};
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: ${color.midGrey};
    :hover {
      color: ${color.midGrey};
    }
  `};
`;
