import { getCouponService } from "src/services/cornerstone/coupons/coupon";
import {
  putCouponService,
  deleteSubscriptionCouponService,
} from "src/services/cornerstone/subscription/coupon";

export const getCouponActionTypes = {
  SIGNUP_COUPONS__GET_PENDING: `SIGNUP_COUPONS__GET_PENDING`,
  SIGNUP_COUPONS__GET_SUCCESS: `SIGNUP_COUPONS__GET_SUCCESS`,
  SIGNUP_COUPONS__GET_ERROR: `SIGNUP_COUPONS__GET_ERROR`,
};
export const getSignupCouponAction = (couponCode) => (dispatch) => {
  dispatch({ type: getCouponActionTypes.SIGNUP_COUPONS__GET_PENDING });

  return getCouponService({ couponCode }).then(({ data, error }) => {
    if (error) {
      dispatch({
        type: getCouponActionTypes.SIGNUP_COUPONS__GET_ERROR,
        payload: error,
      });
      return { error, data: null };
    }
    dispatch({
      type: getCouponActionTypes.SIGNUP_COUPONS__GET_SUCCESS,
      payload: data,
    });
    return { error: null, data };
  });
};

export const putSubscriptionCouponActionTypes = {
  SUBSCRIPTION__COUPON_PUT_SUCCESS: `SUBSCRIPTION__COUPON_PUT_SUCCESS`,
};
export const putSubscriptionCoupon = (couponId) => (
  dispatch,
  getState,
  { getApiToken }
) => {
  const state = getState();
  const apiToken = getApiToken(state);

  return putCouponService({ apiToken, couponId }).then((response) => {
    if (!response.error) {
      dispatch({
        type: putSubscriptionCouponActionTypes.SUBSCRIPTION__COUPON_PUT_SUCCESS,
        payload: { data: response.data, timestamp: Date.now() },
      });
    }
    return response;
  });
};

export const deleteSubscriptionCouponActionTypes = {
  SUBSCRIPTION__DELETE_PENDING: `SUBSCRIPTION__DELETE_PENDING`,
  SUBSCRIPTION__DELETE_SUCCESS: `SUBSCRIPTION__DELETE_SUCCESS`,
  SUBSCRIPTION__DELETE_ERROR: `SUBSCRIPTION__DELETE_ERROR`,
};
export const deleteSubscriptionCouponAction = () => (
  dispatch,
  getState,
  { getApiToken }
) => {
  const apiToken = getApiToken(getState());
  dispatch({
    type: deleteSubscriptionCouponActionTypes.SUBSCRIPTION__DELETE_PENDING,
  });

  return deleteSubscriptionCouponService(apiToken).then((response) => {
    if (response.error) {
      return dispatch({
        type: deleteSubscriptionCouponActionTypes.SUBSCRIPTION__DELETE_ERROR,
      });
    }
    return dispatch({
      type: deleteSubscriptionCouponActionTypes.SUBSCRIPTION__DELETE_SUCCESS,
    });
  });
};
