import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router-dom";
import useMatchMedia from "src/react/hooks/useMatchMedia";
import {
  isAuthenticated as getIsAuthenticated,
  getIsUserAccountActive,
} from "src/redux/user/selectors";
import Container from "src/react/layout/container";
import links from "./links";
import {
  NavList,
  NavListMobileWrapper,
  NavAMobile,
  NavLinkMobile,
  NavA,
  NavLink,
  NavListWrapper,
} from "./components";
import NavBasketLink from "./nav-basket-link";
import NavAccountLink from "./nav-account-link";
import NavToggle from "./nav-toggle";

function MainNavBar({
  showMobileNav,
  toggleMobileNav,
  isAuthenticated,
  isUserAccountActive,
  location,
}) {
  const mediaMatches = useMatchMedia("min-width: 768px");
  const shouldShowNavBasketLink =
    !isUserAccountActive && !matchPath(location.pathname, { path: "/gifts" });

  return (
    <div data-selector="MainNavBar">
      {mediaMatches ? (
        <NavListWrapper>
          <NavList>
            <li>
              <NavLink to={links.products.to}>{links.products.text}</NavLink>
            </li>
            <li>
              <NavLink to={links.howItWorks.to}>
                {links.howItWorks.text}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.ourStory.to}>{links.ourStory.text}</NavLink>
            </li>
            <li>
              <NavLink to={links.gifts.to}>{links.gifts.text}</NavLink>
            </li>
            <li>
              <NavA href={links.blog.to}>{links.blog.text}</NavA>
            </li>
            <li>
              <NavLink to={links.help.to}>{links.help.text}</NavLink>
            </li>
          </NavList>
          <NavList>
            {shouldShowNavBasketLink && (
              <NavBasketLink
                to={links.signupBasket.to}
                text={links.signupBasket.text}
              />
            )}
            <li>
              <NavAccountLink
                isAuthenticated={isAuthenticated}
                isUserAccountActive={isUserAccountActive}
              />
            </li>
          </NavList>
        </NavListWrapper>
      ) : (
        <Fragment>
          <Container>
            <NavList>
              <li>
                <NavToggle
                  data-selector="MainNavBar-NavToggle"
                  onClick={() => toggleMobileNav(!showMobileNav)}
                  isOpen={showMobileNav}
                />
              </li>
              {shouldShowNavBasketLink && (
                <NavBasketLink
                  size={18}
                  to={links.signupBasket.to}
                  text={links.signupBasket.text}
                />
              )}
              <li>
                <NavAccountLink
                  iconSize={18}
                  isAuthenticated={isAuthenticated}
                  isUserAccountActive={isUserAccountActive}
                />
              </li>
            </NavList>
          </Container>
          <NavListMobileWrapper
            data-selector="MainNavBar-NavListMobileWrapper"
            isOpened={showMobileNav}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <ul>
              <li>
                <NavLinkMobile to={links.products.to}>
                  {links.products.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile to={links.howItWorks.to}>
                  {links.howItWorks.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile to={links.ourStory.to}>
                  {links.ourStory.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile to={links.gifts.to}>
                  {links.gifts.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavAMobile href={links.blog.to}>{links.blog.text}</NavAMobile>
              </li>
              <li>
                <NavLinkMobile to={links.help.to}>
                  {links.help.text}
                </NavLinkMobile>
              </li>
            </ul>
          </NavListMobileWrapper>
        </Fragment>
      )}
    </div>
  );
}

MainNavBar.propTypes = {
  toggleMobileNav: PropTypes.func.isRequired,
  showMobileNav: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isUserAccountActive: PropTypes.bool,
  location: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
  isUserAccountActive: getIsUserAccountActive(state),
});

export default connect(mapStateToProps)(MainNavBar);
