import get from "src/lib/get";
import queryString from "query-string";
import history from "src/react/history";
import { actionTypes } from "src/redux/user/actions";
import {
  getSignupCouponAction,
  deleteSubscriptionCouponAction,
} from "src/redux/coupons/actions";
import { getCouponCode } from "src/redux/coupons/selectors";

const APPLY_COUPON_PARAMETER = "apply-coupon";

export default (store) => (next) => (action) => {
  next(action);

  if (
    action.type === actionTypes.USER_AUTH_CHECK_SUCCESS ||
    action.type === actionTypes.USER_AUTH_CHECK_ERROR ||
    action.type === actionTypes.USER_AUTH_CHECK_SKIPPED
  ) {
    const state = store.getState();
    const { search } = history.location;
    const query = queryString.parse(search);
    const coupon = query[APPLY_COUPON_PARAMETER];
    const couponCode = coupon || getCouponCode(state);
    if (couponCode) {
      store.dispatch(getSignupCouponAction(couponCode)).then(({ error }) => {
        removeCouponFromQueryString(history, query);
        if (error && error.status === 404) {
          store.dispatch(deleteSubscriptionCouponAction());
        }
      });
    }
  }
};

export const removeCouponFromQueryString = (history = {}, query = "") => {
  if (history.replace && query[APPLY_COUPON_PARAMETER]) {
    const pathname = get(history, "location.pathname", "");
    delete query[APPLY_COUPON_PARAMETER];
    const hasExtraParameters = Object.keys(query).length > 0;
    history.replace(
      `${pathname}${
        hasExtraParameters ? `?${queryString.stringify(query)}` : ""
      }`
    );
  }
};
