import { createSelector } from "reselect";
import get from "src/lib/get";
import convertPoundsToPence from "src/lib/convert-pounds-to-pence";
import ms from "ms";
import { containsPharmaceuticalProduct } from "src/data/helpers";
import { getProductsWithData } from "./helpers";

const CACHE_TIME = ms("60s");
const getStateSlice = (state) => get(state, "account.subscription", {});

export const getAccountSubData = createSelector(
  getStateSlice,
  (stateSlice) => stateSlice.data
);

/**
 * BASE SELECTORS
 */
export const getAccountSubIsPending = createSelector(
  getStateSlice,
  (stateSlice) => {
    return stateSlice.pending;
  }
);

export const getAccountSubHasData = createSelector(
  getStateSlice,
  (stateSlice) => {
    return !!stateSlice.data;
  }
);

export const getAccountSubHasError = createSelector(
  getStateSlice,
  (stateSlice) => {
    return !!stateSlice.error;
  }
);

export const getAccountSubDataTimestamp = createSelector(
  getStateSlice,
  (stateSlice) => {
    return stateSlice.dataTimestamp;
  }
);

export const getAccountSubShouldFetch = createSelector(
  [getAccountSubHasData, getAccountSubHasError, getAccountSubDataTimestamp],
  (hasData, hasError, dataTimestamp) => {
    if (hasError || !hasData) return true;
    return dataTimestamp + CACHE_TIME < Date.now();
  }
);

/**
 * USER
 */
export const getUser = createSelector(getAccountSubData, (data) => {
  return get(data, "user", {});
});

export const getUserFirstName = createSelector(getUser, (user) => {
  return get(user, "first_name", null);
});

export const getUserType = createSelector(getUser, (user) => {
  return get(user, "type", null);
});

export const getUserSex = createSelector(getUser, (user) => {
  return get(user, "sex", null);
});

export const getIsClassicUser = createSelector(getUserType, (userType) => {
  return userType === "classic";
});

export const getIsMultiUser = createSelector(getUserType, (userType) => {
  return userType === "multi";
});

export const getUserReferralCode = createSelector(getUser, (user) =>
  get(user, "referral_code", null)
);

export const getReferralLink = createSelector(
  getUserReferralCode,
  (referralCode) => {
    if (referralCode) {
      return `https://www.cornerstone.co.uk/7${referralCode.toLowerCase()}`;
    }
    return null;
  }
);

export const getUserCardLast4 = createSelector(getUser, (user) =>
  get(user, "card_last_4", "")
);

export const getUserCardExpiry = createSelector(getUser, (user = {}) =>
  user.card_expiry_month
    ? `${user.card_expiry_month}/${user.card_expiry_year}`
    : ""
);

export const getCardDetails = createSelector(
  [getUserCardLast4, getUserCardExpiry],
  (cardLast4, cardExpiry) => {
    return { cardLast4, cardExpiry };
  }
);

export const getDeliveryDetails = createSelector(getUser, (user) => {
  return {
    firstName: user.first_name || "",
    surname: user.surname || "",
    line1: user.delivery_address1 || "",
    line2: user.delivery_address2 || "",
    city: user.delivery_city || "",
    country: user.delivery_country || "",
    postcode: user.delivery_postcode || "",
    safePlace: user.delivery_safe_place || "",
    phoneNumber: user.phone_number || "",
    phoneCountryCode: user.phone_number_country_code || "",
  };
});

export const getHasPhoneNumber = createSelector(
  getUser,
  (user) => user && !!user.phone_number
);

export const getHasInternationalAddress = createSelector(
  getDeliveryDetails,
  (deliveryDetails = { country: "" }) => {
    return (
      !!deliveryDetails.country &&
      deliveryDetails.country.toLowerCase() !== "united kingdom"
    );
  }
);

export const getReferralBalance = createSelector(getUser, (user) => {
  return user.referral_balance || 0;
});

export const getAccountCredit = createSelector(getUser, (user) => {
  return user.credits || 0;
});

export const getAccountCreditPlusReferralBalance = createSelector(
  [getAccountCredit, getReferralBalance],
  (accountCredit, referralBalance) => {
    return accountCredit + referralBalance;
  }
);

/**
 * SUBSCRIPTION
 */
export const getSubscription = createSelector(getAccountSubData, (data) => {
  return get(data, "subscription", {});
});

export const getEdStatus = createSelector(getSubscription, (subscription) => {
  return subscription.ed_status;
});

export const getHairLossStatus = createSelector(
  getSubscription,
  (subscription) => {
    return subscription.hl_status;
  }
);

export const getWeightLossStatus = createSelector(
  getSubscription,
  (subscription) => {
    return subscription.wl_status;
  }
);

export const getIdentityCheckPassed = createSelector(
  getSubscription,
  (subscription) => {
    return subscription.identity_check_passed || false;
  }
);

export const getSubscriptionActivationDate = createSelector(
  getSubscription,
  (subscription) => {
    const activationDate = get(subscription, "activation_date", "");
    return new Date(activationDate);
  }
);

export const getIsLapsed = createSelector(getSubscription, (subscription) => {
  return get(subscription, "is_lapsed", false);
});

export const getBillingCycle = createSelector(
  getSubscription,
  (subscription) => {
    return get(subscription, "billing_cycle", null);
  }
);

export const getProducts = createSelector(getSubscription, (subscription) => {
  const products = get(subscription, "products", []);
  return getProductsWithData(products);
});

export const getPendingProducts = createSelector(
  getSubscription,
  (subscription) => {
    const products = get(subscription, "pending_products", []);
    return getProductsWithData(products);
  }
);

export const getHasPendingPharmaProducts = createSelector(
  getPendingProducts,
  (pendingProducts) => {
    return containsPharmaceuticalProduct(pendingProducts);
  }
);
export const getProductsSkus = createSelector(
  getSubscription,
  (subscription) => {
    const products = get(subscription, "products", []);
    return products.map((product) => product.sku);
  }
);

export const getCanDelayBillingDate = createSelector(
  getSubscription,
  (subscription) => {
    return get(subscription, "can_delay_billing_date", false);
  }
);

export const getCanSendNow = createSelector(getSubscription, (subscription) => {
  return get(subscription, "can_send_now", false);
});

export const getHasReceivedHandle = createSelector(
  getSubscription,
  (subscription) => {
    return get(subscription, "has_received_handle", false);
  }
);

export const getNextBillingDate = createSelector(
  getSubscription,
  (subscription) => {
    return get(subscription, "next_billing_date", "");
  }
);

export const getSuggestedNextBillingDate = createSelector(
  getSubscription,
  (subscription) => {
    return get(subscription, "suggested_next_billing_date", "");
  }
);

export const getMonthlyBillingDay = createSelector(
  getSubscription,
  (subscription) => {
    return get(subscription, "monthly_billing_day", null);
  }
);

export const getActiveSuperOfferId = createSelector(
  getSubscription,
  (subscription) => {
    return subscription.active_super_offer_id || 0;
  }
);

export const getCanDelayDeliveries = createSelector(
  [
    getIsMultiUser,
    getMonthlyBillingDay,
    getNextBillingDate,
    getCanDelayBillingDate,
  ],
  (isMultiUser, monthlyBillingDay, nextBillingDate, canDelayBillingDate) => {
    return (
      isMultiUser &&
      monthlyBillingDay === new Date(nextBillingDate).getDate() &&
      canDelayBillingDate
    );
  }
);

export const getCanUserMispick = createSelector(
  getSubscription,
  (subscription) => get(subscription, "mispick_self_report_allowed", false)
);

export const getCanUserReportDefectiveHandle = createSelector(
  getSubscription,
  (subscription) =>
    get(subscription, "defective_handle_self_report_allowed", false)
);

export const getCanUserResendOrders = createSelector(
  getSubscription,
  (subscription) => get(subscription, "box_resend_allowed", false)
);

export const getSingleProductDeliveryCharge = createSelector(
  getSubscription,
  (subscription) =>
    convertPoundsToPence(get(subscription, "single_product_delivery_charge", 0))
);

const getProductBySkuHelper = (state, sku) => sku;

export const getProductFrequencyBySku = createSelector(
  getProducts,
  getProductBySkuHelper,
  (products = [], sku) =>
    get(
      products.find((product) => product.id === sku),
      "frequency",
      null
    )
);

/**
 * CURRENT PARCEL
 */
export const getCurrentParcelIds = createSelector(getAccountSubData, (data) => {
  return get(data, "current_parcel", {});
});

export const getHasCurrentParcelIds = createSelector(
  getCurrentParcelIds,
  (currentParcelIds) => {
    return !!get(currentParcelIds, "invoiceable_id", false);
  }
);

/**
 * COMPOSED
 */
export const getHasApprovedOrPendingConsultation = createSelector(
  [getEdStatus, getHairLossStatus],
  (edStatus, hlStatus) => {
    return (
      edStatus === "pending" ||
      edStatus === "approved" ||
      hlStatus === "pending" ||
      hlStatus === "approved"
    );
  }
);
