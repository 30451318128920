import React from "react";
import { render } from "react-dom";
import smartlookClient from "smartlook-client";
import WebFont from "webfontloader";
import { store } from "./store";
import history from "./history";
import App from "./app";

WebFont.load({
  google: {
    families: ["Poppins:300,400,600,800"],
  },
});

const prod =
  process.env.REACT_APP_RELEASE_STAGE === "production" &&
  process.env.NODE_ENV !== "test";

if (prod) {
  smartlookClient.init("461a2d37e27e06f6ca5e44b9ab81a06564eb00f3");
  smartlookClient.consentAPI("User has given consent");
}

render(<App store={store} history={history} />, document.querySelector("#app"));
