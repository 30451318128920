export default function trimObjectStringFields(fields = {}) {
  return Object.keys(fields).reduce((result, field) => {
    const currentFieldValue = fields[field];

    if (typeof currentFieldValue === "object") {
      return { ...result, [field]: trimObjectStringFields(currentFieldValue) };
    }

    return {
      ...result,
      [field]:
        typeof currentFieldValue === "string"
          ? currentFieldValue.trim()
          : currentFieldValue,
    };
  }, {});
}
