import React from "react";
import ProblemFallback from "src/react/components/problem-fallback";
import { HelpEmail } from "src/react/components/help-email";

export default function NotFound() {
  return (
    <ProblemFallback title="Oops, this page isn't available.">
      <p>
        The link you've followed may be broken, or the page have been removed.
      </p>
      <p>
        Please email <HelpEmail /> if you think there is a problem with our
        website.
      </p>
    </ProblemFallback>
  );
}
