import React from "react";
import { Helmet } from "react-helmet";

export default function MetaData({ data, children }) {
  return (
    <Helmet>
      <title>{data.title}</title>
      <meta property="og:title" content={data.title} />
      <meta name="description" content={data.description} />
      <meta property="og:description" content={data.description} />
      <meta property="og:url" content={data.url} />
      <link rel="canonical" href={data.url} />
      {children}
    </Helmet>
  );
}
