import { useState, useCallback, useEffect } from "react";

export default function useMatchMedia(query) {
  const media = window.matchMedia(`(${query})`);
  const [mediaMatches, setMediaMatches] = useState(media.matches);
  const cb = useCallback((event) => {
    setMediaMatches(event.matches);
  }, []);
  useEffect(() => {
    media.addListener(cb);
    return () => {
      media.removeListener(cb);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return mediaMatches;
}
