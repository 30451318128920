import history from "src/react/history";
import { actionTypes as userActionTypes } from "src/redux/user/actions";
import { isCancelled } from "src/redux/user/selectors";
import makeStringMatcher from "src/lib/make-string-matcher";
import { LOCATION_CHANGED } from "src/redux/app/actions";

export default (store) => (next) => (action) => {
  next(action);

  if (
    action.type === userActionTypes.USER_AUTH_CHECK_SUCCESS ||
    action.type === LOCATION_CHANGED
  ) {
    const state = store.getState();
    const isUserCancelled = isCancelled(state);

    if (isUserCancelled) {
      const matches = makeStringMatcher(history.location.pathname);

      switch (true) {
        case matches("^/account/delay-next-box"):
        case matches("^/account/update-product-selection"):
        case matches("^/account/send-now"):
        case matches("^/account/choose-next-billing-date"):
        case matches("^/account/cancel"):
        case matches("^/account/select-billing-frequency"):
          history.replace({
            pathname: "/account",
          });
          break;

        default:
          break;
      }
    }
  }
};
