import trimObjectStringFields from "src/lib/trim-object-string-fields";
import { SET_STATE, RESET_STATE } from "src/redux/app/actions";
import { actionTypes } from "./actions";
import { actionTypes as edActionTypes } from "../ed-consultation/actions";
import { actionTypes as hairlossActionTypes } from "../hairloss-consultation/actions";
import { actionTypes as weightlossActionTypes } from "../weightloss-consultation/actions";

export const defaultState = {
  version: 1,
  data: {},
};

export default function IdCheck(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_STATE:
      return state.version === defaultState.version ? state : defaultState;

    case RESET_STATE:
    case edActionTypes.ED_CONSULTATION_SUBMIT_SUCCESS:
    case hairlossActionTypes.HAIRLOSS_CONSULTATION_SUBMIT_SUCCESS:
    case weightlossActionTypes.WEIGHTLOSS_CONSULTATION_SUBMIT_SUCCESS:
      return defaultState;

    case actionTypes.CONSULTATION_ID_CHECK_SAVE:
      return {
        ...state,
        data: {
          ...state.data,
          ...trimObjectStringFields(action.payload),
        },
      };

    default:
      return state;
  }
}
