import React from "react";
import PropTypes from "prop-types";
import { LoadingBlock } from "src/react/components/loading";
import { LayoutWrapper } from "src/react/layout";

function LoadingComponent({ backgroundColor, fullHeightLoading = true }) {
  return (
    <LayoutWrapper
      backgroundColor={backgroundColor}
      fullHeight={fullHeightLoading}
    >
      <LoadingBlock />
    </LayoutWrapper>
  );
}

LoadingComponent.propTypes = {
  backgroundColor: PropTypes.string,
  fullHeightLoading: PropTypes.bool,
};

export default LoadingComponent;
