export const SIGNUP_ONE_OFF_POSTAGE_IN_PENCE = 395;
export const SIGNUP_ONE_OFF_THRESHOLD_IN_PENCE = 2000;
export const INTERNATIONAL_POSTAGE_IN_PENCE = 500;
export const INTERNATIONAL_THRESHOLD_IN_PENCE = 1000;
export const GIFT_WRAPPING_COST_IN_PENCE = 395;
export const EXPRESS_DELIVERY_COST_IN_PENCE = 495;

export const TOPUP_MINIMUM_ORDER_VALUE_IN_PENCE = 1000;

export const GIFTS_OFFER_PERCENTAGE_DISCOUNT = 0.2;

export const ONE_STONE_IN_KG = 6.35029318;
export const ONE_POUND_IN_KG = 0.45359237;
export const ONE_FOOT_IN_CM = 30.48;
export const ONE_INCH_IN_CM = 2.54;

export const HELP_EMAIL = "help@cornerstone.co.uk";
