import { productsBySku } from "src/data/product-data";

export const getProductsWithData = (products = []) =>
  products
    .map(
      (product) =>
        productsBySku[product.sku] && {
          ...productsBySku[product.sku],
          ...(product.frequency && { frequency: product.frequency }),
          ...(product.quantity && { quantity: product.quantity }),
          outOfStock: product.out_of_stock || false,
          oneOff: product.one_off || false,
          bundled: product.bundled || false,
        }
    )
    .filter((value) => value);

export const getHasBundledProduct = (products = []) =>
  products.some((product) => product.bundled);

export const getHasOneOffProduct = (products = []) =>
  products.some((product) => product.one_off);
