import { createSelector } from "reselect";
import get from "src/lib/get";
import { getData } from ".";

export const getDeliveryDetails = createSelector(getData, (data) => {
  const deliveryDetails = get(data, "delivery_details.data", {});
  return {
    firstName: deliveryDetails.first_name || "",
    surname: deliveryDetails.surname || "",
    line1: deliveryDetails.delivery_address1 || "",
    line2: deliveryDetails.delivery_address2 || "",
    city: deliveryDetails.delivery_city || "",
    country: deliveryDetails.delivery_country || "",
    postcode: deliveryDetails.delivery_postcode || "",
    instructions: deliveryDetails.delivery_safe_place || "",
  };
});
