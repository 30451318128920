import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import useMatchMedia from "src/react/hooks/useMatchMedia";
import Container from "src/react/layout/container";
import links from "./links";
import {
  NavList,
  NavListMobileWrapper,
  NavLinkMobile,
  NavLink,
} from "./components";
import NavToggle from "./nav-toggle";

function AccountNavBar({ showMobileNav, toggleMobileNav }) {
  const mediaMatches = useMatchMedia("min-width: 768px");
  return (
    <div data-selector="AccountNavBar">
      {mediaMatches ? (
        <Container>
          <NavList>
            <li>
              <NavLink
                data-selector="AccountNavBar-your-plan"
                to={links.yourPlan.to}
              >
                {links.yourPlan.text}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.yourDetails.to}>
                {links.yourDetails.text}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.orderHistory.to}>
                {links.orderHistory.text}
              </NavLink>
            </li>
            <li>
              <NavLink data-selector="AccountNavBar-shop" to={links.shop.to}>
                {links.shop.text}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.accountHelp.to}>
                {links.accountHelp.text}
              </NavLink>
            </li>
            <li>
              <NavLink
                data-selector="AccountNavBar-logout"
                to={links.logout.to}
              >
                {links.logout.text}
              </NavLink>
            </li>
          </NavList>
        </Container>
      ) : (
        <Fragment>
          <Container>
            <NavList>
              <li>
                <NavToggle
                  data-selector="AccountNavBar-NavToggle"
                  onClick={() => toggleMobileNav(!showMobileNav)}
                  isOpen={showMobileNav}
                />
              </li>
            </NavList>
          </Container>
          <NavListMobileWrapper
            data-selector="AccountNavBar-NavListMobileWrapper"
            isOpened={showMobileNav}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <ul>
              <li>
                <NavLinkMobile
                  data-selector="AccountNavBar-your-plan"
                  to={links.yourPlan.to}
                >
                  {links.yourPlan.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile to={links.yourDetails.to}>
                  {links.yourDetails.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile to={links.orderHistory.to}>
                  {links.orderHistory.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile
                  data-selector="AccountNavBar-shop"
                  to={links.shop.to}
                >
                  {links.shop.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile to={links.accountHelp.to}>
                  {links.accountHelp.text}
                </NavLinkMobile>
              </li>
              <li>
                <NavLinkMobile
                  data-selector="AccountNavBar-logout"
                  to={links.logout.to}
                >
                  {links.logout.text}
                </NavLinkMobile>
              </li>
            </ul>
          </NavListMobileWrapper>
        </Fragment>
      )}
    </div>
  );
}

AccountNavBar.propTypes = {
  toggleMobileNav: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  showMobileNav: PropTypes.bool,
};

const MemoisedAccountNavBar = memo(AccountNavBar);

export default MemoisedAccountNavBar;
