import api, { handleError } from "../../index";
import queryStringArray from "src/lib/query-string-array";
import meApiInstance from "./api";

export const meService = ({ apiToken, includes = [] }) => {
  return meApiInstance({
    method: "get",
    headers: {
      ...meApiInstance.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
    url: `/users/me${queryStringArray(includes)}`,
  })
    .then((response = {}) => {
      return { error: null, data: response.data.data };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
};

export const saveUserService = ({
  apiToken,
  current_password,
  password,
  email,
}) =>
  api({
    method: "patch",
    url: "/users/me",
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
    data: { current_password, password, email },
  })
    .then((response = {}) => {
      return { error: null, data: response.data.data };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
