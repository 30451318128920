import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaUserCircle } from "react-icons/fa";
import links from "./links";
import { NavLink } from "./components";

export function NavAccountLink({
  iconSize = 20,
  isAuthenticated,
  isUserAccountActive,
}) {
  if (isAuthenticated && isUserAccountActive)
    return (
      <NavLink
        to={links.account.to}
        withIcon
        trackingOptions={{ buttonLabel: links.account.text }}
      >
        <Icon size={iconSize} /> {links.account.text}
      </NavLink>
    );

  if (isAuthenticated && !isUserAccountActive)
    return <NavLink to={links.logout.to}>{links.logout.text}</NavLink>;

  return <NavLink to={links.login.to}>{links.login.text}</NavLink>;
}

NavAccountLink.propTypes = {
  iconSize: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  isUserAccountActive: PropTypes.bool,
};

export default NavAccountLink;

const Icon = styled(FaUserCircle)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;
