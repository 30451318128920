import React from "react";
import styled, { css, keyframes } from "styled-components";
import { color } from "src/styles/variables";

function Loading({ text }) {
  return (
    <LoadingContainer>
      <LoadingText>{text || "loading"}</LoadingText>
      <LoadingDot1>.</LoadingDot1>
      <LoadingDot2>.</LoadingDot2>
      <LoadingDot3>.</LoadingDot3>
    </LoadingContainer>
  );
}

export default Loading;

export function LoadingSpinner({ size = 45, text }) {
  return (
    <LoadingSpinnerWrapper>
      <Spinner size={size} />
      {text && <LoadingSpinnerText>{text}</LoadingSpinnerText>}
    </LoadingSpinnerWrapper>
  );
}

export function LoadingSpinnerBlock(props) {
  return (
    <LoadingWrapper>
      <LoadingSpinner {...props} />
    </LoadingWrapper>
  );
}

export function LoadingBlock({ text, ...props }) {
  return (
    <LoadingWrapper {...props}>
      <Loading text={text} />
    </LoadingWrapper>
  );
}
LoadingBlock.displayName = "LoadingBlock";

const LoadingContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.span`
  margin-right: 0.1em;
`;

const loadingDotAnimation = keyframes`
  0% { opacity: 0.2 }
  20% { opacity: 1 }
  100% { opacity: 0.2 }
`;
const loadingDotStyles = css`
  animation-name: ${loadingDotAnimation};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
`;
const LoadingDot1 = styled.span`
  ${loadingDotStyles};
  animation-delay: 0s;
`;
const LoadingDot2 = styled.span`
  ${loadingDotStyles};
  animation-delay: 0.2s;
`;
const LoadingDot3 = styled.span`
  ${loadingDotStyles};
  animation-delay: 0.4s;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const rotateSpinner = keyframes`
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
`;

export const LoadingSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinnerText = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
`;

const Spinner = styled.div`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  position: relative;

  :before,
  :after {
    content: "";
    border-radius: 50%;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    position: absolute;
  }

  :before {
    backface-visibility: hidden;
    perspective: 1000;
    transform: translateZ(0);
    animation: ${rotateSpinner} 0.8s infinite linear;
    border: 4px solid ${color.red};
    border-top-color: transparent;
    border-right-color: transparent;
    z-index: 2;
  }

  :after {
    border: 4px solid ${color.red};
    z-index: 1;
    opacity: 0.3;
  }
`;
