import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { getBasketQuantity } from "src/redux/signup/selectors";
import { TiShoppingCart } from "react-icons/ti";
import { NavLink } from "./components";

export function NavBasketLink({ to, text, size = 22, basketQuantity = 0 }) {
  return (
    <li>
      <Link
        to={to}
        title={text}
        data-selector="NavBasketLink"
        trackingOptions={{ buttonLabel: "NavBasketLink" }}
      >
        <Icon size={size} />
        <Text>{text}</Text>
        {basketQuantity > 0 && (
          <Quantity data-selector="NavBasketLink-Quantity">
            ({basketQuantity})
          </Quantity>
        )}
      </Link>
    </li>
  );
}

NavBasketLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  size: PropTypes.number,
  basketQuantity: PropTypes.number,
};

const mapStateToProps = (state) => ({
  basketQuantity: getBasketQuantity(state),
});

export default connect(mapStateToProps)(NavBasketLink);

const Text = styled.span`
  text-transform: uppercase;
  font-weight: 400;
`;

const Link = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 7px;
  padding-left: 9px;
  margin: 0 10px;
  @media (min-width: 400px) {
    padding-left: 20px;
    padding-right: 14px;
  }
  @media (min-width: 768px) {
    padding-left: 25px;
    padding-right: 17px;
  }
`;

const Icon = styled(TiShoppingCart)`
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  @media (min-width: 400px) {
    left: 0;
  }
`;

const Quantity = styled.span`
  position: absolute;
  left: calc(100% - 6px);
  @media (min-width: 400px) {
    left: calc(100% - 12px);
  }
  @media (min-width: 768px) {
    left: calc(100% - 14px);
  }
`;
