import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color } from "src/styles/variables";

function SectionHeader({ title, description, children, noPadding, ...props }) {
  return (
    title && (
      <Header data-selector="SectionHeader" {...props}>
        <HeaderTitle noPadding={noPadding}>{title}</HeaderTitle>
        {description && <HeaderContent as="p">{description}</HeaderContent>}
        {children && <HeaderContent>{children}</HeaderContent>}
      </Header>
    )
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noPadding: PropTypes.bool,
};

export default SectionHeader;

const breakpoint = "@media (min-width: 768px)";

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
  ${breakpoint} {
    margin-bottom: 40px;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: ${color.grey};
  ${({ noPadding }) => !noPadding && `padding: 0 10px;`}
  ${breakpoint} {
    font-size: 36px;
  }
`;

const HeaderContent = styled.div`
  margin: 10px auto 0;
  max-width: 65ch;
`;
