import axios from "axios";
import history from "src/react/history";
import handleError from "../handle-error";

function handleUnauthorised(response = {}) {
  if (response.status === 401 && history.location.pathname !== "/login") {
    history.push({ pathname: "/login", state: { from: history.location } });
  }
}

export { handleError };

export const apiDefaults = {
  baseURL: process.env.REACT_APP_API_URL + "/api/v3",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  validateStatus: (status) => status >= 200 && status < 400,
};

const api = axios.create(apiDefaults);

api.interceptors.response.use(
  (response = {}) => {
    handleUnauthorised(response);
    return response;
  },
  (error = {}) => {
    handleUnauthorised(error.response);
    return Promise.reject(error);
  }
);

export default api;
