import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { color } from "src/styles/variables";
import Section from "./section/section";
import SectionHeader from "./section/section-header";
import Container from "./container";
import Header from "./header";
import Main from "./main";
import Footer from "./footer";

export { Section, SectionHeader, Container, Header, Main, Footer };

export function DefaultLayout(props) {
  return (
    <Fragment>
      <Header hideNav={props.hideMainNav} />
      <Main background={props.background}>{props.children}</Main>
      {!props.hideFooter && (
        <Footer
          includeLinks={props.footerLinks}
          hideNav={props.hideFooterNav}
        />
      )}
    </Fragment>
  );
}
DefaultLayout.propTypes = {
  background: PropTypes.string,
  footerLinks: PropTypes.bool,
  hideMainNav: PropTypes.bool,
  hideFooterNav: PropTypes.bool,
  hideFooter: PropTypes.bool,
  children: PropTypes.node,
};

export const LayoutWrapper = styled.div`
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${color[backgroundColor]}`};
  ${({ fullHeight }) => fullHeight && `height: 100vh`};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export function SectionLayout({ title, description, children, ...props }) {
  return (
    <Container {...props}>
      <Section noBorder>
        {title && (
          <SectionHeader
            title={title}
            description={description}
            noPadding={true}
          />
        )}
        {children}
      </Section>
    </Container>
  );
}

SectionLayout.propTypes = {
  title: PropTypes.node,
  description: PropTypes.any,
  children: PropTypes.node,
};
