import api, { handleError } from "../index";
import { getSessionGuid } from "src/lib/tracking-cookies";

export function oneOffOrderProductsService({ apiToken, products }) {
  return api({
    method: "post",
    url: `/one-off-order-request`,
    data: { one_off_products: products, session_guid: getSessionGuid() },
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
  })
    .then((response) => ({ error: null, data: response.data }))
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
}
