import get from "src/lib/get";
import { productsBySku } from "src/data/product-data";
import { HANBLADE_SKU, ENGRAVED_HANDLE_SKU } from "src/data/product-skus";
import formatCurrency from "src/lib/format-currency";

const parseProductId = (productId = "", engraving = "") => {
  if (engraving !== "" && productId === HANBLADE_SKU) {
    return ENGRAVED_HANDLE_SKU;
  }
  return productId;
};

export function getTotalPrice(products = {}) {
  return Object.keys(products).reduce((result, sku) => {
    const product = products[sku];
    const price = get(productsBySku[sku], "priceInPence", 0);
    const quantity = parseInt(product.quantity) || 1;
    return result + price * quantity;
  }, 0);
}

export function getCouponFormatedDiscountAmountString(coupon) {
  const { discount_amount_type: type, discount_amount: amount } = coupon;

  if (type === "percentage") {
    return `${amount}%`;
  }

  if (type === "absolute") {
    return `${formatCurrency({ value: amount })}`;
  }

  return `${formatCurrency({ value: 0 })}`;
}

export function getOneOffProductsPayload(oneOffProducts = {}) {
  return Object.keys(oneOffProducts).reduce((result, sku) => {
    const product = oneOffProducts[sku];

    if (!productsBySku[sku]) return result;

    const productAfterQuantity = new Array(parseInt(product.quantity))
      .fill(undefined)
      .map((_, index) => {
        const engraving = get(product, "options.engraving", [])[index] || [];
        const trimmedEngraving = engraving.join("").trim();
        return {
          sku: parseProductId(sku, trimmedEngraving),
          options: {
            ...(trimmedEngraving !== "" && {
              engraved_initials: trimmedEngraving,
            }),
          },
        };
      });
    return [...result, ...productAfterQuantity];
  }, []);
}
