import api, { handleError } from "../index";

export function userCheckEmailService(email = "") {
  const emailPayload = email ? email.trim() : "";
  return api({
    method: "get",
    url: `/users/check-email/${emailPayload}`,
  })
    .then((response = {}) => {
      return { error: null, data: response.data };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
}
