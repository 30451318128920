import api, { handleError } from "../index";
import { getSessionGuid } from "src/lib/tracking-cookies";

export const fetchParcelAddressService = ({
  apiToken,
  invoiceableType,
  invoiceableId,
}) => {
  return api({
    method: "get",
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
    url: `/parcel/address/${invoiceableType}/${invoiceableId}`,
  })
    .then((response) => ({ error: null, data: response.data.data }))
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
};

export const updateParcelAddressService = ({
  apiToken,
  invoiceableType,
  invoiceableId,
  payload = {},
}) => {
  const data = {
    first_name: payload.firstName,
    surname: payload.surname,
    delivery_address1: payload.firstLine,
    delivery_address2: payload.secondLine,
    delivery_city: payload.city,
    delivery_postcode: payload.postcode,
    delivery_country: payload.country,
    session_guid: getSessionGuid(),
  };
  return api({
    method: "put",
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
    url: `/parcel/address/${invoiceableType}/${invoiceableId}`,
    data,
  })
    .then((response) => ({ error: null, data: response.data }))
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
};
