import styled, { css } from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  max-width: ${({ maxWidth }) => maxWidth || "1000px"};
  ${({ css }) => css && containerCSSStyles(css)};
`;
const containerCSSStyles = (styles) => css`
  ${styles};
`;

export default Container;
