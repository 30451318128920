import history from "src/react/history";
import trackTransaction from "src/analytics/transaction-tracking";
import smartlookClient from "smartlook-client";
import { subscriptionCheckoutService } from "src/services/cornerstone/subscription/checkout";
import { authenticatePaymentService } from "src/services/cornerstone/payments/authenticate";
import {
  putSubscriptionCoupon,
  deleteSubscriptionCouponAction,
} from "src/redux/coupons/actions";
import { signupResetAction } from "src/redux/signup/actions";
import { requiresPaymentAuthorisation } from "src/redux/app/actions";
import {
  getCouponMinimumSpendTooLow,
  getEngravingString,
  getSignupDiscountInPounds,
  getSignupSelectedProductsCheckoutPayload,
  getSignupOrderValue,
  getSignupTransactionProducts,
  getHasSubmittedSignupCoupon,
  getHasPharmaProductInSignupBasket,
} from "src/redux/signup/selectors";
import { getData as getUserData, getUserToken } from "src/redux/user/selectors";
import { getCouponCode, getCouponId } from "src/redux/coupons/selectors";
import { submitPharmaConsultations } from "./pharma-consultations";

const prod =
  process.env.REACT_APP_RELEASE_STAGE === "production" &&
  process.env.NODE_ENV === "production";

export const actionTypes = {
  SIGNUP__COMPLETION_PENDING: `SIGNUP__COMPLETION_PENDING`,
  SIGNUP__COMPLETION_SUCCESS: `SIGNUP__COMPLETION_SUCCESS`,
  SIGNUP__COMPLETION_ERROR: `SIGNUP__COMPLETION_ERROR`,
};

export const checkout = (actionPayload) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SIGNUP__COMPLETION_PENDING });

  const state = getState();
  const apiToken = getUserToken(state);
  const couponId = getCouponId(state);
  const { products, oneOffProducts } = getSignupSelectedProductsCheckoutPayload(
    state
  );
  const { cardDetails, deliveryDetails, phoneDetails } = actionPayload;
  const deliveryDetailsPayload = {
    first_name: deliveryDetails.firstName,
    surname: deliveryDetails.surname,
    delivery_address1: deliveryDetails.firstLine,
    delivery_address2: deliveryDetails.secondLine,
    delivery_city: deliveryDetails.city,
    delivery_postcode: deliveryDetails.postcode,
    delivery_country: deliveryDetails.country,
    delivery_safe_place: deliveryDetails.safePlace,
  };
  const engraving = getEngravingString(state);

  if (getCouponMinimumSpendTooLow(state)) {
    dispatch(deleteSubscriptionCouponAction());
  } else if (!getHasSubmittedSignupCoupon(state) && couponId) {
    await dispatch(putSubscriptionCoupon(couponId)).then(() => {});
  }

  const checkoutResponse = await subscriptionCheckoutService({
    apiToken,
    payload: {
      products,
      one_off_products: oneOffProducts,
      engraving,
      card_details: cardDetails,
      delivery_details: deliveryDetailsPayload,
      phone_number: phoneDetails.phoneNumber,
      phone_number_country_code: phoneDetails.phoneNumber
        ? phoneDetails.phoneCountryCode
        : "",
    },
  });

  if (checkoutResponse.error) {
    return dispatch({
      type: actionTypes.SIGNUP__COMPLETION_ERROR,
      payload: checkoutResponse.error,
    });
  }

  if (checkoutResponse.data.Authentication === "3DS1") {
    dispatch(requiresPaymentAuthorisation(checkoutResponse.data));
  } else {
    dispatch(authoriseCheckout(checkoutResponse));
  }
};

export const authoriseCheckout = (actionPayload) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const user = getUserData(state) || {};
  const apiToken = getUserToken(state);
  const hasPharmaProductInSignupBasket = getHasPharmaProductInSignupBasket(
    state
  );
  let checkoutData = actionPayload || {};

  if (checkoutData.PaymentData) {
    checkoutData = await authenticatePaymentService({
      apiToken,
      payload: checkoutData,
    });

    if (checkoutData.error) {
      return dispatch({
        type: actionTypes.SIGNUP__COMPLETION_ERROR,
        payload: checkoutData.error,
      });
    }
  }
  const transactionTotal = getSignupOrderValue(state);
  const transactionId = checkoutData.data && checkoutData.data.transaction_id;

  dispatch({
    type: actionTypes.SIGNUP__COMPLETION_SUCCESS,
  });

  history.push({
    pathname: "/signup-thanks",
    state: {
      transactionTotal,
      transactionId,
      hasPharma: hasPharmaProductInSignupBasket,
    },
  });

  if (hasPharmaProductInSignupBasket) {
    dispatch(submitPharmaConsultations());
  }

  if (transactionId) {
    trackTransaction({
      currency: "GBP",
      discountAmount: getSignupDiscountInPounds(state),
      discountCode: getCouponCode(state),
      transactionId,
      transactionProducts: getSignupTransactionProducts(state),
      transactionTotal,
      hasPharma: hasPharmaProductInSignupBasket,
    });
  }

  dispatch(signupResetAction());

  if (prod) {
    smartlookClient.identify(user.id, {
      name: user.id,
    });
  }
};
