import app from "src/redux/app/reducer";
import signup from "src/redux/signup/reducer";
import user from "src/redux/user/reducer";
import parcel from "src/redux/parcel/reducer";
import coupons from "src/redux/coupons/reducer";
import edConsultation from "src/redux/consultations/ed-consultation/reducer";
import hairLossConsultation from "src/redux/consultations/hairloss-consultation/reducer";
import weightLossConsultation from "src/redux/consultations/weightloss-consultation/reducer";
import idCheck from "src/redux/consultations/id-check/reducer";

export const initialReducers = {
  app,
  coupons,
  parcel,
  signup,
  user,
  edConsultation,
  hairLossConsultation,
  weightLossConsultation,
  idCheck,
};
