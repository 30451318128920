import api, { handleError } from "../index";

export const storeSession = async (payload) => {
  try {
    const response = await api({
      method: "post",
      url: "/sessions",
      validateStatus: (status) => status === 200,
      data: {
        lifetime_guid: payload.lifetime_guid,
        session_guid: payload.session_guid,
        user_agent: payload.user_agent,
        landing_path: payload.landing_path,
        full_query_string: payload.full_query_string,
        utm_medium: payload.utm_medium,
        utm_source: payload.utm_source,
        utm_campaign: payload.utm_campaign,
        utm_content: payload.utm_content,
        utm_term: payload.utm_term,
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    const errorPayload = handleError(error);
    return {
      ...errorPayload,
      data: null,
    };
  }
};
