import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isAuthenticated as getIsAuthenticated } from "src/redux/user/selectors";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "src/react/layout/container";
import { color } from "src/styles/variables";
import SocialLinks from "./social-links";

export function Footer({ hideNav, isAuthenticated }) {
  const fullYear = new Date().getFullYear();
  return (
    <Wrapper>
      {!hideNav && <SocialLinks />}
      <NavWrapper hiddenNav={hideNav}>
        {!hideNav && (
          <Nav>
            <NavList>
              <li>
                <NavItem to="/regulatory-information">Regulatory</NavItem>
              </li>
              <li>
                <NavItem to="/terms-and-conditions">Terms & Conditions</NavItem>
              </li>
              <li>
                <NavItem to="/privacy-policy">Privacy Policy</NavItem>
              </li>
              <li>
                <NavItem
                  lastItem
                  to={isAuthenticated ? "/account/help" : "/help"}
                >
                  Help
                </NavItem>
              </li>
            </NavList>
          </Nav>
        )}
        <Disclaimer>© CSTT Limited {fullYear} All Rights Reserved</Disclaimer>
      </NavWrapper>
    </Wrapper>
  );
}
Footer.propTypes = {
  hideNav: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
});

export default connect(mapStateToProps)(Footer);

const Wrapper = styled.footer`
  background: ${color.red};
  padding: 20px 0;
  text-align: center;
  color: ${color.white};
`;

const NavWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  max-width: 160px;
  @media (min-width: 400px) {
    max-width: 250px;
  }
  @media (min-width: 760px) {
    max-width: 600px;
  }
  .pharma-logo-link {
    ${({ hiddenNav }) =>
      !hiddenNav ? "position: absolute; right: -60px;" : "margin: 5px 20px 0;"};
  }
`;

const Nav = styled(Container)`
  font-size: 12px;
  @media (min-width: 930px) {
    font-size: 15;
  }
`;

const NavList = styled.ul`
  margin-bottom: 10px;
  @media (min-width: 760px) {
    display: flex;
    justify-content: center;
  }
`;

const NavItem = styled(({ lastItem, ...props }) => <Link {...props} />)`
  display: block;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${color.white};
  padding: 10px 20px;
  font-size: 14px;
  @media (min-width: 760px) {
    border-right-color: ${color.white};
    border-right-width: ${({ lastItem }) => (lastItem ? 0 : "1px")};
    border-right-style: solid;
    padding: 0 20px;
  }
`;

const Disclaimer = styled.div`
  color: ${color.white};
  font-size: 0.75em;
  display: block;
`;
