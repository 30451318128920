import { defaultSubscribeProductFrequencies } from "src/data/default-product-frequencies";
import { RESET_STATE, FULLY_INITIALIZED } from "src/redux/app/actions";
import { mutuallyExclusiveProducts } from "src/data/products-subscribe";
import { isPharmaProduct, isEDProduct } from "src/data/helpers";
import { RAZOR_SKU, HANBLADE_SKU } from "src/data/product-skus";
import { actionTypes as signupCheckoutActionTypes } from "src/redux/signup/actions/checkout";
import { actions as signupActions } from "src/redux/signup/actions";
import {
  putSubscriptionCouponActionTypes,
  getCouponActionTypes,
} from "src/redux/coupons/actions";

export const defaultState = {
  version: 20,
  signupActivationTimestamp: 0,
  hasSubmittedSubscribeCoupon: false,
  engraving: ["", "", ""],
  basketType: "subscribe",
  basket: {},
  basketOneOff: {},
  completionPending: false,
  completionSuccess: false,
  completionError: null,
  country: "United Kingdom",
};

export default function signup(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case FULLY_INITIALIZED:
      return state.version === defaultState.version ? state : defaultState;

    case RESET_STATE:
    case signupActions.SIGNUP__RESET_STATE:
      return defaultState;

    case signupActions.SIGNUP__RESET_PRODUCTS:
      return {
        ...state,
        basket: {},
        basketOneOff: {},
      };

    case signupActions.SIGNUP__SET_BASKET_TYPE:
      return {
        ...state,
        basketType: payload,
      };

    case signupActions.SIGNUP__ADD_PRODUCT_TO_BASKET: {
      const basketOneOff =
        isPharmaProduct(payload.sku) && !isEDProduct(payload.sku)
          ? state.basketOneOff
          : {
              ...state.basketOneOff,
              [payload.sku]: {
                isSelected: true,
                quantity: payload.quantity || "1",
                options:
                  payload.sku === HANBLADE_SKU
                    ? {
                        engraving: [["", "", ""]],
                      }
                    : {},
              },
            };

      return {
        ...state,
        basket: {
          ...filterMutuallyExclusiveProducts(
            mutuallyExclusiveProducts,
            payload.sku,
            state.basket
          ),
          [payload.sku]: {
            isSelected: true,
            frequency:
              payload.frequency ||
              defaultSubscribeProductFrequencies[payload.sku],
          },
        },
        basketOneOff,
      };
    }

    case signupActions.SIGNUP__REMOVE_PRODUCT_IN_BASKET: {
      const currentSubscribeBasket = { ...state.basket };
      const currentOneOffBasket = { ...state.basketOneOff };
      delete currentSubscribeBasket[payload];
      delete currentOneOffBasket[payload];
      return {
        ...state,
        basket: currentSubscribeBasket,
        basketOneOff: currentOneOffBasket,
        engraving:
          payload === RAZOR_SKU ? defaultState.engraving : state.engraving,
      };
    }

    case signupActions.SIGNUP__UPDATE_ENGRAVING:
      return {
        ...state,
        engraving: state.engraving.map((value, index) =>
          index === payload.index ? payload.value : value
        ),
      };

    case signupActions.SIGNUP__UPDATE_ONE_OFF_ENGRAVING:
      return {
        ...state,
        basketOneOff: {
          ...state.basketOneOff,
          [HANBLADE_SKU]: {
            ...state.basketOneOff[HANBLADE_SKU],
            options: {
              engraving: updateOneOffEngraving(
                state.basketOneOff[HANBLADE_SKU].options.engraving,
                payload.letter,
                payload.letterIndex,
                payload.engravingIndex
              ),
            },
          },
        },
      };

    case signupActions.SIGNUP__UPDATE_PRODUCT_FREQUENCY:
      return {
        ...state,
        basket: {
          ...state.basket,
          [payload.sku]: {
            ...state.basket[payload.sku],
            frequency: payload.frequency,
          },
        },
      };

    case signupActions.SIGNUP__UPDATE_PRODUCT_QUANTITY:
      return {
        ...state,
        basketOneOff: {
          ...state.basketOneOff,
          [payload.sku]: {
            ...state.basket[payload.sku],
            quantity: payload.quantity,
            ...(payload.sku === HANBLADE_SKU && {
              options: {
                engraving: updateOneOffEngravingQuantity(
                  state.basketOneOff[payload.sku].options.engraving,
                  payload.quantity
                ),
              },
            }),
          },
        },
      };

    case getCouponActionTypes.SIGNUP_COUPONS__GET_SUCCESS:
      return {
        ...state,
        hasSubmittedSubscribeCoupon: false,
      };

    case putSubscriptionCouponActionTypes.SUBSCRIPTION__COUPON_PUT_SUCCESS:
      return {
        ...state,
        hasSubmittedSubscribeCoupon: true,
      };

    case signupCheckoutActionTypes.SIGNUP__COMPLETION_PENDING:
      return {
        ...state,
        completionPending: true,
        completionSuccess: false,
        completionError: null,
      };

    case signupCheckoutActionTypes.SIGNUP__COMPLETION_SUCCESS:
      return {
        ...state,
        signupActivationTimestamp: Date.now(),
        completionPending: false,
        completionSuccess: true,
        completionError: null,
      };

    case signupCheckoutActionTypes.SIGNUP__COMPLETION_ERROR:
      return {
        ...state,
        completionPending: false,
        completionSuccess: false,
        completionError: action.payload,
      };

    case signupActions.SIGNUP__SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };

    default:
      return state;
  }
}

function filterMutuallyExclusiveProducts(
  mutuallyExclusiveProducts,
  product,
  basket
) {
  const exclusiveProducts = { ...basket };
  delete exclusiveProducts[mutuallyExclusiveProducts[product]];
  return exclusiveProducts;
}

function updateOneOffEngraving(
  engravings,
  letter,
  letterIndex,
  newEngravingIndex
) {
  return engravings.map((engraving, engravingIndex) => {
    if (engravingIndex === newEngravingIndex) {
      engraving[letterIndex] = letter;
    }
    return engraving;
  });
}

function updateOneOffEngravingQuantity(engravings, newQuantity) {
  if (newQuantity < engravings.length) {
    return engravings.slice(0, newQuantity);
  }
  return [
    ...engravings,
    ...new Array(newQuantity - engravings.length).fill(["", "", ""]),
  ];
}
