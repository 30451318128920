import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import history from "src/react/history";
import { logoutAction } from "src/redux/user/actions";
import { isAppReady as getIsAppReady } from "src/redux/app/selectors";
import { LoadingBlock } from "src/react/components/loading";

function Logout({ logout, isAppReady }) {
  useEffect(() => {
    if (isAppReady) {
      logout();
      history.push("/");
    }
  }, [logout, isAppReady]);

  return <LoadingBlock />;
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  isAppReady: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAppReady: getIsAppReady(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
