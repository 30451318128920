import { submitHairLossConsultation } from "src/redux/consultations/hairloss-consultation/actions";
import { submitWeightLossConsultation } from "src/redux/consultations/weightloss-consultation/actions";
import { submitEDConsultation } from "src/redux/consultations/ed-consultation/actions";
import {
  getHasEDProductInSignupBasket,
  getHasHairLossProductInSignupBasket,
  getHasWeightLossProductInSignupBasket,
} from "src/redux/signup/selectors";

export const submitPharmaConsultations = () => (dispatch, getState) => {
  const state = getState();

  if (getHasEDProductInSignupBasket(state)) {
    dispatch(submitEDConsultation());
  }

  if (getHasHairLossProductInSignupBasket(state)) {
    dispatch(submitHairLossConsultation());
  }

  if (getHasWeightLossProductInSignupBasket(state)) {
    dispatch(submitWeightLossConsultation());
  }
};
