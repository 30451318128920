import cookie from "cookie";
import api, { handleError } from "../index";
import { getSessionGuid } from "src/lib/tracking-cookies";

export function registerUserService(payload) {
  const cookies = cookie.parse(document.cookie);
  const referralCode = cookies.referreral || null;
  const email = payload.email ? payload.email.trim() : "";
  const password = payload.password ? payload.password.trim() : "";

  return api({
    method: "post",
    url: "/users",
    data: {
      session_guid: getSessionGuid(),
      email,
      password,
      ...(referralCode && { referrer: referralCode }),
    },
  })
    .then((response = {}) => {
      return { error: null, data: response.data.data };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
}
