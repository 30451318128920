import smartlookClient from "smartlook-client";

export default function trackError({ error, errorMessage, state, action }) {
  error = error || Error("No error provided to trackError");
  errorMessage = errorMessage || "No errorMessage provided to trackError";
  state = format(state) || "No state provided to trackError";
  action = format(action) || "No action provided to trackError";

  if (process.env.REACT_APP_RELEASE_STAGE === "production") {
    try {
      if (window.trackJs) {
        window.trackJs.addMetadata("error", error);
        window.trackJs.addMetadata("errorMessage", errorMessage);
        window.trackJs.addMetadata("state", state);
        window.trackJs.addMetadata("action", action);
        window.trackJs.track(errorMessage);
      }
      if (process.env.NODE_ENV !== "test") {
        smartlookClient.error(error);
      }
    } catch (exception) {
      console.error(exception);
    }
  } else if (process.env.NODE_ENV !== "test") {
    console.error(errorMessage, { error, errorMessage, state, action });
  }
}

const format = (input) => JSON.stringify(input, null, 2);
