import nanoid from "nanoid";
import api, { handleError } from "../index";

export const storePageview = (payload) => {
  api({
    method: "post",
    url: "/pageviews",
    data: {
      session_guid: payload.session_guid,
      pageview_guid: payload.pageview_guid || nanoid(),
      path: payload.path,
    },
  })
    .then((response = {}) => {
      return { error: null, data: response.data };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
};
