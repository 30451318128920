import api, { handleError } from "../index";
import { getSessionGuid } from "src/lib/tracking-cookies";

export function updateProductsService(apiToken, products) {
  return api({
    method: "put",
    url: `/subscription/products`,
    data: { products, session_guid: getSessionGuid() },
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
  })
    .then((response = {}) => {
      return { error: null, data: response.data };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
}
