import api, { handleError } from "../index";
import { getSessionGuid } from "src/lib/tracking-cookies";

function makePutCouponService(url) {
  return ({ apiToken, couponId, force }) =>
    api({
      method: "put",
      url,
      data: {
        coupon_id: couponId,
        force,
        session_guid: getSessionGuid(),
      },
      headers: {
        ...api.defaults.headers,
        Authorization: `Bearer ${apiToken}`,
      },
    })
      .then((response = {}) => {
        return { error: null, data: response.data.data };
      })
      .catch((error) => {
        const response = error.response || {};
        const data = response.data || {};

        if (data["error-code"] === "coupon-already-added") {
          return { error: null, data };
        }

        const errorPayload = handleError(error);
        return {
          ...errorPayload,
          data: null,
        };
      });
}

export const putCouponService = makePutCouponService("/subscription/coupon");
export const putPharmaCouponService = makePutCouponService(
  "/subscription/pharma-coupon"
);
export const applyTopupCouponService = makePutCouponService(
  "/subscription/coupon/apply-topup-discount"
);

export function deleteSubscriptionCouponService(apiToken) {
  return api({
    method: "delete",
    url: `/subscription/coupon`,
    data: { session_guid: getSessionGuid() },
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
  })
    .then((response = {}) => {
      return { error: null, data: response };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
}
