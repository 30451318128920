import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SectionLayout } from "src/react/layout";
import { HelpEmail } from "src/react/components/help-email";

function ProblemFallback({ title, children, ...props }) {
  return (
    <SectionLayout title={title} {...props}>
      <Copy>
        {children || (
          <Fragment>
            <p>Please refresh the page and try again.</p>
            <p>
              If the issue continues please{" "}
              <HelpEmail>email our customer service</HelpEmail>.
            </p>
          </Fragment>
        )}
      </Copy>
    </SectionLayout>
  );
}

export default ProblemFallback;

ProblemFallback.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

const Copy = styled.div`
  max-width: 500;
  margin: auto;
  text-align: center;
`;
