import get from "src/lib/get";
import isEmpty from "src/lib/isEmpty";
import { createSelector } from "reselect";

export { canBeSentNow } from "./can-be-sent-now";
export { canDelayBillingDate } from "./can-delay-billing-date";
export { getCardDetails } from "./get-card-details";
export { getCoupons, getTopupCoupons } from "./get-coupons";
export { getDeliveryDetails } from "./get-delivery-details";
export { getEmail } from "./get-email";
export { getMonthlyBillingDay } from "./get-monthly-next-billing-day";
export { getNextBillingDate } from "./get-next-billing-date";
export { getReturnedOrders } from "./get-returned-orders";
export { getReferralLink } from "./get-referral-link";
export { getSuggestedNextBillingDate } from "./get-suggested-next-billing-date";
export { getUserId } from "./get-user-id";
export { returnedOrdersHasError } from "./returned-orders-has-error";
export { returnedOrdersIsPending } from "./returned-orders-is-pending";

export function hasData(state) {
  return !isEmpty(getData(state));
}

export function getData(state) {
  return get(state, "user.data", {});
}

export function getError(state) {
  return get(state, "user.error", null);
}

export function isPending(state) {
  return get(state, "user.isPending", false);
}

export function getUser(state) {
  return get(state, "user");
}

export const getUserToken = createSelector(getUser, (user) => {
  return get(user, "apiToken", null);
});

export const isAuthenticated = createSelector(getUserToken, (token) => {
  return token != null;
});

export const restrictTo = createSelector(getUser, (user = {}) =>
  user ? user.restrictTo : []
);

export const isPaused = createSelector(getData, (data) => {
  return get(data, "is_paused", false);
});

export const getSubscriptionType = createSelector(getData, (data) => {
  return get(data, "subscription_type", null);
});

export const isClassicUser = createSelector(
  getSubscriptionType,
  (subscriptionType) => {
    return subscriptionType === "classic";
  }
);

export const isMultiProductUser = createSelector(
  getSubscriptionType,
  (subscriptionType) => {
    return subscriptionType === "multi";
  }
);

export const isCancelled = createSelector(getData, (data) => {
  return data && data.status === "cancelled";
});

export const getIsSubscribed = createSelector(getData, (data) => {
  return data && data.status === "subscribed";
});

export const getIsNotSubscribed = createSelector(getData, (data) => {
  return data && data.status === "not_subscribed";
});

export const getIsUserStatusPending = createSelector(getData, (data) => {
  return data && data.status === "pending";
});

export const getIsUserAccountActive = createSelector(getData, (data) => {
  return !!data && !!data.status && data.status !== "pending";
});
