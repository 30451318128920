import React from "react";
import { Link as RRLink, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import Tracking from "src/react/components/tracking";
import { color, font, link } from "src/styles/variables";
import { defaultStyles } from "src/react/components/button/button-base";

const linkStyles = css`
  ${({ inline }) =>
    inline ? "display: inline; width: auto;" : "display: block;"};
  ${({ align }) => (align === "center" ? "width: 100%;" : "")};
  text-align: ${({ align = "left" }) => align};
  cursor: pointer;
  font-family: ${font.primary};
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  transition-property: color;
  transition-duration: 0.3s;
  color: ${color.midBlue};
  padding: 0;
  font-size: 16px;
  text-decoration: none;
  :hover {
    color: ${color.blue};
  }
`;

export const TrackedUnstyledLink = ({
  tracking,
  trackingOptions,
  children,
  ...props
}) => (
  <Tracking tracking={tracking} trackingOptions={trackingOptions}>
    <a {...props}>{children}</a>
  </Tracking>
);

export const TrackedRouterLink = ({ tracking, trackingOptions, ...props }) => (
  <Tracking tracking={tracking} trackingOptions={trackingOptions}>
    <RRLink {...props} />
  </Tracking>
);

const Adapter = (BaseComponent) => ({
  inProgress,
  isSelected,
  align,
  css,
  isActive,
  size,
  variant,
  inline,
  fullWidth,
  ...rest
}) => <BaseComponent {...rest} />;

export const RRNavLink = ({ tracking, trackingOptions, ...props }) => (
  <Tracking tracking={tracking} trackingOptions={trackingOptions}>
    <NavLink {...props} />
  </Tracking>
);

export const Link = styled(Adapter(TrackedRouterLink))`
  ${linkStyles}
`;
Link.displayName = "Link";

export const A = styled(Adapter(TrackedUnstyledLink))`
  ${link};
`;

export const LinkButton = styled(Adapter(TrackedRouterLink))`
  ${defaultStyles}
`;
LinkButton.displayName = "LinkButton";

export const ExternalLinkButton = styled(Adapter(TrackedUnstyledLink))`
  ${defaultStyles}
`;
ExternalLinkButton.displayName = "ExternalLinkButton";
