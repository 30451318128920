import trackError from "src/lib/track-error";
import convertPenceToPounds from "src/lib/convert-pence-to-pounds";
import { pushGTMEvent } from "src/analytics/events/gtm-event.js";

export default function trackTransaction({
  currency,
  transactionId,
  transactionProducts,
  transactionTotal,
  hasPharma,
}) {
  try {
    const transactionTotalInPounds = convertPenceToPounds(transactionTotal);
    const skus = transactionProducts.map((product) => product.sku);
    const gtmEvent = hasPharma
      ? "pageviewSubscribePharmaThanks"
      : "pageviewSubscribeThanks";

    // google analytics
    if (typeof window.ga === "function") {
      window.ga("require", "ecommerce");
      window.ga("ecommerce:addTransaction", {
        id: transactionId,
        revenue: transactionTotalInPounds,
      });
      transactionProducts.map((product) =>
        window.ga("ecommerce:addItem", { ...product, id: transactionId })
      );
      window.ga("ecommerce:send");
    }

    pushGTMEvent("subscribePurchase", {
      transactionTotal: transactionTotalInPounds,
      transactionProducts,
      transactionProductsSkus: skus,
      currency,
      transactionId,
    });
    pushGTMEvent(gtmEvent);

    return null;
  } catch (error) {
    trackError({ errorMessage: "trackTransaction", error });
  }
}
