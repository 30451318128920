import get from "src/lib/get";
import { createSelector } from "reselect";
import formatCurrency from "src/lib/format-currency";
import penceToPounds from "src/lib/convert-pence-to-pounds";
import { productsBySku } from "src/data/product-data";
import productsProductsData from "src/data/products-products";
import {
  containsPharmaceuticalProduct,
  containsEDProduct,
  containsHairLossProduct,
  containsWeightLossProduct,
} from "src/data/helpers";
import { RAZOR_SKU, HANBLADE_SKU } from "src/data/product-skus";
import {
  INTERNATIONAL_POSTAGE_IN_PENCE,
  INTERNATIONAL_THRESHOLD_IN_PENCE,
  SIGNUP_ONE_OFF_THRESHOLD_IN_PENCE,
  SIGNUP_ONE_OFF_POSTAGE_IN_PENCE,
} from "src/data/app-constants";
import { getCouponMinimumSpend, getCoupon } from "src/redux/coupons/selectors";
import { getCouponProductsSkus } from "src/redux/coupons/helpers";
import {
  getTotalPrice,
  getCouponFormatedDiscountAmountString,
  getOneOffProductsPayload,
} from "./helpers";

export function getData(state) {
  return get(state, "signup", {});
}

export const getSignupActivationTimestamp = createSelector(
  getData,
  (data) => data.signupActivationTimestamp || 0
);

export const getSignupBasketType = createSelector(
  getData,
  (data) => data.basketType || "subscribe"
);

export const getIsSignupBasketTypeSubscribe = createSelector(
  getSignupBasketType,
  (basketType) => basketType === "subscribe"
);

export const signupGetBasket = createSelector(
  [getData, getIsSignupBasketTypeSubscribe],
  (data, isBasketTypeSubscribe) =>
    isBasketTypeSubscribe ? data.basket : data.basketOneOff
);

export const getSignupSubscribeBasket = createSelector(
  getData,
  (data) => data.basket
);

export const makeGetIsSignupProductSelected = (sku) =>
  createSelector(signupGetBasket, (products) => !!products[sku]);

export const getSignupHasHandle = createSelector(
  signupGetBasket,
  (selectedProducts = {}) => {
    return !!selectedProducts[HANBLADE_SKU];
  }
);

export const getSignupHasBlades = createSelector(
  signupGetBasket,
  (selectedProducts = {}) => {
    return !!selectedProducts[RAZOR_SKU];
  }
);

export const getSignupSelectedProductsSkus = createSelector(
  signupGetBasket,
  (selectedProducts = {}) => {
    return Object.keys(selectedProducts);
  }
);

export const getSignupSelectedProductsWithData = createSelector(
  [signupGetBasket, getIsSignupBasketTypeSubscribe],
  (selectedProducts = {}, isBasketTypeSubscribe) => {
    const selectedProductsWithData = [];
    productsProductsData.forEach((product) => {
      const selectedProduct = selectedProducts[product.id];
      if (selectedProduct) {
        selectedProductsWithData.push({
          ...product,
          ...(isBasketTypeSubscribe
            ? {
                frequency: selectedProduct.frequency,
                isFree: product.id === HANBLADE_SKU,
              }
            : {
                quantity: selectedProduct.quantity,
                options: selectedProduct.options,
              }),
        });
      }
    });
    return selectedProductsWithData;
  }
);

export const getEngraving = createSelector(getData, (data = {}) => {
  return data.engraving;
});

export const getEngravingString = createSelector(
  getEngraving,
  (engravingArray = []) => {
    return engravingArray.join("");
  }
);

export const getBasketQuantity = createSelector(
  signupGetBasket,
  (selectedProducts = {}) => Object.keys(selectedProducts).length
);

export const getHasSubmittedSignupCoupon = createSelector(getData, (data) => {
  return !!data.hasSubmittedSubscribeCoupon;
});

export const getHasRazorAndHandleInBasket = createSelector(
  getSignupSelectedProductsSkus,
  (skus) => skus.indexOf(RAZOR_SKU) > -1 && skus.indexOf(HANBLADE_SKU) > -1
);

export const getSignupOrderValue = createSelector(
  [
    signupGetBasket,
    getHasRazorAndHandleInBasket,
    getIsSignupBasketTypeSubscribe,
  ],
  (products, hasRazorAndHandleInBasket, isBasketTypeSubscribe) => {
    const totalPrice = getTotalPrice(products);
    return hasRazorAndHandleInBasket && isBasketTypeSubscribe
      ? totalPrice - productsBySku[HANBLADE_SKU].priceInPence
      : totalPrice;
  }
);

export const getSignupErrors = createSelector(
  [getSignupOrderValue, getSignupSelectedProductsSkus],
  (orderValue, productSKUS) => {
    const errors = [];

    if (productSKUS.length < 1) {
      errors.push("Sorry, your plan needs to include at least one product.");
    }

    if (orderValue < 1) {
      errors.push("The total price of your first order must be at least £1.");
    }

    return errors;
  }
);

export const getCouponMinimumSpendTooLow = createSelector(
  [getCouponMinimumSpend, getSignupOrderValue],
  (couponMinimumSpend, orderValue) => orderValue < couponMinimumSpend
);

export const makeWillReceiveSignupCouponDiscount = (productPriceInPence = 0) =>
  createSelector(
    [getCouponMinimumSpend, getSignupOrderValue],
    (couponMinimumSpend = 0, orderValue = 0) => {
      return orderValue - productPriceInPence >= couponMinimumSpend;
    }
  );

export const getSignupDiscount = createSelector(
  [
    getSignupOrderValue,
    getCoupon,
    getCouponMinimumSpendTooLow,
    getSignupSelectedProductsSkus,
    getIsSignupBasketTypeSubscribe,
  ],
  (
    orderValue = 0,
    coupon = {},
    couponMinimumSpendTooLow,
    selectedProductsSkus = [],
    isBasketTypeSubscribe
  ) => {
    const {
      discount_amount_type: type,
      discount_amount: discountAmount,
    } = coupon;

    if (couponMinimumSpendTooLow || !isBasketTypeSubscribe) {
      return 0;
    }

    if (
      type === "percentage_products_or" ||
      type === "percentage_products_and"
    ) {
      const couponProductsSkus = getCouponProductsSkus(coupon) || [];
      const couponProductsInBasket = couponProductsSkus.filter((sku) => {
        return selectedProductsSkus.indexOf(sku) > -1;
      });
      const hasDiscountedProducts =
        type === "percentage_products_and"
          ? couponProductsInBasket.length === couponProductsSkus.length
          : !!couponProductsInBasket.length;

      if (hasDiscountedProducts) {
        const productsAmount = couponProductsInBasket.reduce(
          (totalAmount, product) => {
            const productData = productsBySku[product] || {};
            return productData.priceInPence
              ? productData.priceInPence + totalAmount
              : totalAmount;
          },
          0
        );
        return productsAmount * (discountAmount / 100) || 0;
      }
      return 0;
    }

    if (type === "absolute") {
      return parseInt(discountAmount, 10);
    }

    if (type === "percentage") {
      return parseInt(orderValue * (discountAmount / 100), 10);
    }

    return 0;
  }
);

export const getSignupProductTotalAfterDiscounts = createSelector(
  [getSignupOrderValue, getSignupDiscount],
  (orderValue, discount) => {
    return discount > orderValue ? 0 : orderValue - discount;
  }
);

export const getSignupDiscountInPounds = createSelector(
  getSignupDiscount,
  (discount) => {
    return penceToPounds(discount);
  }
);

export const getSignupCountry = createSelector(getData, (data) =>
  get(data, "country", "")
);

export const getHasInternationalAddress = createSelector(
  getSignupCountry,
  (country = "") => country !== "" && country.toLowerCase() !== "united kingdom"
);

export const getUkOnlyProductsInBasket = createSelector(
  getSignupSelectedProductsWithData,
  (products) =>
    products
      .filter((product) => product.ukOnly)
      .map((product) => product.name.replace(",", " -"))
);

export const getInternationalPostage = createSelector(
  [
    getHasInternationalAddress,
    getSignupProductTotalAfterDiscounts,
    getIsSignupBasketTypeSubscribe,
  ],
  (
    isAddressInternational,
    totalAfterDiscounts,
    isSignupBasketTypeSubscribe
  ) => {
    const hasMetPostageThreshold = isSignupBasketTypeSubscribe
      ? totalAfterDiscounts >= INTERNATIONAL_THRESHOLD_IN_PENCE
      : totalAfterDiscounts > SIGNUP_ONE_OFF_THRESHOLD_IN_PENCE;

    if (
      !isAddressInternational ||
      (isAddressInternational && hasMetPostageThreshold)
    ) {
      return 0;
    }

    return INTERNATIONAL_POSTAGE_IN_PENCE;
  }
);

export const getOneOffPostage = createSelector(
  [getSignupProductTotalAfterDiscounts, getIsSignupBasketTypeSubscribe],
  (totalAfterDiscounts, isSignupBasketTypeSubscribe) => {
    return !isSignupBasketTypeSubscribe &&
      totalAfterDiscounts <= SIGNUP_ONE_OFF_THRESHOLD_IN_PENCE
      ? SIGNUP_ONE_OFF_POSTAGE_IN_PENCE
      : 0;
  }
);

export const getSignupTotal = createSelector(
  [
    getHasInternationalAddress,
    getSignupProductTotalAfterDiscounts,
    getOneOffPostage,
    getInternationalPostage,
  ],
  (
    isAddressInternational,
    productTotalAfterDiscounts = 0,
    oneOffPostage = 0,
    internationalPostage = 0
  ) => {
    const postage = isAddressInternational
      ? internationalPostage
      : oneOffPostage;
    return productTotalAfterDiscounts + postage;
  }
);

// TODO: Add unit tests
export const getCouponAppliedMessage = createSelector(
  [getCoupon, getCouponMinimumSpendTooLow, getSignupDiscount],
  (coupon, couponMinimumSpendTooLow, discount) => {
    const formatedCouponAmount = getCouponFormatedDiscountAmountString(coupon);
    const minimumSpend = formatCurrency({ value: coupon.minimum_spend });
    const formatedDiscount = formatCurrency({ value: discount });

    if (couponMinimumSpendTooLow) {
      return `Spend ${minimumSpend} or more on your first order to get ${formatedCouponAmount} off`;
    } else {
      return `-${formatedDiscount}`;
    }
  }
);

// TODO: Add unit tests
export const getHasEDProductInSignupBasket = createSelector(
  getSignupSelectedProductsSkus,
  (skus) => {
    return containsEDProduct(skus);
  }
);

// TODO: Add unit tests
export const getHasHairLossProductInSignupBasket = createSelector(
  getSignupSelectedProductsSkus,
  (skus) => {
    return containsHairLossProduct(skus);
  }
);

// TODO: Add unit tests
export const getHasWeightLossProductInSignupBasket = createSelector(
  getSignupSelectedProductsSkus,
  (skus) => {
    return containsWeightLossProduct(skus);
  }
);

// TODO: Add unit tests
export const getHasPharmaProductInSignupBasket = createSelector(
  getSignupSelectedProductsSkus,
  (skus) => {
    return containsPharmaceuticalProduct(skus);
  }
);

// TODO: Add unit tests
export const getHasOneOffRestrictedPharmaProductInSubscribeBasket = createSelector(
  getSignupSubscribeBasket,
  (subscribeBasket) => {
    const skus = Object.keys(subscribeBasket);
    return containsHairLossProduct(skus) || containsWeightLossProduct(skus);
  }
);

export const getSignupSelectedProductsCheckoutPayload = createSelector(
  [getData, getIsSignupBasketTypeSubscribe],
  (data, isSignupBasketTypeSubscribe) => {
    const basket = data.basket || {};
    const products = [];
    if (isSignupBasketTypeSubscribe) {
      Object.keys(basket).forEach((sku) => {
        const product = basket[sku];
        if (sku !== HANBLADE_SKU) {
          products.push({ sku, frequency: product.frequency });
        }
      });
    }
    const oneOffProducts = !isSignupBasketTypeSubscribe
      ? getOneOffProductsPayload(data.basketOneOff)
      : [];
    return { products, oneOffProducts };
  }
);

export const getSignupTransactionProducts = createSelector(
  [getSignupSelectedProductsWithData, getIsSignupBasketTypeSubscribe],
  (products = [], isSignupBasketTypeSubscribe) =>
    products
      .filter(
        (product) =>
          !(isSignupBasketTypeSubscribe && product.id === HANBLADE_SKU)
      )
      .map((product) => ({
        sku: product.id,
        name: product.name,
        category: "Subscription Item",
        price: penceToPounds(product.priceInPence),
        quantity: parseInt(product.quantity) || 1,
      }))
);
