import { SET_STATE, RESET_STATE } from "src/redux/app/actions";
import { ORLI84X120_SKU } from "src/data/product-skus";
import { actionTypes as userActionTypes } from "src/redux/user/actions";
import { parseUserActiveCoupons } from "../helpers";
import { actionTypes } from "./actions";

export const DEFAULT_FREQUENCY = 1;

export const defaultState = {
  version: 2,
  isPending: false,
  error: null,
  answers: {
    condition: {},
    terms: {},
  },
  productOptions: {
    strength: ORLI84X120_SKU,
    frequency: DEFAULT_FREQUENCY,
  },
  coupons: {
    data: [],
    error: null,
  },
};

export default function weightLossConsultation(
  state = defaultState,
  action = {}
) {
  switch (action.type) {
    case SET_STATE:
      return state.version === defaultState.version ? state : defaultState;

    case RESET_STATE:
      return defaultState;

    case actionTypes.WEIGHTLOSS_CONSULTATION_SUBMIT_PENDING:
      return {
        ...state,
        isPending: true,
      };

    case actionTypes.WEIGHTLOSS_CONSULTATION_SUBMIT_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case actionTypes.WEIGHTLOSS_CONSULTATION_SUBMIT_SUCCESS:
      return defaultState;

    case actionTypes.WEIGHTLOSS_CONSULTATION_SET_PRODUCT_OPTIONS:
      return {
        ...state,
        productOptions: {
          ...state.productOptions,
          ...action.payload,
        },
      };

    case actionTypes.WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_SUCCESS: {
      const coupons = [].concat(action.payload);
      return {
        ...state,
        coupons: {
          ...state.coupons,
          data: [...state.coupons.data, ...coupons],
        },
      };
    }

    case userActionTypes.USER_FETCH_ACTIVE_COUPONS_SUCCESS: {
      const userActiveCoupons = [].concat(
        parseUserActiveCoupons(action.payload)
      );
      return {
        ...state,
        coupons: {
          ...state.coupons,
          data: [...userActiveCoupons],
        },
      };
    }

    case actionTypes.WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_ERROR:
    case userActionTypes.USER_FETCH_ACTIVE_COUPONS_ERROR:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          data: [],
          error: action.payload,
        },
      };

    case actionTypes.WEIGHTLOSS_CONSULTATION_SAVE:
      return {
        ...state,
        answers: {
          ...state.answers,
          ...action.payload.answers,
        },
      };

    default:
      return state;
  }
}
