import { SET_STATE, RESET_STATE } from "src/redux/app/actions";
import {
  getCouponActionTypes,
  deleteSubscriptionCouponActionTypes,
} from "./actions";

const defaultState = {
  version: 3,
  isPending: false,
  data: {},
  error: null,
};

export default function coupons(state = defaultState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_STATE:
      return state.version === defaultState.version ? state : defaultState;

    case RESET_STATE:
      return defaultState;

    case getCouponActionTypes.SIGNUP_COUPONS__GET_PENDING:
      return {
        ...state,
        isPending: true,
        error: null,
      };

    case getCouponActionTypes.SIGNUP_COUPONS__GET_SUCCESS:
      return {
        ...state,
        isPending: false,
        data: payload,
      };

    case getCouponActionTypes.SIGNUP_COUPONS__GET_ERROR:
      return {
        ...state,
        isPending: false,
        error: payload,
      };

    case deleteSubscriptionCouponActionTypes.SUBSCRIPTION__DELETE_SUCCESS:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
}
