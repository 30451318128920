function CustomError({
  name = "CustomError",
  message = "",
  payload = {},
} = {}) {
  const instance = new Error(message);
  instance.name = name;
  instance.payload = payload;

  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));

  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, CustomError);
  }
  return instance;
}

CustomError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true,
  },
});

if (Object.setPrototypeOf) {
  Object.setPrototypeOf(CustomError, Error);
} else {
  CustomError.__proto__ = Error; // eslint-disable-line no-proto
}

export default CustomError;
