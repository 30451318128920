import { useState, useLayoutEffect } from "react";

export default function useBodyScrollLock() {
  const [isBodyScrollLocked, setIsBodyScrollLocked] = useState(false);

  useLayoutEffect(() => {
    document.body.style.overflow = isBodyScrollLocked ? "hidden" : "";
  }, [isBodyScrollLocked]);

  return { isBodyScrollLocked, setIsBodyScrollLocked };
}
