import {
  FULLY_INITIALIZED,
  REQUIRES_PAYMENT_AUTHORISATION,
  RESET_PAYMENT_AUTHORISATION,
} from "./actions";
import { actionTypes as signupActionTypes } from "src/redux/signup/actions/checkout";

export const defaultState = {
  loading: true,
  isReady: false,
  paymentAuthorisationData: null,
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case FULLY_INITIALIZED:
      return {
        loading: false,
        isReady: true,
      };

    case REQUIRES_PAYMENT_AUTHORISATION:
      return {
        ...state,
        paymentAuthorisationData: action.payload,
      };

    case signupActionTypes.SIGNUP__COMPLETION_SUCCESS:
    case signupActionTypes.SIGNUP__COMPLETION_ERROR:
    case RESET_PAYMENT_AUTHORISATION:
      return {
        ...state,
        paymentAuthorisationData: null,
      };

    default:
      return state;
  }
}
