import { createGlobalStyle } from "styled-components";
import { color, font } from "./variables";

const GlobalStyles = createGlobalStyle`
  *, *:after, *:before {
    box-sizing: border-box;
  }

  :root {
    color: ${color.grey};
    line-height: 1.5;
    font-family: Helvetica, Arial;
    font-size: 105%;
    font-weight: 300;
    letter-spacing: 0.5px;
    overflow-scrolling: touch;
  }
  
  html.wf-active {
    font-size: 16px;
    letter-spacing: 0.1px;
    font-family: ${font.primary};
  }

  html, body, div, article, aside, footer, header, main, nav, section, form, h1,
  h2, h3, h4, h5, h6, p, a, span, em, img, strong, ol, ul, li, label {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
    transition-property: color;
    transition-duration: 0.1s;
    :hover {
      color: inherit;
    }
  }

  img {
    max-width: 100%;
  }

  ul {
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1.3;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  #app {
    overflow: hidden;
    background: ${color.white};
  }

  #cto_cookies_header_message.criteo_header {
    display: none !important;
  }
`;

export default GlobalStyles;
