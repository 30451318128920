// Based off https://github.com/jakearchibald/idb-keyval
// We need to be able to specify a db version, which isn't possible with the libray
// so instead of forking it, I've decided to create a custom stripped out version
// just for our needs.

export default class IdbStore {
  constructor(dbName = "keyval-store", storeName = "keyval", version) {
    this.storeName = storeName;
    this.dbName = dbName;
    this.version = version;
    this.status = "pending";
    this._dbp = this._openDB();
  }

  _openDB() {
    return new Promise((resolve, reject) => {
      const openreq = indexedDB.open(this.dbName, this.version);
      openreq.onerror = () => {
        this.status = "rejected";
        return reject(openreq.error);
      };
      openreq.onsuccess = () => {
        this.status = "resolved";
        this._openRequestResult = openreq.result;
        resolve(openreq.result);
      };

      // First time setup: create an empty object store
      openreq.onupgradeneeded = (event) => {
        openreq.result.createObjectStore(this.storeName);
      };
    });
  }

  _withIDBStore(type, callback) {
    return this._dbp.then((db) => {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(this.storeName, type);
        transaction.oncomplete = () => {
          resolve();
        };
        transaction.onabort = transaction.onerror = () => {
          reject(transaction.error);
        };
        callback(transaction.objectStore(this.storeName), db);
      });
    });
  }

  get(key) {
    let req;
    return this._withIDBStore("readonly", (store) => {
      req = store.get(key);
    })
      .then(() => req.result)
      .catch((error) => {
        console.warn(error.message);
      });
  }

  set(key, value) {
    return this._withIDBStore("readwrite", (store) => {
      store.put(value, key);
    })
      .then(() => {})
      .catch((error) => {
        console.warn(error.message);
        // Safari often closes the connection when idle.
        // This triggers an error after trying to set data,
        // so this makes sure the connection is open again
        // and resumes set data
        this._openRequestResult.close();
        this._dbp = this._openDB();
        return this._withIDBStore("readwrite", (store) => {
          store.put(value, key);
        });
      });
  }
}
