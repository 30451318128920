import { lazy } from "react";

function getHasReloadedFailedChunk() {
  try {
    return sessionStorage.getItem("hasReloadedFailedChunk");
  } catch (error) {
    return true;
  }
}

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft < 1) {
            const hasReloadedFailedChunk = getHasReloadedFailedChunk();
            if (hasReloadedFailedChunk) {
              return reject(error);
            } else {
              sessionStorage.setItem("hasReloadedFailedChunk", true);
              window.location.reload();
            }
          } else {
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }
        }, interval);
      });
  });
}

function loadable({ loader }) {
  return lazy(() => retry(loader));
}

export default loadable;
