import { getSessionGuid } from "src/lib/tracking-cookies";
import api, { handleError } from "../index";

export const postConsultationService = ({ apiToken, payload }) => {
  return api({
    method: "post",
    url: "/consultation",
    data: { ...payload, session_guid: getSessionGuid() },
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
  })
    .then((response = {}) => {
      return { error: null, data: response.data };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
};
