import { createSelector } from "reselect";
import get from "src/lib/get";
import { getData } from ".";

export const getCardDetails = createSelector(getData, (data) => {
  const cardDetails = get(data, "card_details.data", {});
  return {
    billingPostcode: cardDetails.billing_postcode,
    cardLast4: cardDetails.card_last4,
    cardType: cardDetails.card_type,
    cardExpiryMonth: cardDetails.card_expiry_month,
    cardExpiryYear: cardDetails.card_expiry_year,
    cardExpired: cardDetails.card_expired,
    cardDeclined: cardDetails.card_declined,
  };
});
