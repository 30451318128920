import React, { Component } from "react";
import PropTypes from "prop-types";
import trackError from "src/lib/track-error";
import Page from "src/react/layout/page";
import Oops from "src/react/pages/oops";
import { DefaultLayout } from "src/react/layout";

class ErrorBoundaryHandler extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  state = {
    hasError: false,
  };

  componentDidCatch(error) {
    trackError({ errorMessage: "Route error", error });
    this.setState(() => ({ hasError: true }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState(() => ({ hasError: false }));
    }
  }

  render() {
    if (this.state.hasError)
      return (
        <Page>
          <DefaultLayout>
            <Oops />
          </DefaultLayout>
        </Page>
      );

    return this.props.children;
  }
}

export default ErrorBoundaryHandler;
