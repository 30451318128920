import nanoid from "nanoid";
import { getSessionGuid } from "src/lib/tracking-cookies";
import { storePageview } from "src/services/event/pageviews/store";
import { pushGTMEvent } from "src/analytics/events/gtm-event.js";

const prod = process.env.REACT_APP_RELEASE_STAGE === "production";
const dev = process.env.REACT_APP_RELEASE_STAGE === "development";

export default function trackPageview(options = {}) {
  const pageviewGuid = nanoid();
  const pageviewData = {
    session_guid: getSessionGuid(),
    pageview_guid: pageviewGuid,
    ...options,
  };

  try {
    window.sessionStorage.setItem("pageviewGuid", pageviewGuid);
  } catch (error) {
    console.warn("sessionStorage.setItem not available - ", error);
  }

  if (prod) {
    storePageview(pageviewData);
    pushGTMEvent("pageview");
  } else if (dev) {
    console.warn("page view", pageviewData);
  }

  if (typeof window.ga === "function") {
    window.ga("set", "page", `${options.path || ""}${options.query || ""}`);
    window.ga("send", "pageview");
  }
}
