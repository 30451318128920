import trackError from "src/lib/track-error";

export default function handleError(error) {
  const { response = {}, message, stack, errors, name } = error;
  const errorPayload = {
    error:
      response.data ||
      response.statusText ||
      errors ||
      new Error("Request error"),
    errorMessage: message || response.statusText,
    stack,
    name,
    status: response.status,
  };
  trackError(errorPayload);
  return errorPayload;
}
