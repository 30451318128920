import styled, { css } from "styled-components";

const Section = styled.div`
  padding: ${(props) => (props.padding ? props.padding : "20px 0")};
  margin: ${(props) => (props.margin ? props.margin : "0 auto")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "inherit")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.noBorder && sectionNoBorderStyles};
  ${({ css }) => css && customStyles(css)};
  @media (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const customStyles = (styles) => css`
  ${styles};
`;
const sectionNoBorderStyles = css`
  border: 0;
`;

export default Section;
