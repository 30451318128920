export const productCategories = {
  shaving: {
    id: "shaving",
    name: "Shaving",
    description:
      "Our iconic chrome-plated razor and shaving supplies - everything you need for a smooth, comfortable shave.",
  },
  body: {
    id: "body",
    name: "Body",
    description:
      "Start each morning fresh, vibrant and full of energy - ready for whatever the day throws at you.",
  },
  mouth: {
    id: "mouth",
    name: "Mouth",
    description:
      "Excellent dental care, fresh breath and white teeth - plenty to smile about.",
  },
  travel: {
    id: "travel",
    name: "Travel / Extras",
    description: "",
  },
  gifts: {
    id: "gifts",
    name: "Gifts",
    description: "The perfect gift for someone special",
  },
  sex: {
    id: "sex",
    name: "Sex",
    description:
      "Effective sexual healthcare. Get ready for great sex.",
  },
  hair: {
    id: "hair",
    name: "Hair",
    description:
      "Keep your hair strong, thick and full of life. It's not magic, it's science.",
  },
  health: {
    id: "health",
    name: "Health",
    description: "",
  },
};

export const subscribeProductsCategories = [
  productCategories.shaving.id,
  productCategories.sex.id,
  productCategories.hair.id,
  productCategories.body.id,
  productCategories.mouth.id,
];

export const accountSubscriptionProductCategories = [
  productCategories.body.id,
  productCategories.sex.id,
  productCategories.hair.id,
  productCategories.shaving.id,
  productCategories.mouth.id,
];

export const accountTopupProductCategories = [
  productCategories.gifts.id,
  productCategories.shaving.id,
  productCategories.travel.id,
  productCategories.body.id,
  productCategories.sex.id,
  productCategories.hair.id,
  productCategories.mouth.id,
];
