import React, { useState, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { matchPath, useLocation } from "react-router-dom";
import useBodyScrollLock from "src/react/hooks/useBodyScrollLock";
import { color } from "src/styles/variables";
import { Nav } from "./components";
import MainNavBar from "./navbar-main";
import AccountNavBar from "./navbar-account";

function NavBar({ hideNav }) {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { setIsBodyScrollLocked } = useBodyScrollLock();
  const location = useLocation();

  const shouldHideMainNav =
    hideNav ||
    matchPath(location.pathname, {
      path: ["/signup/account-creation", "/signup/payment-and-delivery"],
    });

  const isAccount = matchPath(location.pathname, { path: "/account" });

  useLayoutEffect(() => {
    setShowMobileNav(false);
  }, [location.pathname, setShowMobileNav]);

  useEffect(() => {
    setIsBodyScrollLocked(showMobileNav);
  }, [setIsBodyScrollLocked, showMobileNav]);

  if (shouldHideMainNav) return <HeaderBorder />;

  return (
    <Nav
      isOpened={showMobileNav}
      onClick={() => {
        if (showMobileNav) {
          setShowMobileNav(!showMobileNav);
        }
      }}
    >
      {isAccount ? (
        <AccountNavBar
          location={location}
          showMobileNav={showMobileNav}
          toggleMobileNav={setShowMobileNav}
        />
      ) : (
        <MainNavBar
          location={location}
          showMobileNav={showMobileNav}
          toggleMobileNav={setShowMobileNav}
        />
      )}
    </Nav>
  );
}

NavBar.propTypes = {
  hideNav: PropTypes.bool,
};

export default NavBar;

const HeaderBorder = styled.div`
  border-bottom: 40px solid ${color.red};
  @media (min-width: 960px) {
    border-bottom: 49px solid ${color.red};
  }
`;
