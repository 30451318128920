import nanoid from "nanoid";
import trackError from "src/lib/track-error";
import api from "../index";

const handleError = (error, state) => {
  const { response = {}, message, stack, errors, name } = error;
  const errorPayload = {
    state,
    error:
      response.data ||
      response.statusText ||
      errors ||
      new Error("Request error"),
    errorMessage: message || response.statusText,
    stack,
    name,
  };
  trackError(errorPayload);
};

export const storeEvent = async (payload) => {
  try {
    const response = await api({
      method: "post",
      url: "/event",
      data: {
        pageview_guid: payload.pageview_guid || nanoid(),
        event_type: payload.event_type,
        event_target: payload.event_target,
        event_data: payload.event_data || {},
      },
    });
    return response.data;
  } catch (error) {
    handleError(error, payload);
  }
};
