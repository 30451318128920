import axios from "axios";
import handleError from "../handle-error";

export { handleError };

export const api = axios.create({
  baseURL: process.env.REACT_APP_EVENT_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  validateStatus: (status) => status >= 200 && status < 500,
});

export default api;
