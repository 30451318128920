import React from "react";
import styled from "styled-components";
import { A } from "src/react/components/link";
import { HELP_EMAIL } from "src/data/app-constants";

export const HelpEmail = ({ children, emailBody, ...props }) => {
  const href = `mailto:${HELP_EMAIL}${
    emailBody ? `?body=${encodeURIComponent(emailBody)}` : ""
  }`;
  return (
    <CustomLink href={href} {...props}>
      {children || HELP_EMAIL}
    </CustomLink>
  );
};

const CustomLink = styled(A)`
  hyphens: none;
`;
