import get from "src/lib/get";

export function isLoading(state) {
  return get(state, "app.loading", true);
}

export function isAppReady(state) {
  return get(state, "app.isReady", false);
}

export function getPaymentAuthorisationData(state) {
  return get(state, "app.paymentAuthorisationData", null);
}
