const currencySymbolMap = {
  GBP: "£",
  EURO: "€",
};

const DEFAULT_CURRENCY = "GBP";

export default function toCurrency(
  { value = 0, currency = DEFAULT_CURRENCY } = {
    value: 0,
    currency: DEFAULT_CURRENCY,
  }
) {
  const currencySymbol =
    currencySymbolMap[currency] || currencySymbolMap[DEFAULT_CURRENCY];
  return `${currencySymbol}${value}`;
}
