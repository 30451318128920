import { createSelector } from "reselect";
import get from "src/lib/get";
import isEmpty from "src/lib/isEmpty";
import { SILD8X50 } from "src/data/product-data";
import { containsEDProduct } from "src/data/helpers";
import { getCoupon as getSubscribeCoupon } from "src/redux/coupons/selectors";
import {
  getEdStatus,
  getIdentityCheckPassed,
} from "src/redux/account/subscription/selectors";
import { getConsultationIdCheck } from "src/redux/consultations/id-check/selectors";
import { parseConsultationAnswers, couponDiscountPercentage } from "../helpers";
import { DEFAULT_FREQUENCY } from "./reducer";

export function getConsultation(state = {}) {
  return state.edConsultation || {};
}

export const getConsultationAnswers = createSelector(
  getConsultation,
  (consultation) => consultation.answers || {}
);

export const getCanSubmitEDMainConsultation = createSelector(
  getConsultationAnswers,
  (consultationAnswers) => {
    const answers = { ...consultationAnswers };
    delete answers.terms;
    return (
      !isEmpty(answers) &&
      Object.keys(answers).every((key) => !isEmpty(answers[key]))
    );
  }
);

export const getConsultationSubscribeEDCouponDiscountPercentage = createSelector(
  getSubscribeCoupon,
  (subscribeCoupon = {}) => {
    if (
      containsEDProduct(get(subscribeCoupon, "products.data", [])) ||
      subscribeCoupon.discount_amount_type === "percentage"
    ) {
      return couponDiscountPercentage({
        couponData: subscribeCoupon,
        applicableDiscountTypes: ["percentage_products_or", "percentage"],
      });
    }
    return 0;
  }
);

export const getConsultationEDCoupons = createSelector(
  getConsultation,
  (consultation) => {
    const consultationCoupons = get(consultation, "coupons.data", []);
    return consultationCoupons.filter((coupon) =>
      containsEDProduct(get(coupon, "products.data", []))
    );
  }
);

export const getConsultationEDCouponsError = createSelector(
  getConsultation,
  (consultation) => get(consultation, "coupons.error", null)
);

export const getConsultationCanHaveCouponDiscount = createSelector(
  [getEdStatus, getConsultationEDCoupons, getConsultationEDCouponsError],
  (edStatus, coupons = [], couponsError) => {
    return (
      (edStatus === "not_started" || edStatus === "failed") &&
      !coupons.length &&
      !couponsError
    );
  }
);

export const getConsultationCouponDiscountPercentage = createSelector(
  getConsultationEDCoupons,
  (coupons = []) => {
    return couponDiscountPercentage({
      couponData: coupons[0],
      applicableDiscountTypes: ["percentage_products_or"],
    });
  }
);

export const getConsultationDiscountedPrice = createSelector(
  getConsultationCouponDiscountPercentage,
  (discountPercentage = 0) => {
    return SILD8X50.priceInPence * discountPercentage;
  }
);

export const getConsultationProductOptions = createSelector(
  getConsultation,
  (consultation) => consultation.productOptions || {}
);

export const getCanDoConsultation = createSelector(getEdStatus, (edStatus) => {
  return edStatus !== "pending" && edStatus !== "approved";
});

export const getFormattedNewConsultation = createSelector(
  [
    getConsultationAnswers,
    getConsultationIdCheck,
    getConsultationProductOptions,
    getIdentityCheckPassed,
  ],
  (answers = {}, idCheck = {}, productOptions = {}, identityCheckPassed) => {
    const { condition = {}, medicalHistory = {} } = answers;
    const { strength, frequency = DEFAULT_FREQUENCY } = productOptions;
    const gpData = medicalHistory.gpName
      ? {
          name: medicalHistory.gpName,
          address: {
            address1: medicalHistory.gpAddress1,
            address2: medicalHistory.gpAddress2,
            city: medicalHistory.gpCity,
            county: medicalHistory.gpCounty,
            postcode: medicalHistory.gpPostcode,
          },
        }
      : {};
    const patient = identityCheckPassed
      ? {}
      : {
          firstname: idCheck.firstName,
          middlename: "",
          lastname: idCheck.lastName,
          phone: idCheck.phone,
          dob: idCheck.dob,
          address: {
            address1: idCheck.address1,
            address2: idCheck.address2,
            city: idCheck.city,
            county: idCheck.county,
            postcode: idCheck.postcode,
          },
        };

    return {
      type: "new",
      applicable_skus: [strength],
      selected_skus: [{ product_id: strength, frequency }],
      consultation: [
        ...parseConsultationAnswers(condition),
        ...parseConsultationAnswers(medicalHistory),
      ],
      patient,
      gp: gpData,
    };
  }
);

export const getFormattedFollowUpConsultation = createSelector(
  [getConsultationAnswers, getConsultationProductOptions],
  (answers = {}, productOptions = {}) => {
    const { condition = {}, terms = {} } = answers;
    const { strength } = productOptions;
    const gpData = terms.gpName
      ? {
          name: terms.gpName,
          address: {
            address1: terms.gpAddress1,
            address2: terms.gpAddress2,
            city: terms.gpCity,
            county: terms.gpCounty,
            postcode: terms.gpPostcode,
          },
        }
      : {};

    return {
      type: "repeat",
      applicable_skus: [strength],
      consultation: [
        ...parseConsultationAnswers(condition),
        ...parseConsultationAnswers(terms),
      ],
      gp: gpData,
    };
  }
);
