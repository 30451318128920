import React from "react";
import PropTypes from "prop-types";
import trackClickEvent from "src/analytics/events/track-click-event";

function isFunction(func) {
  return typeof func === "function";
}

export class Tracking extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    tracking: PropTypes.string,
    trackingOptions: PropTypes.object,
    trackOnFocus: PropTypes.bool,
  };

  clickHandler = (props = {}) => (clickParams) => {
    const { onClick, tracking, trackingOptions, buttonLabel } = props;
    if (tracking) {
      trackClickEvent({
        buttonLabel,
        ...trackingOptions,
      });
    }
    if (isFunction(onClick) && !this.props.trackOnFocus) {
      onClick(clickParams);
    }
  };

  render() {
    const { tracking, trackingOptions = {}, trackOnFocus } = this.props;
    const { onClick, ...childrenProps } = this.props.children.props;
    const element = { ...this.props.children };
    delete element.props;
    const clickHandler = this.clickHandler({
      onClick,
      tracking,
      trackingOptions,
      buttonLabel: trackingOptions.buttonLabel || childrenProps.children,
    });
    return React.cloneElement(element, {
      ...childrenProps,
      onClick: trackOnFocus ? onClick : clickHandler,
      onFocus: trackOnFocus && clickHandler,
    });
  }
}

export default Tracking;
