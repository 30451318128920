import smartlookClient from "smartlook-client";
import { getSessionGuid } from "src/lib/tracking-cookies";
import CustomError from "src/lib/custom-error";
import {
  meService,
  saveUserService,
} from "src/services/cornerstone/users/me/me";
import { registerUserService } from "src/services/cornerstone/users/register";
import { activeUserCouponsService } from "src/services/cornerstone/users/active-user-coupons";
import { logoutService } from "src/services/cornerstone/users/logout";
import { userCheckEmailService } from "src/services/cornerstone/users/check-email";
import { resetState } from "src/redux/app/actions";
import { getUserToken } from "src/redux/user/selectors";

const prod =
  process.env.REACT_APP_RELEASE_STAGE === "production" &&
  process.env.NODE_ENV === "production";

const defaultMeIncludes = [
  "card_details",
  "delivery_details",
  "coupons",
  "credits",
  "referral_balance",
  "current_parcel",
];

export const actionTypes = {
  USER_FETCH_ACTIVE_COUPONS_PENDING: "USER_FETCH_ACTIVE_COUPONS_PENDING",
  USER_FETCH_ACTIVE_COUPONS_SUCCESS: "USER_FETCH_ACTIVE_COUPONS_SUCCESS",
  USER_FETCH_ACTIVE_COUPONS_ERROR: "USER_FETCH_ACTIVE_COUPONS_ERROR",
  USER_AUTH_CHECK_PENDING: "USER_AUTH_CHECK_PENDING",
  USER_AUTH_CHECK_SUCCESS: "USER_AUTH_CHECK_SUCCESS",
  USER_AUTH_CHECK_ERROR: "USER_AUTH_CHECK_ERROR",
  USER_AUTH_CHECK_SKIPPED: "USER_AUTH_CHECK_SKIPPED",
  USER_UPDATE: "USER_UPDATE",
};

function identifyErrorTrackingUser(userId) {
  if (window.trackJs && userId) {
    window.trackJs.configure({
      userId: `${userId}`,
    });
  }
}

export const logoutAction = () => async (dispatch, getState) => {
  const apiToken = getUserToken(getState());

  dispatch({
    type: "USER_LOGOUT",
  });

  dispatch(resetState());

  if (apiToken) {
    return logoutService(apiToken);
  }
};

export const fetchActiveUserCoupons = () => (dispatch, getState) => {
  const apiToken = getUserToken(getState());

  dispatch({
    type: actionTypes.USER_FETCH_ACTIVE_COUPONS_PENDING,
  });

  activeUserCouponsService(apiToken).then((response) => {
    if (response.error) {
      dispatch({
        type: actionTypes.USER_FETCH_ACTIVE_COUPONS_ERROR,
        payload: response.error,
      });
    } else {
      dispatch({
        type: actionTypes.USER_FETCH_ACTIVE_COUPONS_SUCCESS,
        payload: response.data,
      });
    }
  });
};

export const registerUser = (payload) => (dispatch) => {
  dispatch({
    type: actions.USER_REGISTER_PENDING,
  });

  return registerUserService(payload).then((response) => {
    if (response.error) {
      dispatch({
        type: actions.USER_REGISTER_ERROR,
        payload: response.error,
      });
    } else {
      if (prod) {
        const userId = response.data.id;
        smartlookClient.identify(userId, {
          name: userId,
        });
        identifyErrorTrackingUser(userId);
      }
      dispatch({
        type: actions.USER_REGISTER_SUCCESS,
        payload: response.data,
      });
    }

    return response;
  });
};

export const authCheckAsync = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.USER_AUTH_CHECK_PENDING,
  });

  const apiToken = getUserToken(getState());

  if (!apiToken) {
    dispatch(actions.resetUser());
    dispatch({
      type: actionTypes.USER_AUTH_CHECK_SKIPPED,
    });
  } else {
    meService({ apiToken }).then(({ error, data }) => {
      if (error) {
        dispatch(actions.resetUser());
        dispatch({
          type: actionTypes.USER_AUTH_CHECK_ERROR,
          payload: error,
        });
      } else {
        if (prod) {
          const userId = data.id;
          smartlookClient.identify(userId, {
            name: userId,
            sessionGuid: getSessionGuid(),
          });
          identifyErrorTrackingUser(userId);
        }
        dispatch(updateUserAction({ user: data }));
        dispatch({
          type: actionTypes.USER_AUTH_CHECK_SUCCESS,
          payload: data,
        });
      }
    });
  }
};

export const fetchUserActionTypes = {
  ME_FETCH_PENDING: "ME_FETCH_PENDING",
  ME_FETCH_SUCCESS: "ME_FETCH_SUCCESS",
  ME_FETCH_ERROR: "ME_FETCH_ERROR",
};
export const fetchUserAction = (
  { includes } = { includes: defaultMeIncludes }
) => (dispatch, getState) => {
  const apiToken = getUserToken(getState());

  dispatch({
    type: fetchUserActionTypes.ME_FETCH_PENDING,
  });

  return meService({ apiToken, includes }).then(({ error, data }) => {
    if (error) {
      dispatch({
        type: fetchUserActionTypes.ME_FETCH_ERROR,
        payload: error,
      });
    } else {
      dispatch({
        type: fetchUserActionTypes.ME_FETCH_SUCCESS,
        payload: data,
      });
    }
    return { error, data };
  });
};

export const updateUserAction = ({ user }) => {
  return { type: actionTypes.USER_UPDATE, payload: user };
};

const actions = {
  USER_RESET_STATE: "USER_RESET_STATE",
  resetUser: () => {
    return { type: actions.USER_RESET_STATE };
  },

  USER_SET: "USER_SET",
  setUser: ({ user }) => {
    return { type: actions.USER_SET, payload: user };
  },

  ME_SAVE_USER_SUCCESS: "ME_SAVE_USER_SUCCESS",
  saveUser: (payload = {}) => async (dispatch, getState) => {
    const apiToken = getUserToken(getState());

    try {
      const emailResponse = await userCheckEmailService(payload.email);

      if (emailResponse.error) {
        throw emailResponse.error;
      }

      const userStatus =
        emailResponse && emailResponse.data
          ? emailResponse.data["user-status"]
          : null;
      const emailAlreadyExists =
        userStatus === "active" || userStatus === "pending";

      if (emailAlreadyExists && !payload.newPassword) {
        throw new CustomError({
          name: "EmailCheckError",
          message: `Email is already being used.`,
          payload: { status: userStatus },
        });
      }

      const userResponse = await saveUserService({
        apiToken,
        current_password: payload.currentPassword,
        password: payload.newPassword,
        email: payload.email,
      });

      if (userResponse.error) {
        throw userResponse.error;
      }

      dispatch({
        type: actions.ME_SAVE_USER_SUCCESS,
        payload: userResponse.data,
      });
      return userResponse;
    } catch (error) {
      return {
        error:
          error.name === "EmailCheckError"
            ? error.message
            : error["error-message"] ||
              `Sorry, we can't update your details at the moment. Please try again later or get in touch with us.`,
      };
    }
  },

  USER_REGISTER_PENDING: "USER_REGISTER_PENDING",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",
};

export default actions;
