export const RESET_STATE = "RESET_STATE";
export const SET_STATE = "@@replicate/SET_STATE";
export const FULLY_INITIALIZED = "@@replicate/FULLY_INITIALIZED";
export const REQUIRES_PAYMENT_AUTHORISATION = "REQUIRES_PAYMENT_AUTHORISATION";
export const RESET_PAYMENT_AUTHORISATION = "RESET_PAYMENT_AUTHORISATION";
export const LOCATION_CHANGED = "LOCATION_CHANGED";

export const resetState = (payload) => {
  return { type: RESET_STATE, payload };
};

export const requiresPaymentAuthorisation = (payload) => {
  return { type: REQUIRES_PAYMENT_AUTHORISATION, payload };
};

export const resetPaymentAuthorisationAction = () => {
  return { type: RESET_PAYMENT_AUTHORISATION };
};
