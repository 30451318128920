import api, { handleError } from "../index";

export const getCouponService = ({ couponCode }) => {
  return api({
    method: "get",
    url: `/coupons/${couponCode}`,
  })
    .then((response = {}) => {
      return {
        error: null,
        data: transformPoundsToPence(response),
      };
    })
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
};

const transformPoundsToPence = (response) => {
  const couponData = response.data.data;
  const discountAmount =
    couponData.discount_amount_type === "absolute"
      ? couponData.discount_amount * 100
      : couponData.discount_amount;
  const minimumSpend = couponData.minimum_spend * 100 || 0;

  return {
    ...couponData,
    discount_amount: discountAmount,
    minimum_spend: minimumSpend,
  };
};
