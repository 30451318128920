import { getSessionGuid } from "src/lib/tracking-cookies";
import { storeEvent } from "src/services/event/event/store";

export const dev = process.env.REACT_APP_RELEASE_STAGE !== "production";
export const test = process.env.NODE_ENV === "test";

export default function trackClickEvent(eventData = {}) {
  const payload = {
    pageview_guid: window.sessionStorage.getItem("pageviewGuid"),
    session_guid: getSessionGuid(),
    event_type: "click",
    event_target: eventData.buttonLabel,
    event_data: eventData,
  };
  if (dev && !test) {
    console.warn("storeEvent", payload);
  } else {
    storeEvent(payload);
  }
}
