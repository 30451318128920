import { updateEngravingService } from "src/services/cornerstone/subscription/engraving";
import { updateProductsService } from "src/services/cornerstone/subscription/products";
import { oneOffOrderProductsService } from "src/services/cornerstone/signup/one-off-order-products";
import { putSubscriptionCoupon } from "src/redux/coupons/actions";
import {
  getEngravingString,
  getSignupSelectedProductsCheckoutPayload,
  getHasSubmittedSignupCoupon,
  getIsSignupBasketTypeSubscribe,
} from "src/redux/signup/selectors";
import { getCouponId } from "src/redux/coupons/selectors";
import { getUserToken } from "src/redux/user/selectors";

export const actions = {
  SIGNUP__RESET_PRODUCTS: `SIGNUP__RESET_PRODUCTS`,
  SIGNUP__RESET_STATE: `SIGNUP__RESET_STATE`,
  SIGNUP__UPDATE_ENGRAVING: `SIGNUP__UPDATE_ENGRAVING`,
  SIGNUP__UPDATE_ONE_OFF_ENGRAVING: `SIGNUP__UPDATE_ONE_OFF_ENGRAVING`,
  SIGNUP__SET_COUNTRY: `SIGNUP__SET_COUNTRY`,
  SIGNUP__ADD_PRODUCT_TO_BASKET: `SIGNUP__ADD_PRODUCT_TO_BASKET`,
  SIGNUP__REMOVE_PRODUCT_IN_BASKET: `SIGNUP__REMOVE_PRODUCT_IN_BASKET`,
  SIGNUP__UPDATE_PRODUCT_FREQUENCY: `SIGNUP__UPDATE_PRODUCT_FREQUENCY`,
  SIGNUP__UPDATE_PRODUCT_QUANTITY: `SIGNUP__UPDATE_PRODUCT_QUANTITY`,
  SIGNUP__SET_BASKET_TYPE: `SIGNUP__SET_BASKET_TYPE`,
};

export const signupResetAction = (payload) => {
  return { type: actions.SIGNUP__RESET_STATE, payload };
};

export const resetProducts = (payload) => {
  return { type: actions.SIGNUP__RESET_PRODUCTS, payload };
};

export const signupSetBasketActionTypeAction = (payload) => {
  return { type: actions.SIGNUP__SET_BASKET_TYPE, payload };
};

export const addProductToBasket = (payload) => {
  return { type: actions.SIGNUP__ADD_PRODUCT_TO_BASKET, payload };
};

export const removeProductInBasket = (payload) => {
  return { type: actions.SIGNUP__REMOVE_PRODUCT_IN_BASKET, payload };
};

export const signupSetBasketAction = (basketProducts = []) => (dispatch) => {
  dispatch(resetProducts());
  basketProducts.forEach((product) =>
    dispatch(addProductToBasket({ sku: product }))
  );
};

export const updateEngraving = (payload) => {
  return { type: actions.SIGNUP__UPDATE_ENGRAVING, payload };
};

export const signupUpdateOneOffEngravingAction = (payload) => {
  return { type: actions.SIGNUP__UPDATE_ONE_OFF_ENGRAVING, payload };
};

export const updateProductFrequency = (payload) => {
  return { type: actions.SIGNUP__UPDATE_PRODUCT_FREQUENCY, payload };
};

export const updateProductQuantity = (payload) => {
  return { type: actions.SIGNUP__UPDATE_PRODUCT_QUANTITY, payload };
};

export const signupSetCountryAction = (payload) => {
  return {
    type: actions.SIGNUP__SET_COUNTRY,
    payload: payload,
  };
};

export const postCurrentSubscriptionDataAction = () => (dispatch, getState) => {
  const state = getState();
  const apiToken = getUserToken(state);
  const engraving = getEngravingString(state);
  const { products, oneOffProducts } = getSignupSelectedProductsCheckoutPayload(
    state
  );
  const couponId = getCouponId(state);
  const hasSubmittedSignupCoupon = getHasSubmittedSignupCoupon(state);
  const isSignupBasketTypeSubscribe = getIsSignupBasketTypeSubscribe(state);

  if (!apiToken) return;

  const productstoUpdate = products.map((product) => product.sku);
  if (isSignupBasketTypeSubscribe) {
    updateProductsService(apiToken, productstoUpdate);
  } else {
    oneOffOrderProductsService({ apiToken, products: oneOffProducts });
  }

  if (engraving !== "") {
    updateEngravingService({ apiToken, engraving });
  }

  if (couponId && !hasSubmittedSignupCoupon) {
    dispatch(putSubscriptionCoupon(couponId));
  }
};
