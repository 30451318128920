import * as skus from "src/data/product-skus";

export const availableSubscribeProductFrequencies = {
  [skus.RAZOR_SKU]: ["1", "2", "3"],
  [skus.SCRUB_SKU]: ["1", "2", "3"],
  [skus.GEL_SKU]: ["1", "2", "3"],
  [skus.BALM_SKU]: ["1", "2", "3"],
  [skus.TOOTH_PASTE_SKU]: ["1", "2", "3"],
  [skus.MOUTH_WASH_SKU]: ["1", "2", "3"],
  [skus.FLOSS_SKU]: ["1", "2", "3"],
  [skus.FOUR_BRUSH_CART_SKU]: ["3", "6", "12"],
  [skus.DEODORANT_SKU]: ["1", "2", "3"],
  [skus.VITAMINS_SKU]: ["1", "2", "3"],
  [skus.SHAMPOO_SKU]: ["1", "2", "3"],
  [skus.BODYWASH_SKU]: ["1", "2", "3"],
  [skus.FACE_WASH_SKU]: ["1", "2", "3"],
  [skus.MOISTURISER_SKU]: ["1", "2", "3"],
  [skus.CREAM_SKU]: ["1", "2", "3"],
  [skus.CONDOM10_SKU]: ["1", "2", "3"],
  [skus.SILD8X50_SKU]: ["1", "2", "3", "4", "5", "6"],
  [skus.SILD8X100_SKU]: ["1", "2", "3", "4", "5", "6"],
  [skus.FIN30X1_SKU]: ["1"],
  [skus.MIN65_SKU]: ["1"],
  [skus.ORLI84X120_SKU]: ["1"],
  [skus.BBCOOLSOX3_SKU]: ["1", "2", "3", "4", "5", "6", "12"],
};

export const defaultSubscribeProductFrequencies = {
  [skus.RAZOR_SKU]: "2",
  [skus.SCRUB_SKU]: "2",
  [skus.GEL_SKU]: "2",
  [skus.BALM_SKU]: "2",
  [skus.TOOTH_PASTE_SKU]: "1",
  [skus.MOUTH_WASH_SKU]: "2",
  [skus.FLOSS_SKU]: "2",
  [skus.FOUR_BRUSH_CART_SKU]: "12",
  [skus.DEODORANT_SKU]: "1",
  [skus.VITAMINS_SKU]: "1",
  [skus.SHAMPOO_SKU]: "2",
  [skus.BODYWASH_SKU]: "2",
  [skus.FACE_WASH_SKU]: "2",
  [skus.MOISTURISER_SKU]: "2",
  [skus.CREAM_SKU]: "2",
  [skus.CONDOM10_SKU]: "3",
  [skus.SILD8X50_SKU]: "2",
  [skus.SILD8X100_SKU]: "2",
  [skus.FIN30X1_SKU]: "1",
  [skus.MIN65_SKU]: "1",
  [skus.ORLI84X120_SKU]: "1",
  [skus.BBCOOLSOX3_SKU]: "3",
};
