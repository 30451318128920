const baseTags = {
  title:
    "Cornerstone - Quality Men's shaving, bathroom and healthcare supplies, delivered regularly",
  description: `Cornerstone - a flexible, regular delivery of shaving, bathroom and healthcare supplies, so you always have everything you need for a great start to your day.`,
  url: "https://www.cornerstone.co.uk",
};

export default {
  base: baseTags,
  gifts: {
    title: "Gifts | Cornerstone",
    description: `The Cornerstone gift set offers everything in our entire range for a smooth, comfortable shave. Including: Pre-Shave Face Scrub, Sensitive Shave Gel, Foaming Shave Cream, the Cornerstone Razor and Cooling Post-Shave Balm. The perfect present for a loved one.`,
    url: "https://www.cornerstone.co.uk/gifts",
  },
  homepage: baseTags,
  howItWorks: {
    title: "How It Works | Cornerstone",
    description: `Find out how a Cornerstone Shave Plan works, including how a subscription works, how to order, and when you pay.`,
    url: "https://www.cornerstone.co.uk/how-it-works",
  },
  login: {
    title:
      "Your Account | Cornerstone - Quality Men's shaving, bathroom and healthcare supplies, delivered regularly",
    description: `Login and manage your Cornerstone account and subscription.`,
    url: "https://www.cornerstone.co.uk/login",
  },
  ourStory: {
    title: "Our Story | Cornerstone",
    description: `Read about how Cornerstone began from humble beginnings at our founders kitchen table. Discover the mission and purpose behind Cornerstone.`,
    url: "https://www.cornerstone.co.uk/our-story",
  },
  product: ({ title, description, slug }) => ({
    ...baseTags,
    ...(title && { title }),
    ...(description && { description }),
    ...(slug && { url: `https://www.cornerstone.co.uk/products/${slug}` }),
  }),
  products: {
    title: "Products | Cornerstone",
    description: `Browse our range of high quality bathroom supplies. Including: Face Scrub, Shave Gel, Shave Cream, the Cornerstone Razor and Post-Shave Balm.`,
    url: "https://www.cornerstone.co.uk/products",
  },
  productCategory: ({ title, description, category }) => ({
    ...baseTags,
    ...(title && { title: `${title} | Cornerstone` }),
    ...(description && { description }),
    ...(category && {
      url: `https://www.cornerstone.co.uk/products/category/${category}`,
    }),
  }),
  signupBasket: {
    title: "Signup Basket | Cornerstone",
    description: `Start your Cornerstone signup by building your perfect basket. Adjust your delivery frequencies or quantities to match your usage habits.`,
    url: "https://www.cornerstone.co.uk/signup/basket",
  },
};
