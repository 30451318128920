import api, { handleError } from "../index";
import { getSessionGuid } from "src/lib/tracking-cookies";

export function authenticatePaymentService({ payload, apiToken }) {
  return api({
    method: "post",
    url: "/payments/authenticate",
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
    data: {
      session_guid: getSessionGuid(),
      MD: payload.MD,
      PaRes: payload.PaRes,
      PaymentData: payload.PaymentData,
    },
  })
    .then((response) => ({ error: null, data: response.data }))
    .catch((error) => {
      const errorPayload = handleError(error);
      return {
        ...errorPayload,
        data: null,
      };
    });
}
