import { createSelector } from "reselect";
import get from "src/lib/get";
import { getCouponDiscountString } from "./helpers";

export function getCoupon(state) {
  return get(state, "coupons.data", {});
}

export function isCouponPending(state) {
  return get(state, "coupons.isPending", {});
}

export function getHasCouponError(state) {
  return !!get(state, "coupons.error", null);
}

export const getCouponDiscountAmountFormatted = createSelector(
  getCoupon,
  (coupon) => getCouponDiscountString(coupon)
);

export const getHasCoupon = createSelector(
  getCoupon,
  (coupon) => !!coupon.code
);

export const getCouponCode = createSelector(getCoupon, (coupon) =>
  get(coupon, "code", "")
);

export const getCouponId = createSelector(getCoupon, (coupon) =>
  get(coupon, "id", null)
);

export const getCouponMinimumSpend = createSelector(getCoupon, (coupon) =>
  get(coupon, "minimum_spend", 0)
);
