import {
  RAZOR,
  HANBLADE,
  SILD8X50,
  SILD8X100,
  FIN30X1,
  MIN65,
  FACE_WASH,
  SCRUB,
  GEL,
  CREAM,
  BALM,
  MOISTURISER,
  BODYWASH,
  DEODORANT,
  FLOSS,
  TOOTH_PASTE,
  VITAMINS,
  ORLI84X120,
  BBCOOLSOX3
} from './product-data'

const imageOptions = '?fm=jpg&fl=progressive&bg=rgb:fff'

export const PRODUCT_RAZOR = {
  ...RAZOR,
  name: '6 Razor Blades',
  image: RAZOR.image + imageOptions,
  link: '/products/mens-razor-blades'
}

export const PRODUCT_HANDLE = {
  ...HANBLADE,
  image: HANBLADE.image + imageOptions,
  link: '/products/mens-razor-blade-and-handle'
}

export const PRODUCT_SILD8X50 = {
  ...SILD8X50,
  name: '8 Sildenafil Erectile Dysfunction Treatment Tablets, 50mg',
  image: SILD8X50.image + imageOptions,
  link: '/products/erectile-dysfunction-treatment',
  addToBasketLink: '/signup/consultation/ed',
  ctaText: 'Start consultation'
}

export const PRODUCT_SILD8X100 = {
  ...SILD8X100,
  name: '8 Sildenafil Erectile Dysfunction Treatment Tablets, 100mg',
  image: SILD8X100.image + imageOptions,
  link: '/products/erectile-dysfunction-treatment',
  addToBasketLink: '/signup/consultation/ed',
  ctaText: 'Start consultation'
}

export const PRODUCT_FIN30X1_MIN65 = {
  id: `${FIN30X1.id}-${MIN65.id}`,
  priceInPence: 4500,
  category: FIN30X1.category,
  slug: 'hair-loss-combo-treatment',
  name: `Hair Loss Treatment, 30 Finasteride Tablets & Minoxidil Spray`,
  image:
    'https://images.ctfassets.net/itvcls9imtx8/2E0zwTtss5hHO89TEcrhKW/c573c53ab7736c76b3b4c88cea31d89f/HL.jpg' +
    imageOptions,
  link: '/products/hair-loss-combo-treatment',
  addToBasketLink: '/signup/consultation/hl',
  ctaText: 'Start consultation'
}

export const PRODUCT_FIN30X1 = {
  ...FIN30X1,
  name: `Hair Loss Treatment, 30 Finasteride Tablets`,
  image:
    'https://images.ctfassets.net/itvcls9imtx8/6BUClu6pUFOLCrjQM9U4nf/646aeccf1bbfdf877181f9f346229525/Tablets.jpg' +
    imageOptions,
  link: '/products/hair-loss-tablet-treatment',
  addToBasketLink: '/signup/consultation/hl',
  ctaText: 'Start consultation'
}

export const PRODUCT_MIN65 = {
  ...MIN65,
  name: `Hair Loss Treatment, Minoxidil Spray`,
  image:
    'https://images.ctfassets.net/itvcls9imtx8/HFXUt5RQ0RjCItngK4gir/d7c1ce3df7e4da85eab7cb2be5722e4f/Spray.jpg' +
    imageOptions,
  link: '/products/hair-loss-spray-treatment',
  addToBasketLink: '/signup/consultation/hl',
  ctaText: 'Start consultation'
}

export const PRODUCT_FACE_WASH = {
  ...FACE_WASH,
  name: `Oil-Free Face Wash`,
  image: FACE_WASH.image + imageOptions,
  link: '/products/mens-face-wash'
}

export const PRODUCT_SCRUB = {
  ...SCRUB,
  name: `Pre-Shave Face Scrub`,
  image: SCRUB.image + imageOptions,
  link: '/products/mens-face-scrub'
}

export const PRODUCT_GEL = {
  ...GEL,
  name: `Sensitive Shave Gel`,
  image: GEL.image + imageOptions,
  link: '/products/shaving-gel'
}

export const PRODUCT_CREAM = {
  ...CREAM,
  name: `Sensitive Shave Cream`,
  image: CREAM.image + imageOptions,
  link: '/products/shaving-cream'
}

export const PRODUCT_BALM = {
  ...BALM,
  name: `Post-Shave Balm`,
  image: BALM.image + imageOptions,
  link: '/products/after-shave-balm'
}

export const PRODUCT_MOISTURISER = {
  ...MOISTURISER,
  name: `Oil-Free Moisturiser`,
  image: MOISTURISER.image + imageOptions,
  link: '/products/mens-oil-free-moisturiser'
}

export const PRODUCT_BODYWASH = {
  ...BODYWASH,
  name: `Energising Body Wash`,
  image: BODYWASH.image + imageOptions,
  link: '/products/mens-body-wash'
}

export const PRODUCT_DEODORANT = {
  ...DEODORANT,
  name: `48 Hour Antiperspirant Deodorant`,
  image: DEODORANT.image + imageOptions,
  link: '/products/deodorant'
}

export const PRODUCT_FLOSS = {
  ...FLOSS,
  name: `Gentle Floss Tape`,
  image: FLOSS.image + imageOptions,
  link: '/products/dental-floss'
}

export const PRODUCT_TOOTH_PASTE = {
  ...TOOTH_PASTE,
  name: `Gentle Whitening Toothpaste`,
  image: TOOTH_PASTE.image + imageOptions,
  link: '/products/whitening-toothpaste'
}

export const PRODUCT_VITAMINS = {
  ...VITAMINS,
  name: `30 Energy Boosting Multivitamins`,
  image: VITAMINS.image + imageOptions,
  link: '/products/mens-multivitamins'
}

export const PRODUCT_ORLI84X120 = {
  ...ORLI84X120,
  name: `Orlistat Weight Loss Treatment`,
  image: ORLI84X120.image + imageOptions,
  link: '/products/orlistat-weight-loss-treatment',
  addToBasketLink: '/signup/consultation/wl',
  ctaText: 'Start consultation'
}

export const PRODUCT_BBCOOLSOX3 = {
  ...BBCOOLSOX3,
  image: BBCOOLSOX3.image + imageOptions,
  link: `/products/${BBCOOLSOX3.slug}`
}

const products = [
  PRODUCT_RAZOR,
  PRODUCT_HANDLE,
  PRODUCT_SILD8X50,
  PRODUCT_SILD8X100,
  PRODUCT_FIN30X1_MIN65,
  PRODUCT_FIN30X1,
  PRODUCT_MIN65,
  PRODUCT_FACE_WASH,
  PRODUCT_SCRUB,
  PRODUCT_GEL,
  PRODUCT_CREAM,
  PRODUCT_BALM,
  PRODUCT_MOISTURISER,
  PRODUCT_BODYWASH,
  PRODUCT_DEODORANT,
  PRODUCT_FLOSS,
  PRODUCT_TOOTH_PASTE,
  PRODUCT_VITAMINS,
  PRODUCT_ORLI84X120,
  PRODUCT_BBCOOLSOX3
]

export default products

export function createUpsellList(
  basketContent = [],
  upsellSkusByPriority = [],
  maxItems = 8
) {
  return products
    .filter(
      product =>
        upsellSkusByPriority.indexOf(product.id) > -1 &&
        basketContent.indexOf(product.id) < 0
    )
    .sort(
      (a, b) =>
        upsellSkusByPriority.indexOf(a.id) - upsellSkusByPriority.indexOf(b.id)
    )
    .slice(0, maxItems)
}
