import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import { setTrackingCookies } from "src/lib/tracking-cookies";
import metaData from "src/data/meta-data";
import MetaData from "src/react/components/meta-data";
import ErrorBoundary from "src/react/components/error-boundary";
import CookieBanner from "src/react/components/cookie-banner";
import ToastNotifications from "src/react/components/toast";
import Page from "src/react/layout/page";
import Routes from "src/react/routes";
import GlobalStyles from "src/styles/style";

function App({ store, history }) {
  useEffect(() => {
    setTrackingCookies();
  }, []);

  return (
    <Fragment>
      <GlobalStyles />
      <ReduxProvider store={store}>
        <Router history={history}>
          <ErrorBoundary location={history.location}>
            <Page>
              <MetaData data={metaData.base} />
              <CookieBanner />
              <Routes />
              <ToastNotifications />
            </Page>
          </ErrorBoundary>
        </Router>
      </ReduxProvider>
    </Fragment>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

export default App;
