import { css } from "styled-components";

export const font = {
  primary: `'Poppins', 'Helvetica', 'Arial', sans-serif`,
};

// prettier-ignore
export const color = {
  azalea:           "#f7cfcf",
  darkGrey:         "#728791",
  blueishGrey:      "#dce6ea",
  blueishLightGrey: "#e6ebef",
  aquaIsland:       "#acdfda",
  midBlue:          "#1d81a1",
  darkBlue:         "#2f8da7",
  blue:             "#4fb2ce",
  darkOrange:       "#ff8c00",
  funGreen:         "#009933",
  green:            "#007940",
  asparagus:        "#479642",
  gold:             "#ffd700",
  lightGold:        "#fff0a0",
  prelude:          "#c4b3dd",
  red:              "#c41e3a",
  torchRed:         "#f5003d",
  black:            "#000000",
  mineShaft:        "#333333",
  grey:             "#3b454a",
  emperor:          "#999999",
  midGrey:          "#5e5e5e",
  silverChalice:    "#b2b2b2",
  dustyGrey:        "#cccccc",
  silverGrey:       "#dddddd",
  shipGrey:         "#eeeeee",
  lightGrey:        "#f5f5f5",
  white:            "#ffffff"
}

export const link = css`
  color: ${color.blue};
  cursor: pointer;
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 400;
  hyphens: auto;
  :hover {
    color: ${color.darkBlue};
  }
`;

export const fakeFocus = css`
  outline-style: solid;
  outline-color: Highlight;

  /* WebKit gets its native focus styles */
  @media (-webkit-min-device-pixel-ratio: 0) {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
`;
