import { productsBySku } from "./product-data";
import {
  SILD8X50_SKU,
  SILD8X100_SKU,
  FIN30X1_SKU,
  MIN65_SKU,
  ORLI84X120_SKU,
} from "./product-skus";

export function getProductsByCategory(products = [], categoriesFilter = []) {
  const productsByCategory = {};

  if (!products) return {};

  products.forEach((product) => {
    const productData =
      productsBySku[product.sku || product.id || product] || {};
    const category = product.category || productData.category;
    const currentCategory = productsByCategory[category] || [];

    if (
      categoriesFilter.length === 0 ||
      categoriesFilter.indexOf(category) > -1
    ) {
      productsByCategory[category] = [...currentCategory, product];
    }
  });
  return productsByCategory;
}

export function groupProductsBySku(productsArray = []) {
  const productsBySku = {};
  productsArray.forEach((product) => {
    const existentProduct = productsBySku[product.sku] || [];
    productsBySku[product.sku] = [...existentProduct, product];
  });
  return productsBySku;
}

export function containsPharmaceuticalProduct(products = []) {
  return products.some((product) =>
    [
      SILD8X50_SKU,
      SILD8X100_SKU,
      FIN30X1_SKU,
      MIN65_SKU,
      ORLI84X120_SKU,
    ].includes(product.sku || product.id || product)
  );
}

// TODO: Add unit tests
export function isPharmaProduct(sku) {
  return [
    SILD8X50_SKU,
    SILD8X100_SKU,
    FIN30X1_SKU,
    MIN65_SKU,
    ORLI84X120_SKU,
  ].includes(sku);
}

export function isEDProduct(sku) {
  return sku === SILD8X100_SKU || sku === SILD8X50_SKU;
}

export function isHairLossProduct(sku) {
  return sku === FIN30X1_SKU || sku === MIN65_SKU;
}

export function isWeightLossProduct(sku) {
  return sku === ORLI84X120_SKU;
}

export function containsEDProduct(products = []) {
  return products.some((product) =>
    isEDProduct(product.sku || product.id || product)
  );
}

export function containsHairLossProduct(products = []) {
  return products.some((product) =>
    isHairLossProduct(product.sku || product.id || product)
  );
}

export function containsWeightLossProduct(products = []) {
  return products.some((product) =>
    isWeightLossProduct(product.sku || product.id || product)
  );
}

export function containsAllHairLossProducts(products = []) {
  const hlProducts = [FIN30X1_SKU, MIN65_SKU];

  if (!products) return false;

  if (!products.length || products.length < hlProducts.length) return false;

  return products.every((product) =>
    hlProducts.includes(product.sku || product.id || product)
  );
}
