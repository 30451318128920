import { SILD8X50_SKU } from "src/data/product-skus";
import { SET_STATE, RESET_STATE } from "src/redux/app/actions";
import { isEDProduct } from "src/data/helpers";
import { actionTypes as userActionTypes } from "src/redux/user/actions";
import { actions as signupActions } from "src/redux/signup/actions";
import { parseUserActiveCoupons } from "../helpers";
import { actionTypes } from "./actions";

export const DEFAULT_FREQUENCY = 2;

export const defaultState = {
  version: 4,
  isPending: false,
  error: null,
  answers: {
    condition: {},
    medicalHistory: {},
    terms: {},
  },
  productOptions: {
    strength: SILD8X50_SKU,
    frequency: DEFAULT_FREQUENCY,
  },
  coupons: {
    data: [],
    error: null,
  },
};

export default function edConsultation(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_STATE:
      return state.version === defaultState.version ? state : defaultState;

    case RESET_STATE:
      return defaultState;

    case actionTypes.ED_CONSULTATION_SUBMIT_PENDING:
      return {
        ...state,
        isPending: true,
      };

    case actionTypes.ED_CONSULTATION_SUBMIT_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case actionTypes.ED_CONSULTATION_SUBMIT_SUCCESS:
      return defaultState;

    case actionTypes.ED_CONSULTATION_SET_PRODUCT_OPTIONS:
      return {
        ...state,
        productOptions: {
          ...state.productOptions,
          ...action.payload,
          frequency:
            parseInt(action.payload.frequency) ||
            state.productOptions.frequency,
        },
      };

    case signupActions.SIGNUP__UPDATE_PRODUCT_FREQUENCY:
      if (isEDProduct(action.payload.sku)) {
        return {
          ...state,
          productOptions: {
            ...state.productOptions,
            frequency: parseInt(action.payload.frequency),
          },
        };
      }
      return state;

    case actionTypes.ED_CONSULTATION_SAVE:
      return {
        ...state,
        answers: {
          ...state.answers,
          ...action.payload.answers,
        },
      };

    case actionTypes.ED_CONSULTATION_COUPONS_FETCH_SUCCESS: {
      const coupons = [].concat(action.payload);
      return {
        ...state,
        coupons: {
          ...state.coupons,
          data: [...state.coupons.data, ...coupons],
        },
      };
    }

    case userActionTypes.USER_FETCH_ACTIVE_COUPONS_SUCCESS: {
      const userActiveCoupons = [].concat(
        parseUserActiveCoupons(action.payload)
      );
      return {
        ...state,
        coupons: {
          ...state.coupons,
          data: [...userActiveCoupons],
        },
      };
    }

    case actionTypes.ED_CONSULTATION_COUPONS_FETCH_ERROR:
    case userActionTypes.USER_FETCH_ACTIVE_COUPONS_ERROR:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          data: [],
          error: action.payload,
        },
      };

    default:
      return state;
  }
}
