import * as skus from "./product-skus";

export const squareImages = {
  [skus.BLADE_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/1VPXxhNGyAyg44euIg0kaO/eb95c481e96ab2032f61b197b8896358/Blades-min.png",
  [skus.SCRUB_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/6dSCXMWLK0y6oAKOagmka4/5bfe02a8fc55fdba252d2a45460abd9f/Scrub-min.png",
  [skus.FACE_WASH_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/RhALsI56EuEoewWOOWq6i/79047a120443ba2993b0b6f4c46ece58/Face-Wash-min.png",
  [skus.GIFTSET_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/58bHOrcEiDBTeoFydrCqk7/4cb370e7f55379d012423997e3876782/Lux-Box-Image-1__1_.jpg",
  [skus.LUXGIFTSETGOLD_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/26vwABfXDI3SZwcEGNzdKR/f904deded4da1b246652b64a33b7d60f/Lux-Box-1-GOLD.jpg",
  [skus.LUXURY_WASHSET_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/2JkMjEi9jQq31ONOv4fmCI/ab452608177b1b6a00d2e1b579c68e6e/Body_Wash_Gift_Box.jpg",
  [skus.MINI_GIFTSET_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/5yXkWl5Aw724EdBHXqbsxn/70b4ad309153750666d26584b19e7eda/Trial-Box-Image-Christmas-Edit-Updated-Razor.jpg",
  [skus.MOISTURISER_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/2flp1Ii9N2Aka2KcaWuaao/6cdf310014cba76e616344483b64d27d/Moisturiser-min.png",
  [skus.BALM_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/613xUBxJZYSGMK2IKQ0o40/0f68be1019c28671bda305d72b9731a1/Shave-Balm-min.png",
  [skus.TRAVEL_CREAM_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/1q1j9d6qRmEQ8wqskQO0G/abad3f7ec16b202b18cf42e9b81f33fe/product-shave-cream-2.png",
  [skus.TRAVEL_GEL_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/2Vkg2gPYG4kGWi2i0S4Ag8/f368e0863796f41f5febc5ddb7dc0de3/product-shave-gel-3.png",
  [skus.GEL_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/6aoNsBi4QoOkAs4SQ4OmOQ/0ce194ddfde75067c5bf2cccfc0517ed/Shave-Gel-min.png",
  [skus.WASHBAG_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/1Wk18XyPlOCIS2U8MkGEwa/c766ab956bff83a7aadb14fb46771316/Wash-Bag-Image.jpg",
  [skus.BLADE_PROTECTOR_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/5XtqYQ6Q3mywsGgquKA2qM/a7974a5b4970e4f2aa6657c46e92a1c8/Cornerstone_Cover__1_.png",
  [skus.HANBLADE_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/1kc95ojNhWmhn6agDPLDXy/b0da8b70b758d5a67579a03883de91d8/razor-single.jpg",
  [skus.GOLD_HANDLE_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/1QPCx53pdGkGE8ocQQ0qEK/0a932743ed7edd0e83f787d71bc0ccfc/gold_handle_front.png",
  [skus.DEODORANT_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/3G1024UpwAg06IoA0A6qSI/8a6fe86d5048598463ecda096198780e/Cornerstone-Deodrant-Image-500x500-1.png",
  [skus.SHAMPOO_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/cwMjxuoJFuSOYYOkcW2Q0/06f1198126a1b0fa6cf055537d000722/Shampoo_Ingredients.png",
  [skus.BODYWASH_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/6WV4lmaUvxMyS0xNzpp0X1/e7711eb17ddc9f8069e61c2d010ea05e/Body_Wash_Ingredients.jpg",
  [skus.FOUR_BRUSH_CART_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/6K76kqs6LD3L82dcGNdYms/92f3f24deb5747f5ac74177672cb0bae/17-4-Deep-Clean-Toothbrush-Heads-1.jpg",
  [skus.TOOTH_PASTE_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/5K5TWTbH7VQGoKZufB362V/259394914cfca6af7f7a57f6936b7345/Toothpaste-product.jpg",
  [skus.MOUTH_WASH_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/2PQOLyTqIHXJKgMJvBrjn5/24456c27637781223d44123577afddfe/19-Gentle-Fresh-Mint-Mouthwash-1.jpg",
  [skus.FLOSS_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/4UqSvXdiXmoaOq2s884Wsq/e740c83a30ad5f461ed759d82338c24d/Floss-Square-2500.png",
  [skus.CREAM_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/34z5pJt26cwiMyWIKCEwCk/3331f042da79b113a387d8c5b9c7967f/Shave-Cream-min.png",
  [skus.RAZOR_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/2l59zAideUYAqkgKo2EuE8/3b14cbe0424e7bd01cfe53d3f28e261d/Blades-6.png",
  [skus.VITAMINS_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/3ulnATTZ5fyPtZ2XXNdaQW/8944bf117940fea8d1ba1db2313dea6d/16-Energy-Boosting-Multi-Vitamins-1__1_.jpg",
  [skus.SILD8X100_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/7DPKIJlG1FTszwb1GRImO2/8daeb701885043e03ec3a1de80e78d4e/ED-Pills-1000x1000.jpg",
  [skus.SILD8X50_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/7DPKIJlG1FTszwb1GRImO2/8daeb701885043e03ec3a1de80e78d4e/ED-Pills-1000x1000.jpg",
  [skus.FIN30X1_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/3zjHR5a3qyGYOz7UteueP/81efb160a56ebf51fda359b2224d51c0/30-Finasteride-1mg.png",
  [skus.MIN65_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/7ihdezpzyz1QM5mzxpgD1n/8885a9da251a7f5bcbb18e5d73654ac5/Minoxidil-60ml.png",
  [skus.CONDOM10_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/6qosGKFNkr9AqYl7QxK8lw/41ee6a06039679876b8fe40335d6e837/10-Ultra-Thin-Condoms-1.jpg",
  [skus.ORLI84X120_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/6elILAWsGp5WOkXAvZYxYi/d610d10e10ed0e458c3aa4dfe9336b4e/Orlistat-product-optimised.jpg",
  [skus.BBCOOLSOX3_SKU]:
    "//images.ctfassets.net/itvcls9imtx8/6Fwo8ZvSSmdqrTC96kbwg0/22d6ba68ceb48fd1b77c7732e4cdd1c4/BambooSocksWhiteBG.jpg",
};
