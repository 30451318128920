import { RESET_STATE } from "src/redux/app/actions";
import actions from "./actions";

const defaultState = {
  recentOrders: {
    isPending: false,
    data: [],
    error: null,
  },
};

export default function shipment(state = defaultState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case RESET_STATE:
      return defaultState;

    case actions.PARCEL_FETCH_RECENT_PARCELS_PENDING:
      return {
        ...state,
        recentOrders: {
          ...state.recentOrders,
          isPending: true,
        },
      };
    case actions.PARCEL_FETCH_RECENT_PARCELS_ERROR:
      return {
        ...state,
        recentOrders: {
          ...state.recentOrders,
          isPending: false,
          error: payload,
        },
      };
    case actions.PARCEL_FETCH_RECENT_PARCELS_SUCCESS:
      return {
        ...state,
        recentOrders: {
          ...state.recentOrders,
          isPending: false,
          data: payload,
        },
      };

    default:
      return state;
  }
}
