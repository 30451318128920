import history from "src/react/history";
import { updateParcelAddressService } from "src/services/cornerstone/parcel/address";
import { fetchRecentParcelsService } from "src/services/cornerstone/parcel/recent";
import { getUserToken } from "src/redux/user/selectors";

const actions = {
  PARCEL_ADDRESS_UPDATE_PENDING: "PARCEL_ADDRESS_UPDATE_PENDING",
  updateAddressPending: () => ({
    type: actions.PARCEL_ADDRESS_UPDATE_PENDING,
  }),

  PARCEL_ADDRESS_UPDATE_ERROR: "PARCEL_ADDRESS_UPDATE_ERROR",
  updateAddressError: (error) => ({
    type: actions.PARCEL_ADDRESS_UPDATE_ERROR,
    payload: error,
  }),

  PARCEL_ADDRESS_UPDATE_SUCCESS: "PARCEL_ADDRESS_UPDATE_SUCCESS",
  updateAddressSuccess: (data) => ({
    type: actions.PARCEL_ADDRESS_UPDATE_SUCCESS,
    payload: data,
  }),

  PARCEL_ADDRESS_UPDATE: "PARCEL_ADDRESS_UPDATE",
  updateAddress: ({ invoiceableType, invoiceableId, payload = {} }) => (
    dispatch,
    getState
  ) => {
    const apiToken = getUserToken(getState());

    dispatch(actions.updateAddressPending());

    return updateParcelAddressService({
      apiToken,
      invoiceableType,
      invoiceableId,
      payload,
    }).then(({ data, error }) => {
      if (error) {
        dispatch(actions.updateAddressError(error));
      } else {
        dispatch(actions.updateAddressSuccess(data));
        history.push("/account");
      }
    });
  },

  PARCEL_FETCH_RECENT_PARCELS_PENDING: "PARCEL_FETCH_RECENT_PARCELS_PENDING",
  PARCEL_FETCH_RECENT_PARCELS_SUCCESS: "PARCEL_FETCH_RECENT_PARCELS_SUCCESS",
  PARCEL_FETCH_RECENT_PARCELS_ERROR: "PARCEL_FETCH_RECENT_PARCELS_ERROR",
  fetchRecentParcels: ({ limit, cancelToken } = { limit: 3 }) => async (
    dispatch,
    getState
  ) => {
    const state = getState();
    const apiToken = getUserToken(state);

    dispatch({
      type: actions.PARCEL_FETCH_RECENT_PARCELS_PENDING,
      payload: {
        limit,
      },
    });

    const response = await fetchRecentParcelsService({
      apiToken,
      limit,
      cancelToken,
    });

    if (response.data) {
      dispatch({
        type: actions.PARCEL_FETCH_RECENT_PARCELS_SUCCESS,
        payload: response.data,
      });
    }

    if (response.error) {
      dispatch({
        type: actions.PARCEL_FETCH_RECENT_PARCELS_ERROR,
        payload: response.error,
      });
    }

    return response;
  },
};

export default actions;
