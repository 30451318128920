import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "src/react/layout/container";
import logo from "src/assets/images/logos/cornerstone-logo-nav.svg";
import NavBar from "./navbar";

function Header({ hideNav }) {
  return (
    <Wrapper>
      <LogoContainer>
        <LogoLink data-selector="LogoLink" to="/">
          <Logo src={logo} alt="Cornerstone Logo" width={300} height={42} />
        </LogoLink>
      </LogoContainer>
      <NavBar hideNav={hideNav} />
    </Wrapper>
  );
}

export default Header;

const breakPoint = "@media (min-width: 600px)";
const breakPoint2 = "@media (min-width: 930px)";

const Wrapper = styled.header`
  padding-top: 10px;
  max-height: 95px;
`;

const Logo = styled.img`
  display: block;
  width: 160px;
  height: 23px;
  margin-bottom: -1px;
  ${breakPoint} {
    width: 230px;
    height: 32px;
  }
  ${breakPoint2} {
    width: 270px;
    height: 38px;
  }
`;

const LogoContainer = styled(Container)`
  display: flex;
`;

const LogoLink = styled(Link)`
  display: inline-block;
  vertical-align: bottom;
`;
