import { createSelector } from "reselect";
import get from "src/lib/get";
import { getData } from ".";

export const getReferralLink = createSelector(getData, (data) => {
  const referralCode = get(data, "referral_code", null);
  if (referralCode) {
    return `https://www.cornerstone.co.uk/7${referralCode.toLowerCase()}`;
  }
  return null;
});
