import get from "src/lib/get";
import formatCurrency from "src/lib/format-currency";

export const getCouponProductsSkus = (coupon) => {
  const products = get(coupon, "products.data", []);
  return products.map((product) => product.id);
};

export function getCouponDiscountString(coupon = {}) {
  const discountAmountType = coupon.discount_amount_type;
  const discountAmount = coupon.discount_amount;

  if (discountAmountType === "absolute") {
    return formatCurrency({ value: discountAmount });
  }

  if (discountAmountType === "percentage") {
    return `${discountAmount}%`;
  }

  return null;
}
