import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import isEqual from "fast-deep-equal";
import IdbStore from "src/lib/idb-keyval";
import trackError from "src/lib/track-error";
import isEmpty from "src/lib/isEmpty";
import api from "src/services/cornerstone";
import { SET_STATE, FULLY_INITIALIZED } from "src/redux/app/actions";
import { getUserToken as getApiToken } from "./user/selectors";
import authCheck from "./middleware/auth-check";
import restrictCancelledUsers from "./middleware/restrict-cancelled-users";
import couponMiddleware from "./coupons/middleware";
import { initialReducers } from "./root-reducer";

const replicateReducerKeys = [
  "coupons",
  "signup",
  "user",
  "edConsultation",
  "hairLossConsultation",
  "weightLossConsultation",
  "idCheck",
];

const composeEnhancers = composeWithDevTools({
  actionsBlacklist: [],
});

const customIdbStore = new IdbStore("data", "keyvaluepairs", 2);

async function getPersistedInitialState(initialState) {
  return Promise.all(
    replicateReducerKeys.map((key) => customIdbStore.get(`cornerstone/${key}`))
  ).then((reducers) => {
    const newInitialState = {};
    reducers.forEach((data, index) => {
      if (data) {
        newInitialState[replicateReducerKeys[index]] = data;
      }
    });
    return { ...initialState, ...newInitialState };
  });
}

const makeReplicateReducerHandler = (reducer) => (state, action) => {
  const initialState =
    action.type === SET_STATE ? { ...state, ...action.payload } : state;
  const nextState = reducer(initialState, action);

  if (isEmpty(initialState) || action.type === FULLY_INITIALIZED)
    return nextState;

  if (action.type === "INITIAL_PERSISTED_STATE") {
    return action.payload;
  }

  // Persist reducer state on action types other than FULLY_INITIALIZED and INITIAL_PERSISTED_STATE
  if (customIdbStore.status === "resolved") {
    Object.keys(nextState).forEach((reducerKey) => {
      if (
        replicateReducerKeys.indexOf(reducerKey) > -1 &&
        !isEqual(state[reducerKey], nextState[reducerKey])
      ) {
        customIdbStore.set(`cornerstone/${reducerKey}`, nextState[reducerKey]);
      }
    });
  }

  return nextState;
};

function replicateStore(next) {
  return (reducer, initialState) => {
    const replicateReducerHandler = makeReplicateReducerHandler(reducer);
    const store = next(replicateReducerHandler, initialState);
    // Indexeddb is async, so we need to dispatch an action when persisted reducers state is retrieved in order to hydrate the app's reducers
    getPersistedInitialState(store.getState())
      .then((persistedInitialState = {}) => {
        // 2 different actions are needed here to make sure the persisted state is in place before the app is fully initialised and routes running. Avoids race conditions.
        store.dispatch({
          type: "INITIAL_PERSISTED_STATE",
          payload: persistedInitialState,
        });
        store.dispatch({
          type: FULLY_INITIALIZED,
        });
      })
      .catch((error) => {
        console.warn({ error });
        store.dispatch({
          type: FULLY_INITIALIZED,
        });
      });
    return store;
  };
}

function createReducerManager(_initialReducers) {
  // Create an object which maps keys to reducers
  const reducers = { ..._initialReducers };
  let combinedReducer = combineReducers(reducers);

  return {
    getReducerMap: () => reducers,
    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      return combinedReducer(state, action);
    },

    add: ({ reducerKey, reducer }) => {
      if (!reducerKey || reducers[reducerKey]) {
        return;
      }

      reducers[reducerKey] = reducer;

      combinedReducer = combineReducers(reducers);
    },
  };
}

export default function configureStore(initialState = {}) {
  const reducerManager = createReducerManager(initialReducers);
  // Create a store with the root reducer function being the one exposed by the manager.
  const store = createStore(
    reducerManager.reduce,
    initialState,
    composeEnhancers(
      replicateStore,
      applyMiddleware(
        thunk.withExtraArgument({ api, getApiToken, trackError }),
        authCheck,
        couponMiddleware,
        restrictCancelledUsers
      )
    )
  );

  // Put the reducer manager on the store so it is easily accessible
  store.reducerManager = reducerManager;

  return store;
}
