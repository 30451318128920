import React from "react";
import styled from "styled-components";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { color } from "src/styles/variables";

function SocialLinks() {
  return (
    <Wrapper>
      <List>
        <SocialItem
          text="Twitter"
          href="https://www.twitter.com/Cornerstone_HQ"
          logo={FaTwitter}
        />
        <SocialItem
          text="Facebook"
          href="https://www.facebook.com/CornerstoneHQ"
          logo={FaFacebookF}
        />
        <SocialItem
          text="Instagram"
          href="https://instagram.com/Cornerstone_HQ"
          logo={FaInstagram}
        />
      </List>
    </Wrapper>
  );
}

export default SocialLinks;

const SocialItem = ({ text, href, logo }) => {
  const Logo = logo;
  return (
    <ListItem>
      <SocialLink
        href={href}
        target="_blank"
        rel="noopener"
        title={`Go to Cornerstone ${text} profile`}
      >
        <Logo size={18} />
        {text}
      </SocialLink>
    </ListItem>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  margin: 10px;
`;

const SocialLink = styled.a`
  display: block;
  padding: 7px;
  text-decoration: none;
  font-size: 0;
  cursor: pointer;
  border: 2px solid ${color.white};
  border-radius: 50%;
  letter-spacing: 0;
`;
