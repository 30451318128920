import React from "react";
import styled, { css } from "styled-components";
import { A, RRNavLink, Link } from "src/react/components/link";
import { color } from "src/styles/variables";
import Container from "src/react/layout/container";

export const breakPoint = "@media (min-width: 768px)";
export const breakPoint2 = "@media (min-width: 930px)";
export const breakPoint3 = "@media (min-width: 960px)";

export const Nav = styled.nav`
  background-color: ${color.red};
  font-size: 12px;
  position: relative;
  ${breakPoint3} {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    :after {
      content: "";
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      z-index: -1;
      height: 100vh;
      right: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }
    ${({ isOpened }) =>
      isOpened &&
      `
      :after {
        z-index: 99;
        opacity: 1;
      }
    `}
  }
`;

export const NavListWrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

export const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  color: ${color.white};
  position: relative;
  ${breakPoint} {
    justify-content: space-around;
  }
  ${breakPoint2} {
    justify-content: flex-end;
  }

  li:last-child a {
    margin-right: 0;
  }
`;

export const NavListMobileWrapper = styled.div`
  background: ${color.dustyGrey};
  padding: 10px;
  height: 100vh;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 35%;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear,
    visibility 0.2s linear;
  visibility: hidden;
  ${({ isOpened }) =>
    isOpened &&
    `
    z-index: 100;
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  `}
`;

const navLinkMobileStyles = css`
  color: ${color.grey};
  padding: 10px 20px;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  :hover {
    color: ${color.grey};
  }
`;

export const NavLinkMobile = styled(Link).attrs({
  tracking: "custom",
  trackingOptions: { location: "navigationBarMobile" },
})`
  ${navLinkMobileStyles};
`;

export const NavAMobile = styled(A).attrs({
  tracking: "custom",
  trackingOptions: { location: "navigationBarMobile" },
})`
  ${navLinkMobileStyles};
`;

const navLinkActiveStyles = css`
  color: ${color.white};
  :after {
    width: 100%;
  }
`;

const navLinkStyles = css`
  display: block;
  color: ${color.white};
  font-weight: 400;
  position: relative;
  padding: 11px 0;
  margin: 0 10px;
  text-decoration: none;
  text-align: center;
  :after {
    content: "";
    display: block;
    background: ${color.white};
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 0;
    transition: width 0.2s ease-in-out;
  }
  :hover {
    ${navLinkActiveStyles};
  }
  ${breakPoint} {
    padding: 13px 0;
  }
`;

export const NavLink = styled(({ withIcon, trackingOptions, ...props }) => (
  <RRNavLink
    tracking="custom"
    trackingOptions={{ location: "navigationBar", ...trackingOptions }}
    {...props}
  />
))`
  ${navLinkStyles};
  &[aria-current="true"] {
    ${navLinkActiveStyles};
  }
  ${({ withIcon }) =>
    withIcon &&
    `
    padding-left: 24px;
    ${breakPoint} {
      padding-left: 27px;
    }
  `}
`;

export const NavA = styled(({ last, ...props }) => <A {...props} />).attrs({
  tracking: "custom",
  trackingOptions: { location: "navigationBar" },
})`
  ${navLinkStyles};
`;
