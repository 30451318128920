import get from "src/lib/get";
import trackError from "src/lib/track-error";
import api, { handleError } from "../index";
import { getSessionGuid } from "src/lib/tracking-cookies";

export const subscriptionCheckoutService = async (
  { apiToken, payload } = { payload: {} }
) => {
  try {
    const {
      engraving,
      delivery_details,
      card_details,
      products,
      one_off_products,
      phone_number,
      phone_number_country_code,
    } = payload;
    const {
      first_name,
      surname,
      delivery_address1,
      delivery_address2,
      delivery_city,
      delivery_country,
      delivery_postcode,
      delivery_safe_place,
    } = delivery_details;
    const checkoutData = {
      session_guid: getSessionGuid(),
      engraving: engraving ? engraving.trim() : "",
      delivery_details: {
        first_name: first_name ? first_name.trim() : "",
        surname: surname ? surname.trim() : "",
        delivery_address1: delivery_address1 ? delivery_address1.trim() : "",
        delivery_address2: delivery_address2 ? delivery_address2.trim() : "",
        delivery_city: delivery_city ? delivery_city.trim() : "",
        delivery_country: delivery_country ? delivery_country.trim() : "",
        delivery_postcode: delivery_postcode ? delivery_postcode.trim() : "",
        delivery_safe_place: delivery_safe_place
          ? delivery_safe_place.trim()
          : "",
      },
      products,
      one_off_products,
      card_details,
      phone_number,
      phone_number_country_code,
      trial: false,
    };

    const response = await api({
      method: "post",
      url: `/subscription/checkout`,
      headers: {
        ...api.defaults.headers,
        Authorization: `Bearer ${apiToken}`,
      },
      data: checkoutData,
    });
    return { error: null, data: response.data };
  } catch (error) {
    const phoneNumberError = get(
      error,
      "response.data.errors.phone_number",
      null
    );

    if (phoneNumberError) {
      trackError({
        errorMessage: "Subscribe invalid-phone-number",
        phoneNumberError,
      });
      return {
        error: {
          "error-code": "invalid-phone-number",
        },
        data: null,
      };
    }

    return {
      ...handleError(error),
      data: null,
    };
  }
};
