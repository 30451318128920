import styled from "styled-components";
import { color } from "src/styles/variables";

const Main = styled.main`
  flex-grow: 1;
  position: relative;
  background: ${({ background }) =>
    background === "white" ? color.white : color.lightGrey};
  display: flex;
  flex-direction: column;
`;

export default Main;
