import toCurrency from "./to-currency";

export default function formatCurrency({
  value = 0,
  truncate = true,
  currency,
}) {
  const price = value / 100;
  const decimalPlaces = truncate && price % 1 === 0 ? 0 : 2;
  return toCurrency({ value: price.toFixed(decimalPlaces), currency });
}
