import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import nanoid from "nanoid";
import mitt from "mitt";
import styled, { keyframes } from "styled-components";
import { MdClear } from "react-icons/md";
import { color } from "src/styles/variables";

const ANIMATION_DURATION = 700;

const emitter = mitt();

const variantColor = {
  success: color.funGreen,
  error: color.torchRed,
};

function addToast(variant, content, options = {}) {
  emitter.emit("addToast", { id: nanoid(), variant, content, options });
}

export const toast = {
  success: (content, options) => {
    addToast("success", content, options);
  },
  error: (content, options) => {
    addToast("error", content, options);
  },
};

const ToastNotifications = ({ autoDelete = true, dismissTime = 4000 }) => {
  const [toastList, setToastList] = useState([]);

  const deleteToast = useCallback(
    (id) => {
      const tempToastList = toastList.map((toast) =>
        toast.id === id ? { ...toast, isExiting: true } : toast
      );
      setToastList([...tempToastList]);
      setTimeout(() => {
        const newToastList = toastList.filter((toast) => toast.id !== id);
        setToastList([...newToastList]);
      }, ANIMATION_DURATION);
    },
    [toastList]
  );

  useEffect(() => {
    emitter.on("addToast", (toast) => {
      setToastList((currentList) => [...currentList, toast]);
    });
  }, []);

  useEffect(() => {
    const toast = toastList[0];
    if (toast) {
      const interval = setInterval(() => {
        if (autoDelete && toastList.length) {
          deleteToast(toast.id);
        }
      }, toast.options.autoClose || dismissTime);
      return () => {
        clearInterval(interval);
      };
    }
  }, [toastList, autoDelete, dismissTime, deleteToast]);

  return (
    <ToastContainer>
      {toastList.map((toast) => (
        <Toast
          key={toast.id}
          data-selector={`Toast-${toast.variant}`}
          variant={toast.variant}
          isExiting={toast.isExiting}
          onClick={() => deleteToast(toast.id)}
        >
          <ToastContent>{toast.content}</ToastContent>
          <ToastClose
            aria-label="dismiss toast notification"
            data-selector="Toast-close"
            onClick={() => deleteToast(toast.id)}
          >
            <MdClear size={24} />
          </ToastClose>
        </Toast>
      ))}
    </ToastContainer>
  );
};

ToastNotifications.propTypes = {
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

export default ToastNotifications;

const toastInRight = keyframes`
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
`;

const toastOutRight = keyframes`
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
`;

const ToastContainer = styled.section`
  font-size: 14px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  transform: translate3d(0, 0, 300px);
  width: 100%;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  @media (min-width: 600px) {
    max-width: 350px;
    padding-top: 20px;
    padding-right: 20px;
  }
`;

const Toast = styled.div`
  background-color: ${({ variant }) =>
    variantColor[variant] || color.lightGrey};
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 15px;
  min-height: 50px;
  border-radius: 5px;
  color: ${color.white};
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  animation: ${({ isExiting }) => (isExiting ? toastOutRight : toastInRight)}
    ${ANIMATION_DURATION / 1000}s;
`;

const ToastContent = styled.div`
  margin: auto 10px auto 0;
  flex: 1 1 auto;
  a {
    text-decoration: underline;
    color: ${color.white};
    &:hover {
      text-decoration: none;
      color: ${color.white};
    }
  }
`;

const ToastClose = styled.button`
  cursor: pointer;
  transition: transform 0.25s ease-out;
  font-weight: 700;
  color: ${color.white};
  outline: none;
  border: none;
  font-size: 16px;
  padding: 0;
  background: none;
  &:hover {
    transform: scale(1.25);
  }
`;
