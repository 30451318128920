import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import get from "src/lib/get";
import { isLoading } from "src/redux/app/selectors";
import {
  getUserToken,
  restrictTo,
  getIsUserStatusPending,
} from "src/redux/user/selectors";
import LoadingComponent from "src/react/components/async-component/loading-component";

export const allowedPathnames = ["account/email-preferences"];

export const isPathnameAllowed = (pathname = "") =>
  !!allowedPathnames.find((allowedPathname) =>
    pathname.match(`${allowedPathname}`)
  );

export const LoginRedirect = ({ location }) => {
  return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
};

export const SignupRedirect = ({ location }) => (
  <Redirect to={{ pathname: "/signup/basket", state: { from: location } }} />
);

export const PrivateRoute = ({
  loading,
  apiToken,
  isUserStatusPending,
  location,
  ...props
}) => {
  if (loading) {
    return <LoadingComponent />;
  }

  if (!apiToken) {
    return <LoginRedirect location={location} />;
  }

  const pathname = get(location, "pathname");

  if (isUserStatusPending && !isPathnameAllowed(pathname)) {
    return <SignupRedirect location={location} />;
  }

  return <Route {...props} location={location} />;
};

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  apiToken: getUserToken(state),
  restrictTo: restrictTo(state),
  isUserStatusPending: getIsUserStatusPending(state),
});

export default connect(mapStateToProps)(PrivateRoute);
