import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { color, font } from "src/styles/variables";

function NavToggle({ isOpen, ...props }) {
  return (
    <NavToggleButton {...props}>
      <MenuIcon close={isOpen}>
        <span />
        <span />
        <span />
      </MenuIcon>
      Menu
    </NavToggleButton>
  );
}
NavToggle.propTypes = {
  isOpen: PropTypes.bool,
};

export default NavToggle;

export const NavToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 400;
  padding: 11px 10px 11px 0;
  background: transparent;
  border: none;
  color: ${color.white};
  font-family: ${font.primary};
  font-size: 12px;
  height: 100%;
  &:focus {
    outline: none;
  }
`;

export const MenuIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 15px;
  margin-right: 5px;

  span {
    width: 17px;
    height: 2px;
    border-radius: 10px;
    transition: transform 0.2s linear, opacity 0.2s linear;
    position: relative;
    transform-origin: 8%;
    background-color: white;

    :first-child {
      transform: ${({ close }) => (close ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ close }) => (close ? "0" : "1")};
      transform: ${({ close }) =>
        close ? "translateX(5px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ close }) => (close ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
