import React, { useState } from "react";
import Loading from "src/react/components/loading";
import Tracking from "src/react/components/tracking";
import { ButtonBase } from "./button-base";

export function Button({
  type = "button",
  isSelected,
  children,
  disabled,
  inProgress,
  onClick,
  tracking,
  trackingOptions,
  ...props
}) {
  const hasTracking = tracking || trackingOptions;

  return hasTracking ? (
    <Tracking tracking={tracking} trackingOptions={trackingOptions}>
      <ButtonBase
        type={type}
        onClick={onClick}
        disabled={disabled}
        isSelected={isSelected}
        inProgress={inProgress}
        {...props}
      >
        {children}
      </ButtonBase>
    </Tracking>
  ) : (
    <ButtonBase
      type={type}
      onClick={onClick}
      disabled={disabled}
      isSelected={isSelected}
      inProgress={inProgress}
      {...props}
    >
      {children}
    </ButtonBase>
  );
}
export default Button;

export const SubmitButton = ({
  children,
  isSubmitting,
  type = "submit",
  disabled,
  onClick = () => {},
  controlled,
  activeText = "Processing",
  ...props
}) => {
  const [inProgress, setInProgress] = useState(false);
  const isInProgress = isSubmitting || inProgress;

  const onClickHandler = () => {
    if (disabled || isInProgress) return;

    if (typeof onClick === "function") {
      const fn = onClick();
      if (fn && fn.then && typeof fn.then === "function") {
        setInProgress(true);
        fn.then(() => setInProgress(false)).catch(() => setInProgress(false));
      }
    }
  };

  return (
    <Button
      type={type}
      inProgress={isInProgress}
      onClick={onClickHandler}
      disabled={isInProgress || disabled}
      {...props}
    >
      {isInProgress ? <Loading text={activeText} /> : children}
    </Button>
  );
};
SubmitButton.displayName = "SubmitButton";
