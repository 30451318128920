import { FULLY_INITIALIZED } from "src/redux/app/actions";
import { authCheckAsync } from "src/redux/user/actions";
import history from "src/react/history";

export default (store) => (next) => (action) => {
  next(action);

  if (
    history.location.pathname.includes("/token/") ||
    action.type !== FULLY_INITIALIZED
  ) {
    return;
  }

  store.dispatch(authCheckAsync());
};
