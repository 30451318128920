import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import trackPageview from "src/analytics/events/track-page-view";
import { isAuthenticated } from "src/redux/user/selectors";
import { LOCATION_CHANGED } from "src/redux/app/actions";
import { isAppReady as getIsAppReady } from "src/redux/app/selectors";

function resetScrollPosition(location) {
  if (location.hash === "") {
    window.scrollTo(0, 0);
  }
}

export default function useRouteChangeHandler() {
  const dispatch = useDispatch();
  const isAppReady = useSelector(getIsAppReady);
  const userIsLoggedIn = useSelector(isAuthenticated);
  const location = useLocation();
  const { pathname = "", search = "" } = location;

  useEffect(() => {
    const state = location.state || {};
    const shouldResetScrollPosition =
      state.resetScrollPosition != null ? state.resetScrollPosition : true;

    if (shouldResetScrollPosition) {
      resetScrollPosition(location);
    }

    if (isAppReady) {
      dispatch({ type: LOCATION_CHANGED });
    }
  }, [location, dispatch, isAppReady]);

  useEffect(() => {
    trackPageview({ path: pathname, query: search, userIsLoggedIn });
  }, [pathname, search, userIsLoggedIn]);
}
