import React, { useState } from "react";
import cookie from "cookie";
import styled from "styled-components";
import Container from "src/react/layout/container";
import { color } from "src/styles/variables";
import { Link } from "src/react/components/link";
import { Button } from "src/react/components/button";

const cookieName = "hasAcceptedCookies";

function setCookie() {
  const currentDatePlus30Days = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
  document.cookie = cookie.serialize(cookieName, true, {
    expires: currentDatePlus30Days,
  });
}

function CookieBanner() {
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(
    !!cookie.parse(document.cookie)[cookieName]
  );

  return hasAcceptedCookies ? null : (
    <CookieBannerWrapper>
      <Container>
        <Copy>
          We use our own and third party cookies to deliver content to you
          throughout your experience on our site. By clicking on or navigating
          our site you’re agreeing to our{" "}
          <CustomLink inline to="/privacy-policy">
            privacy policy
          </CustomLink>{" "}
          and use of cookies.{" "}
          <Button
            variant="outlineSecondary"
            size="small"
            align="center"
            css="margin-top: 10px; width: 200px;"
            onClick={() => {
              setCookie();
              setHasAcceptedCookies(true);
            }}
          >
            Got it
          </Button>
        </Copy>
      </Container>
    </CookieBannerWrapper>
  );
}

export default CookieBanner;

const CookieBannerWrapper = styled.section`
  color: ${color.white};
  background-color: rgba(51, 51, 51, 0.95);
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 12px;
  position: fixed;
  z-index: 99;
  bottom: 0;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const CustomLink = styled(Link)`
  color: ${color.white};
  text-decoration: underline;
  font-size: 12px;
`;

const Copy = styled.p`
  margin-bottom: 0;
`;
