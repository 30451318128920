import { isWeightLossProduct } from "src/data/helpers";
import { getWeightLossStatus } from "src/redux/account/subscription/selectors";
import { getUserToken } from "src/redux/user/selectors";
import { getCouponService } from "src/services/cornerstone/coupons/coupon";
import { putPharmaCouponService } from "src/services/cornerstone/subscription/coupon";
import { postConsultationService } from "src/services/cornerstone/consultation/consultation";
import {
  getFormattedNewConsultation,
  getFormattedFollowUpConsultation,
  getConsultationWeightLossCoupons,
  getConsultationProduct,
} from "src/redux/consultations/weightloss-consultation/selectors";
import { fetchActiveUserCoupons } from "src/redux/user/actions";

export const actionTypes = {
  WEIGHTLOSS_CONSULTATION_SAVE: "WEIGHTLOSS_CONSULTATION_SAVE",
  WEIGHTLOSS_CONSULTATION_SET_PRODUCT_OPTIONS:
    "WEIGHTLOSS_CONSULTATION_SET_PRODUCT_OPTIONS",
  WEIGHTLOSS_CONSULTATION_SUBMIT_PENDING:
    "WEIGHTLOSS_CONSULTATION_SUBMIT_PENDING",
  WEIGHTLOSS_CONSULTATION_SUBMIT_ERROR: "WEIGHTLOSS_CONSULTATION_SUBMIT_ERROR",
  WEIGHTLOSS_CONSULTATION_SUBMIT_SUCCESS:
    "WEIGHTLOSS_CONSULTATION_SUBMIT_SUCCESS",
  WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_SUCCESS:
    "WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_SUCCESS",
  WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_ERROR:
    "WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_ERROR",
  WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_SKIPPED:
    "WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_SKIPPED",
};

export const fetchWeightLossConsultationCoupons = () => async (
  dispatch,
  getState
) => {
  const state = getState();
  const coupons = getConsultationWeightLossCoupons(state);
  const wlStatus = getWeightLossStatus(state);

  if (coupons.length || (wlStatus !== "not_started" && wlStatus !== "failed")) {
    return dispatch({
      type: actionTypes.WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_SKIPPED,
    });
  }

  if (wlStatus === "failed") {
    return dispatch(fetchActiveUserCoupons());
  }

  const couponData = await getCouponService({ couponCode: "MEMBERWL" });

  if (!couponData.error) {
    return dispatch({
      type: actionTypes.WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_SUCCESS,
      payload: couponData.data,
    });
  }

  return dispatch({
    type: actionTypes.WEIGHTLOSS_CONSULTATION_COUPONS_FETCH_ERROR,
    payload: couponData.error,
  });
};

export const saveWeightLossConsultationAnswers = (payload) => {
  return { type: actionTypes.WEIGHTLOSS_CONSULTATION_SAVE, payload };
};

export const setWeightLossConsultationProductOptions = (payload) => {
  return {
    type: actionTypes.WEIGHTLOSS_CONSULTATION_SET_PRODUCT_OPTIONS,
    payload,
  };
};

export const submitWeightLossConsultation = ({ type } = { type: "" }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const apiToken = getUserToken(state);
  const wlStatus = getWeightLossStatus(state);
  const consultationProduct = getConsultationProduct(state);
  const coupon = getConsultationWeightLossCoupons(state)[0];
  const shouldPutCoupon =
    coupon && wlStatus !== "failed" && isWeightLossProduct(consultationProduct);
  const consultationPayload =
    type === "followUp"
      ? getFormattedFollowUpConsultation(state)
      : getFormattedNewConsultation(state);

  dispatch({
    type: actionTypes.WEIGHTLOSS_CONSULTATION_SUBMIT_PENDING,
  });

  return postConsultationService({
    apiToken,
    payload: consultationPayload,
  }).then((response = {}) => {
    if (response.error) {
      dispatch({
        type: actionTypes.WEIGHTLOSS_CONSULTATION_SUBMIT_ERROR,
        payload: response.error,
      });
      return response;
    }

    if (shouldPutCoupon) {
      putPharmaCouponService({
        apiToken,
        couponId: coupon.id,
        force: true,
      });
    }
    dispatch({
      type: actionTypes.WEIGHTLOSS_CONSULTATION_SUBMIT_SUCCESS,
      payload: response.data,
    });
    return response;
  });
};
