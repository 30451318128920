import get from "src/lib/get";
import { SET_STATE, RESET_STATE } from "src/redux/app/actions";
import actions, { actionTypes, fetchUserActionTypes } from "./actions";

const defaultState = {
  version: 3,
  apiToken: null,
  isPending: false,
  error: null,
  restrictTo: [],
  data: {
    id: null,
    email: "",
  },
  activeCoupons: {
    isPending: false,
    data: [],
    error: null,
  },
};

export default function user(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case SET_STATE:
      return state.version === defaultState.version ? state : defaultState;

    case RESET_STATE:
    case actions.USER_RESET_STATE:
      return defaultState;

    case actions.USER_SET:
      return {
        ...defaultState,
        apiToken: get(payload, "api_token", defaultState.apiToken),
        restrictTo: [].concat(get(payload, "restrict_to", [])),
        data: payload,
      };

    case actionTypes.USER_UPDATE:
      return {
        ...state,
        apiToken: get(payload, "api_token", state.apiToken),
        restrictTo: [].concat(
          get(payload, "restrict_to", state.restrictTo) || []
        ),
        data: {
          ...state.data,
          ...payload,
        },
      };

    case actionTypes.USER_AUTH_CHECK_SKIPPED:
    case actionTypes.USER_AUTH_CHECK_SUCCESS:
    case actionTypes.USER_AUTH_CHECK_ERROR:
      return {
        ...state,
        isPending: false,
      };

    case actionTypes.USER_AUTH_CHECK_PENDING:
    case actions.USER_REGISTER_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case actions.USER_REGISTER_ERROR:
      return {
        ...state,
        isPending: false,
        error: get(action, "payload", null),
      };
    case actions.USER_REGISTER_SUCCESS: {
      const data = get(action, "payload", {});
      return {
        ...state,
        isPending: false,
        error: null,
        apiToken: data.api_token,
        data,
      };
    }

    case fetchUserActionTypes.ME_FETCH_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case fetchUserActionTypes.ME_FETCH_ERROR:
      return {
        ...state,
        isPending: false,
        error: get(action, "payload", null),
      };
    case fetchUserActionTypes.ME_FETCH_SUCCESS:
      return {
        ...state,
        isPending: false,
        error: null,
        data: get(action, "payload", {}),
      };

    case actionTypes.USER_FETCH_ACTIVE_COUPONS_PENDING:
      return {
        ...state,
        activeCoupons: {
          ...defaultState.activeCoupons,
          isPending: true,
        },
      };
    case actionTypes.USER_FETCH_ACTIVE_COUPONS_ERROR:
      return {
        ...state,
        activeCoupons: {
          ...defaultState.activeCoupons,
          error: get(action, "payload", null),
        },
      };
    case actionTypes.USER_FETCH_ACTIVE_COUPONS_SUCCESS:
      return {
        ...state,
        activeCoupons: {
          ...defaultState.activeCoupons,
          data: get(action, "payload", {}),
        },
      };

    case actions.ME_SAVE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          email: get(action, "payload.email", state.data.email),
        },
      };

    default:
      return state;
  }
}
