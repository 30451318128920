import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { DefaultLayout } from "src/react/layout";
import { isAppReady as getIsAppReady } from "src/redux/app/selectors";
import LoadingComponent from "src/react/components/async-component/loading-component";
import PrivateRoute from "src/react/components/private-route";

function LayoutRoute({
  hideMainNav,
  hideFooterNav,
  hideFooter,
  footerLinks,
  withAuth,
  backgroundColor,
  fullHeightLoading = true,
  ...props
}) {
  const isAppReady = useSelector(getIsAppReady);
  return (
    <DefaultLayout
      hideMainNav={hideMainNav}
      hideFooterNav={hideFooterNav}
      hideFooter={hideFooter}
      footerLinks={footerLinks}
    >
      <Suspense
        fallback={
          <LoadingComponent
            backgroundColor={backgroundColor}
            fullHeightLoading={fullHeightLoading}
          />
        }
      >
        {!isAppReady ? null : withAuth ? (
          <PrivateRoute {...props} />
        ) : (
          <Route {...props} />
        )}
      </Suspense>
    </DefaultLayout>
  );
}

export default LayoutRoute;
