import { createSelector } from "reselect";
import get from "src/lib/get";
import isEmpty from "src/lib/isEmpty";
import { ORLI84X120 } from "src/data/product-data";
import { containsWeightLossProduct } from "src/data/helpers";
import {
  getWeightLossStatus,
  getIdentityCheckPassed,
  getUserSex,
} from "src/redux/account/subscription/selectors";
import { getCoupon as getSubscribeCoupon } from "src/redux/coupons/selectors";
import { getConsultationIdCheck } from "src/redux/consultations/id-check/selectors";
import { couponDiscountPercentage, parseConsultationAnswers } from "../helpers";
import { DEFAULT_FREQUENCY } from "./reducer";

function getCoupons(consultation) {
  return get(consultation, "coupons.data", []);
}

function getCouponProducts(coupon) {
  return get(coupon, "products.data", []);
}

export function getConsultation(state = {}) {
  return state.weightLossConsultation || {};
}

export const getConsultationAnswers = createSelector(
  [getConsultation, getUserSex],
  (consultation, sex) =>
    consultation.answers
      ? {
          ...consultation.answers,
          condition: {
            ...consultation.answers.condition,
            sex: consultation.answers.condition.sex || sex,
          },
        }
      : {}
);

export const getCanSubmitWeightLossMainConsultation = createSelector(
  getConsultationAnswers,
  (consultationAnswers) => {
    const answers = { ...consultationAnswers };
    delete answers.terms;
    return (
      !isEmpty(answers) &&
      Object.keys(answers).every((key) => !isEmpty(answers[key]))
    );
  }
);

export const getConsultationSubscribeWLCouponDiscountPercentage = createSelector(
  getSubscribeCoupon,
  (subscribeCoupon = {}) => {
    const couponProducts = getCouponProducts(subscribeCoupon);
    if (containsWeightLossProduct(couponProducts)) {
      return couponDiscountPercentage({
        couponData: subscribeCoupon,
        applicableDiscountTypes: ["percentage", "percentage_products_or"],
      });
    }
    return 0;
  }
);

export const getConsultationWeightLossCoupons = createSelector(
  getConsultation,
  (consultation) => {
    const consultationCoupons = getCoupons(consultation);
    return consultationCoupons.filter((coupon) => {
      const couponProducts = getCouponProducts(coupon);
      return containsWeightLossProduct(couponProducts);
    });
  }
);

export const getConsultationWeightLossCouponsError = createSelector(
  getConsultation,
  (consultation) => get(consultation, "coupons.error", null)
);

export const getConsultationCanHaveCouponDiscount = createSelector(
  [
    getWeightLossStatus,
    getConsultationWeightLossCoupons,
    getConsultationWeightLossCouponsError,
  ],
  (weightLossStatus, coupons = [], couponsError) => {
    return (
      (weightLossStatus === "not_started" || weightLossStatus === "failed") &&
      !coupons.length &&
      !couponsError
    );
  }
);

export const getConsultationCouponDiscountPercentage = createSelector(
  getConsultationWeightLossCoupons,
  (coupons = []) => {
    return couponDiscountPercentage({
      couponData: coupons[0],
      applicableDiscountTypes: ["percentage_products_or"],
    });
  }
);

export const getConsultationDiscountedPrice = createSelector(
  getConsultationCouponDiscountPercentage,
  (discountPercentage = 0) => {
    return ORLI84X120.priceInPence * discountPercentage;
  }
);

export const getConsultationProductOptions = createSelector(
  getConsultation,
  (consultation) => consultation.productOptions || {}
);

export const getConsultationProduct = createSelector(
  getConsultationProductOptions,
  (productOptions) => productOptions.strength || {}
);

export const getCanDoConsultation = createSelector(
  getWeightLossStatus,
  (weightLossStatus) => {
    return weightLossStatus !== "pending" && weightLossStatus !== "approved";
  }
);

export const getFormattedNewConsultation = createSelector(
  [
    getConsultationAnswers,
    getConsultationIdCheck,
    getConsultationProductOptions,
    getIdentityCheckPassed,
  ],
  (answers = {}, idCheck = {}, productOptions = {}, identityCheckPassed) => {
    const { condition = {}, terms = {} } = answers;
    const gpData = terms.gpName
      ? {
          name: terms.gpName,
          address: {
            address1: terms.gpAddress1,
            address2: terms.gpAddress2,
            city: terms.gpCity,
            county: terms.gpCounty,
            postcode: terms.gpPostcode,
          },
        }
      : {};
    const patient = identityCheckPassed
      ? {}
      : {
          firstname: idCheck.firstName,
          middlename: "",
          lastname: idCheck.lastName,
          phone: idCheck.phone,
          dob: idCheck.dob,
          address: {
            address1: idCheck.address1,
            address2: idCheck.address2,
            city: idCheck.city,
            county: idCheck.county,
            postcode: idCheck.postcode,
          },
        };

    return {
      type: "new",
      applicable_skus: [ORLI84X120.id],
      selected_skus: [
        {
          product_id: productOptions.strength,
          frequency: productOptions.frequency || DEFAULT_FREQUENCY,
        },
      ],
      consultation: [
        ...parseConsultationAnswers(condition),
        ...parseConsultationAnswers(terms),
      ],
      patient: {
        ...patient,
      },
      gp: gpData,
      bmi_data: {
        weight: condition.weightKg,
        height: condition.heightCm,
        sex: condition.sex,
        pregnancy: condition.pregnancy,
        pregnancy_details: condition.pregnancyDetails,
      },
    };
  }
);

export const getFormattedFollowUpConsultation = createSelector(
  getConsultationAnswers,
  (answers = {}) => {
    const { condition = {}, terms = {} } = answers;
    const gpData = terms.gpName
      ? {
          name: terms.gpName,
          address: {
            address1: terms.gpAddress1,
            address2: terms.gpAddress2,
            city: terms.gpCity,
            county: terms.gpCounty,
            postcode: terms.gpPostcode,
          },
        }
      : {};

    return {
      type: "repeat",
      applicable_skus: [ORLI84X120.id],
      consultation: [
        ...parseConsultationAnswers(condition),
        ...parseConsultationAnswers(terms),
      ],
      gp: gpData,
      bmi_data: {
        weight: condition.weightKg,
        height: condition.heightCm,
        sex: condition.sex,
        pregnancy: condition.pregnancy,
        pregnancy_details: condition.pregnancyDetails,
      },
    };
  }
);
