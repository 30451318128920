import axios from "axios";
import api, { handleError } from "../index";

export const fetchRecentParcelsService = ({ apiToken, limit, cancelToken }) => {
  return api({
    cancelToken,
    method: "get",
    headers: {
      ...api.defaults.headers,
      Authorization: `Bearer ${apiToken}`,
    },
    url: `/recent-parcels/?limit=${limit}`,
  })
    .then((response) => ({ error: null, data: response.data.data }))
    .catch((error) => {
      if (axios.isCancel(error)) {
        return {};
      } else {
        const errorPayload = handleError(error);
        return {
          ...errorPayload,
          data: null,
        };
      }
    });
};
