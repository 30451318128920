export const BALM_SKU = "C1002";
export const BLADE_PROTECTOR_SKU = "PROTECTOR";
export const BLADE_SKU = "BLADE";
export const BLADECART_5_SKU = "5BLADECART";
export const BLADECART_6_SKU = "6BLADECART";
export const BLADES_5_SKU = "5BLADES";
export const BLADES_6_SKU = "6BLADES";
export const BODYWASH_SKU = "BODYWASH";
export const CONDOM10_SKU = "CONDOM10";
export const CREAM_SKU = "C1011";
export const DEODORANT_SKU = "DEO";
export const ENGRAVED_GOLD_HANDLE_SKU = "ENHANBLADE_GOLD";
export const ENGRAVED_HANDLE_SKU = "ENHANBLADE";
export const FACE_WASH_SKU = "C1006";
export const FIN30X1_SKU = "FIN30X1";
export const FLOSS_SKU = "FLOSS";
export const FOUR_BRUSH_CART_SKU = "4BRUSHCART";
export const GEL_SKU = "C1001";
export const GIFTSET_SKU = "C2000";
export const GOLD_HANDLE_SKU = "HANBLADE_GOLD";
export const HANBLADEV1_SKU = "HANBLADEV1";
export const HANBLADEV2_SKU = "HANBLADEV2";
export const HANDLE_SKU = "HANBLADE";
export const HANDLE_V1_SKU = "HANDLE";
export const HANDLE_V2_1_SKU = "HANDLE_V2_1";
export const HANDLE_V2_GOLD_SKU = "HANDLEV2_GOLD";
export const HANBLADE_SKU = "HANBLADE";
export const LUXURY_WASHSET_SKU = "LUXURY_WASH_SET";
export const LUXGIFTSETGOLD_SKU = "LUXGIFTSETGOLD";
export const MIN65_SKU = "MIN65";
export const MINI_GIFTSET_SKU = "MINIGIFTBOX";
export const MOISTURISER_SKU = "C1012";
export const MOUTH_WASH_SKU = "MOUTHWASH";
export const ONE_BRUSH_CART_SKU = "1BRUSHCART";
export const RAZOR_SKU = "C1003";
export const SCRUB_SKU = "C1000";
export const SHAMPOO_SKU = "SHAMPOO";
export const SILD8X100_SKU = "SILD8X100";
export const SILD8X50_SKU = "SILD8X50";
export const TOOTH_PASTE_SKU = "PASTE";
export const TRAVEL_CREAM_SKU = "TSC";
export const TRAVEL_GEL_SKU = "TSG";
export const TRIALBOX_SKU = "TRIALBOX";
export const VITAMINS_SKU = "VITA30";
export const WASHBAG_SKU = "WASHBAG";
export const ORLI84X120_SKU = "ORLI84X120";
export const BBCOOLSOX1_SKU = "BBCOOLSOX1";
export const BBCOOLSOX3_SKU = "BBCOOLSOX3";
