import smartlookClient from "smartlook-client";
import cookie from "cookie";
import nanoid from "nanoid";
import queryString from "query-string";
import { storeSession } from "src/services/event/sessions/store";
const prod = process.env.REACT_APP_RELEASE_STAGE === "production";

export const setTrackingCookies = () => {
  const cookies = cookie.parse(document.cookie);
  let lifetimeGuid = cookies.lifetime_guid;
  let sessionGuid = cookies.session_guid;
  let newSession = false;

  if (!lifetimeGuid) {
    lifetimeGuid = nanoid();
    document.cookie = lifetimeCookie(lifetimeGuid);
  }
  if (!sessionGuid) {
    newSession = true;
    sessionGuid = nanoid();
    document.cookie = sessionCookie(sessionGuid);
  }

  if (newSession) {
    const sessionData = {
      type: "session",
      lifetime_guid: lifetimeGuid,
      session_guid: sessionGuid,
      user_agent: window.navigator.userAgent || "",
      landing_path: window.location.pathname,
    };
    if (window.location.search) {
      sessionData.full_query_string = window.location.search;
      const queries = queryString.parse(window.location.search);
      if (queries.utm_medium) sessionData.utm_medium = queries.utm_medium;
      if (queries.utm_source) sessionData.utm_source = queries.utm_source;
      if (queries.utm_campaign) sessionData.utm_campaign = queries.utm_campaign;
      if (queries.utm_content) sessionData.utm_content = queries.utm_content;
      if (queries.utm_term) sessionData.utm_term = queries.utm_term;
    }
    if (prod) {
      storeSession(sessionData).then(() => {});
      if (window.trackJs) {
        window.trackJs.configure({
          userId: lifetimeGuid,
          sessionId: sessionGuid,
        });
      }

      if (process.env.NODE_ENV !== "test") {
        smartlookClient.identify(lifetimeGuid, {
          sessionGuid,
          lifetimeGuid,
        });
      }
    }
  }

  return {
    lifetimeGuid,
    sessionGuid,
  };
};

export function getSessionGuid() {
  const cookies = cookie.parse(document.cookie);
  if (cookies.session_guid) {
    return cookies.session_guid;
  } else {
    return setTrackingCookies().sessionGuid;
  }
}

function lifetimeCookie(lifetimeGuid) {
  const maxAge = 2147483647; // http://stackoverflow.com/a/22479460/1836264
  return cookie.serialize("lifetime_guid", lifetimeGuid, { maxAge });
}

function sessionCookie(sessionGuid) {
  return cookie.serialize("session_guid", sessionGuid, { maxAge: null });
}
