import get from "src/lib/get";

const isInteger = RegExp(/^\d+$/);

export function parseConsultationAnswers(answersData = {}) {
  const consultationAnswers = [];
  Object.keys(answersData).forEach((question) => {
    const answer = answersData[question];
    if (isInteger.test(question)) {
      consultationAnswers.push({
        question: parseInt(question),
        answer,
        detail: answersData[`${question}-details`] || "",
      });
    }
  });
  return consultationAnswers;
}

export function couponDiscountPercentage(
  { couponData, applicableDiscountTypes = "" } = {
    couponData: {},
    applicableDiscountTypes: "",
  }
) {
  const hasApplicalbleDiscountType =
    couponData &&
    []
      .concat(applicableDiscountTypes)
      .indexOf(couponData.discount_amount_type) > -1;

  return hasApplicalbleDiscountType ? couponData.discount_amount / 100 : 0;
}

export function parseUserActiveCoupons(coupons = []) {
  return coupons.map((coupon) => get(coupon, "coupon.data", {}));
}
