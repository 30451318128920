export default {
  home: {
    to: "/",
    text: "Home",
  },
  howItWorks: {
    to: "/how-it-works",
    text: "How it works",
  },
  products: {
    to: "/products",
    text: "Products",
  },
  ourStory: {
    to: "/our-story",
    text: "Our Story",
  },
  gifts: {
    to: `/gifts`,
    text: "Gifts",
  },
  blog: {
    href: true,
    to: "https://blog.cornerstone.co.uk",
    text: "Blog",
  },
  account: {
    to: `/account`,
    text: "My Account",
  },
  yourPlan: {
    to: `/account`,
    text: "Your Plan",
  },
  yourDetails: {
    to: `/account/your-details`,
    text: "Your Details",
  },
  orderHistory: {
    to: `/account/order-history`,
    text: "Order History",
  },
  shop: {
    to: `/account/topup`,
    text: "Place Order",
  },
  help: {
    to: `/help`,
    text: "Help",
  },
  accountHelp: {
    to: `/account/help`,
    text: "Help",
  },
  logout: {
    to: `/logout`,
    text: "Logout",
  },
  login: {
    to: `/login`,
    text: "Login",
  },
  signupBasket: {
    to: "/signup/basket",
    text: "Signup basket",
  },
};
