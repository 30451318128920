import React from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import loadAsyncComponent from "src/react/components/async-component";
import Logout from "src/react/pages/logout";
import LayoutRoute from "src/react/components/layout-route";
import NotFound from "src/react/pages/not-found";
import useRouteChangeHandler from "src/react/hooks/useRouteChangeHandler";

const Routes = () => {
  const location = useLocation();
  useRouteChangeHandler();

  return (
    <Switch>
      <Redirect
        exact
        from="/subscribe"
        to={{ ...location, pathname: "/products" }}
      />
      <Redirect exact from="/signup" to="/products" />
      <Redirect exact from="/trial" to="/products" />
      <Redirect exact from="/subscribe/product-selection" to="/products" />
      <Redirect exact path="/subscribe-thanks" to="/signup-thanks" />
      <Redirect
        path="/subscribe/consultation/:step"
        to={{ ...location, pathname: "/signup/consultation/:step" }}
      />
      <Redirect
        from="/subscribe/basket"
        to={{ ...location, pathname: "/signup/basket" }}
      />
      <Redirect
        from="/consultation/:step/follow-up"
        to={{ ...location, pathname: "/account/consultation/:step/follow-up" }}
      />
      <Redirect
        from="/consultation/:step"
        to={{ ...location, pathname: "/account/consultation/:step" }}
      />
      {/* <Redirect
        from="/gifts"
        to="/gifts-offers"
      /> */}
      <LayoutRoute
        withAuth
        fullHeightLoading={false}
        path="/account"
        component={Account}
      />
      <LayoutRoute
        path="/signup/consultation"
        component={Consultations}
        hideMainNav
        hideFooterNav
      />
      <LayoutRoute exact path="/" component={Homepage} />
      <LayoutRoute exact path="/404" component={NotFound} />
      <LayoutRoute
        exact
        path="/forgotten-password"
        component={ForgottenPassword}
      />
      <LayoutRoute
        path={["/gifts", "/gifts/:step", "/gifts-offers", "/vodafone20"]}
        component={Gifts}
      />
      <LayoutRoute exact path="/gifts-thanks" component={GiftsThanks} />
      <LayoutRoute exact path="/help" component={HelpCentre} />
      <LayoutRoute exact path="/how-it-works" component={HowItWorks} />
      <LayoutRoute exact path="/login" component={Login} />
      <LayoutRoute exact path="/logout" component={Logout} />
      <LayoutRoute exact path="/our-story" component={OurStory} />
      <LayoutRoute
        exact
        path="/password/reset/:token"
        component={PasswordReset}
      />
      <LayoutRoute
        exact
        path="/preview/products/:productPageSlug"
        component={Product}
      />
      <LayoutRoute
        exact
        path="/products/:productPageSlug"
        component={Product}
      />
      <LayoutRoute path="/products" component={Products} />
      <LayoutRoute exact path="/redeem" component={Redeem} />
      <LayoutRoute
        exact
        path="/signup-thanks"
        component={SignupThanks}
        hideFooterNav
      />
      <LayoutRoute
        path="/signup"
        component={Signup}
        hideMainNav
        hideFooterNav
      />
      <LayoutRoute exact path="/token/:token" hideMainNav component={Token} />
      <LayoutRoute
        exact
        path="/7:referral"
        component={LandingPage}
        hideMainNav
        hideFooterNav
      />
      <LayoutRoute exact path="/terms-and-conditions" component={LandingPage} />
      <LayoutRoute
        exact
        path="/regulatory-information"
        component={LandingPage}
      />
      <LayoutRoute exact path="/privacy-policy" component={LandingPage} />
      <LayoutRoute
        path="/feedback-collect"
        component={FeedbackCollect}
        hideMainNav
        hideFooterNav
      />
      <LayoutRoute
        path="/:slug"
        component={LandingPage}
        backgroundColor="white"
        hideMainNav
        hideFooterNav
      />
    </Switch>
  );
};

export default Routes;

// contains Async Components for account
const Account = loadAsyncComponent({
  loader: () => import("./pages-account"),
});

const Consultations = loadAsyncComponent({
  loader: () => import("./pages/consultations"),
});

const Homepage = loadAsyncComponent({
  loader: () => import("./pages/homepage"),
});

const Login = loadAsyncComponent({
  loader: () => import("./pages/login"),
});

const ForgottenPassword = loadAsyncComponent({
  loader: () => import("./pages/forgotten-password"),
});

const PasswordReset = loadAsyncComponent({
  loader: () => import("./pages/password-reset"),
});

const Signup = loadAsyncComponent({
  loader: () => import("./pages/signup"),
});

const SignupThanks = loadAsyncComponent({
  loader: () => import("./pages/signup-thanks"),
});

const HowItWorks = loadAsyncComponent({
  loader: () => import("./pages/how-it-works"),
});

const OurStory = loadAsyncComponent({
  loader: () => import("./pages/our-story"),
});

const Products = loadAsyncComponent({
  loader: () => import("./pages/products"),
});

const Product = loadAsyncComponent({
  loader: () => import("./pages/product"),
});

const Redeem = loadAsyncComponent({
  loader: () => import("./pages/redeem"),
});

const Gifts = loadAsyncComponent({
  loader: () => import("./pages/gifts"),
});

const GiftsThanks = loadAsyncComponent({
  loader: () => import("./pages/gifts-thanks"),
});

const Token = loadAsyncComponent({
  loader: () => import("./pages/token"),
});

const HelpCentre = loadAsyncComponent({
  loader: () => import("./pages/help"),
});

const FeedbackCollect = loadAsyncComponent({
  loader: () => import("./pages/feedback-collect"),
});

const LandingPage = loadAsyncComponent({
  loader: () => import("src/react/pages/landing-page"),
});
