import { containsAllHairLossProducts } from "src/data/helpers";
import { getHairLossStatus } from "src/redux/account/subscription/selectors";
import { getUserToken } from "src/redux/user/selectors";
import { getCouponService } from "src/services/cornerstone/coupons/coupon";
import { putPharmaCouponService } from "src/services/cornerstone/subscription/coupon";
import { postConsultationService } from "src/services/cornerstone/consultation/consultation";
import {
  getFormattedNewConsultation,
  getConsultationHairLossCoupons,
  getConsultationProduct,
  getFormattedFollowUpConsultation,
} from "src/redux/consultations/hairloss-consultation/selectors";
import { fetchActiveUserCoupons } from "src/redux/user/actions";

export const actionTypes = {
  HAIRLOSS_CONSULTATION_SAVE: "HAIRLOSS_CONSULTATION_SAVE",
  HAIRLOSS_CONSULTATION_SET_PRODUCT_OPTIONS:
    "HAIRLOSS_CONSULTATION_SET_PRODUCT_OPTIONS",
  HAIRLOSS_CONSULTATION_SUBMIT_PENDING: "HAIRLOSS_CONSULTATION_SUBMIT_PENDING",
  HAIRLOSS_CONSULTATION_SUBMIT_ERROR: "HAIRLOSS_CONSULTATION_SUBMIT_ERROR",
  HAIRLOSS_CONSULTATION_SUBMIT_SUCCESS: "HAIRLOSS_CONSULTATION_SUBMIT_SUCCESS",
  HAIRLOSS_CONSULTATION_COUPONS_FETCH_SUCCESS:
    "HAIRLOSS_CONSULTATION_COUPONS_FETCH_SUCCESS",
  HAIRLOSS_CONSULTATION_COUPONS_FETCH_ERROR:
    "HAIRLOSS_CONSULTATION_COUPONS_FETCH_ERROR",
  HAIRLOSS_CONSULTATION_COUPONS_FETCH_SKIPPED:
    "HAIRLOSS_CONSULTATION_COUPONS_FETCH_SKIPPED",
};

export const fetchHairLossConsultationCoupons = () => async (
  dispatch,
  getState
) => {
  const state = getState();
  const coupons = getConsultationHairLossCoupons(state);
  const hlStatus = getHairLossStatus(state);

  if (coupons.length || (hlStatus !== "not_started" && hlStatus !== "failed")) {
    return dispatch({
      type: actionTypes.HAIRLOSS_CONSULTATION_COUPONS_FETCH_SKIPPED,
    });
  }

  if (hlStatus === "failed") {
    return dispatch(fetchActiveUserCoupons());
  }

  const couponData = await getCouponService({ couponCode: "EXISTINGHL50" });

  if (!couponData.error) {
    return dispatch({
      type: actionTypes.HAIRLOSS_CONSULTATION_COUPONS_FETCH_SUCCESS,
      payload: couponData.data,
    });
  }

  return dispatch({
    type: actionTypes.HAIRLOSS_CONSULTATION_COUPONS_FETCH_ERROR,
    payload: couponData.error,
  });
};

export const saveHairLossConsultationAnswers = (payload) => {
  return { type: actionTypes.HAIRLOSS_CONSULTATION_SAVE, payload };
};

export const setHairLossConsultationProductOptions = (payload) => {
  return {
    type: actionTypes.HAIRLOSS_CONSULTATION_SET_PRODUCT_OPTIONS,
    payload,
  };
};

export const submitHairLossConsultation = ({ type } = { type: "" }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const apiToken = getUserToken(state);
  const hlStatus = getHairLossStatus(state);
  const consultationProduct = getConsultationProduct(state);
  const coupon = getConsultationHairLossCoupons(state)[0];

  const shouldPutCoupon =
    coupon &&
    hlStatus !== "failed" &&
    containsAllHairLossProducts(consultationProduct);

  const consultationPayload =
    type === "followUp"
      ? getFormattedFollowUpConsultation(state)
      : getFormattedNewConsultation(state);

  dispatch({
    type: actionTypes.HAIRLOSS_CONSULTATION_SUBMIT_PENDING,
  });

  return postConsultationService({
    apiToken,
    payload: consultationPayload,
  }).then((response = {}) => {
    if (response.error) {
      dispatch({
        type: actionTypes.HAIRLOSS_CONSULTATION_SUBMIT_ERROR,
        payload: response.error,
      });
      return response;
    }

    if (shouldPutCoupon) {
      putPharmaCouponService({
        apiToken,
        couponId: coupon.id,
        force: true,
      });
    }
    dispatch({
      type: actionTypes.HAIRLOSS_CONSULTATION_SUBMIT_SUCCESS,
      payload: response.data,
    });
    return response;
  });
};
