import { getUserToken } from "src/redux/user/selectors";
import { getCouponService } from "src/services/cornerstone/coupons/coupon";
import { putPharmaCouponService } from "src/services/cornerstone/subscription/coupon";
import { postConsultationService } from "src/services/cornerstone/consultation/consultation";
import {
  getFormattedNewConsultation,
  getConsultationEDCoupons,
  getFormattedFollowUpConsultation,
} from "src/redux/consultations/ed-consultation/selectors";
import { getEdStatus } from "src/redux/account/subscription/selectors";
import { fetchActiveUserCoupons } from "src/redux/user/actions";

export const actionTypes = {
  ED_CONSULTATION_SAVE: "ED_CONSULTATION_SAVE",
  ED_CONSULTATION_SET_PRODUCT_OPTIONS: "ED_CONSULTATION_SET_PRODUCT_OPTIONS",
  ED_CONSULTATION_SUBMIT: "ED_CONSULTATION_SUBMIT",
  ED_CONSULTATION_SUBMIT_PENDING: "ED_CONSULTATION_SUBMIT_PENDING",
  ED_CONSULTATION_SUBMIT_ERROR: "ED_CONSULTATION_SUBMIT_ERROR",
  ED_CONSULTATION_SUBMIT_SUCCESS: "ED_CONSULTATION_SUBMIT_SUCCESS",
  ED_CONSULTATION_COUPONS_FETCH_SUCCESS:
    "ED_CONSULTATION_COUPONS_FETCH_SUCCESS",
  ED_CONSULTATION_COUPONS_FETCH_ERROR: "ED_CONSULTATION_COUPONS_FETCH_ERROR",
  ED_CONSULTATION_COUPONS_FETCH_SKIPPED:
    "ED_CONSULTATION_COUPONS_FETCH_SKIPPED",
};

export const fetchEdConsultationCoupons = () => async (dispatch, getState) => {
  const state = getState();
  const coupons = getConsultationEDCoupons(state);
  const edStatus = getEdStatus(state);

  if (coupons.length || (edStatus !== "not_started" && edStatus !== "failed")) {
    return dispatch({
      type: actionTypes.ED_CONSULTATION_COUPONS_FETCH_SKIPPED,
    });
  }

  if (edStatus === "failed") {
    return dispatch(fetchActiveUserCoupons());
  }

  const couponData = await getCouponService({ couponCode: "EXISTINGED50" });

  if (!couponData.error) {
    return dispatch({
      type: actionTypes.ED_CONSULTATION_COUPONS_FETCH_SUCCESS,
      payload: couponData.data,
    });
  }

  return dispatch({
    type: actionTypes.ED_CONSULTATION_COUPONS_FETCH_ERROR,
    payload: couponData.error,
  });
};

export const saveEDConsultationAnswers = (payload) => {
  return { type: actionTypes.ED_CONSULTATION_SAVE, payload };
};

export const setEdConsultationProductOptions = (payload) => {
  return { type: actionTypes.ED_CONSULTATION_SET_PRODUCT_OPTIONS, payload };
};

export const submitEDConsultation = ({ type } = { type: "" }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const apiToken = getUserToken(state);
  const edStatus = getEdStatus(state);
  const consultationPayload =
    type === "followUp"
      ? getFormattedFollowUpConsultation(state)
      : getFormattedNewConsultation(state);

  dispatch({
    type: actionTypes.ED_CONSULTATION_SUBMIT_PENDING,
  });

  const coupon = getConsultationEDCoupons(state)[0];

  if (coupon && edStatus !== "failed") {
    putPharmaCouponService({
      apiToken,
      couponId: coupon.id,
      force: true,
    });
  }

  return postConsultationService({
    apiToken,
    payload: consultationPayload,
  }).then((response = {}) => {
    if (response.error) {
      dispatch({
        type: actionTypes.ED_CONSULTATION_SUBMIT_ERROR,
        payload: response.error,
      });
    } else {
      dispatch({
        type: actionTypes.ED_CONSULTATION_SUBMIT_SUCCESS,
        payload: response.data,
      });
    }
    return response;
  });
};
