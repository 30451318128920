import { createSelector } from "reselect";
import get from "src/lib/get";
import { getData } from ".";

export const getCoupons = createSelector(getData, (data) => {
  return get(data, "coupons.data", []);
});

export const getTopupCoupons = createSelector(getCoupons, (coupons) =>
  coupons.filter((coupon = {}) => coupon.type === "topup")
);
